import Button from "@/components/common/Button";
import styled from "@emotion/styled";
import { COLORS } from "vd-component";

export const Container = styled.section({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  borderRadius: "20px",
  padding: "0px 24px",
});

export const QAContainer = styled.div({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  gap: "20px",
  minHeight: 0,
  zIndex: 2,
  h1: {
    textAlign: "center",
    color: "white",
    fontSize: "40px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "150%",
    letterSpacing: "-1.2px",
    marginTop: "24px",
    marginBottom: "26px",
  },
});

export const TitleContainer = styled.div({
  gap: "8px",
  display: "flex",
  alignItems: "center",

  span: {
    fontFamily: "Pretendard",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "150%" /* 21px */,
    letterSpacing: "-0.14px",
  },
});

export const CustomInputButton = styled(Button)(({ theme, disabled }) => ({
  height: "fit-content",
  padding: "14px 28px",
  color: "var(--color-gray-700,#5e5e5e)",
  border: "2px solid var(--color-line-light, #EFEFEF);",
  borderRadius: "100px",
  backdropFilter: "blur(10px)",
  width: "fit-content",
  gap: "4px",
  backgroundColor: disabled ? "#DBDBDB" : "white",
  span: {
    fontFamily: "Pretendard Variable",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "150%",
    letterSpacing: "-0.18px",
    whiteSpace: "nowrap",
  },
}));

export const CenterBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.colorLabelHeavy};
  border-radius: 999px;
`;
