interface KeyPadContainerProps {
  padContent: Function;
  skipFunc?: Function;
  buttonAvailable: boolean;
  inputFunc: Function;
}

const KeyPadContainer = ({
  padContent,
  skipFunc,
  buttonAvailable,
  inputFunc,
}: KeyPadContainerProps) => {
  return (
    <div
      style={{
        minWidth: "317px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <div>{padContent()}</div>
      {/* <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "12px",
          padding: skipFunc ? "0px 19%" : "0",
        }}
      > */}
      {/* {skipFunc && (
          <SquareButton
            style={{
              width: "63%",
              padding: "23.5px",
              background: "var(--color-background-light, #F1F2F3)",
              color: "var(--color-label-light, #46474C)",
              border: "1px solid",
              borderColor: "var(--color-line-nomal, #C2C4C8)",
            }}
            onClick={() => skipFunc()}
            labelStyle={{
              fontSize: "24px",
            }}
            buttonType="cancel"
            label="건너뛰기"
          />
        )} */}
      {/* </div> */}
    </div>
  );
};

export default KeyPadContainer;
