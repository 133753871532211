import TermAgreePage from "@/components/kiosk/TermAgree";

import { usePatientDataStore } from "@/features/Screen/store/ScreenStore";
import {
  CheckReciptResponseEntity,
  PatientInfo,
} from "@/network-module/entities/HospitalEntity";
import { NetworkManager } from "@/network-module/NetworkManager";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ScreenWaitStep1 from "../screening/ScreenWaitStep1";
import DuplicateCopyModal from "./DuplicateCopyModal";
import { HospitalData } from "./ReceptionPage";

type ReceptionType2 = {
  phoneNumber: string;
  name: string;
  birth: string;
  identityGender: number | string;
  selectedDoctorId: number | null;
  termsIds: Array<number>;
  medicalDepartmentId: number | null;
  identityNumber: string;
  doctorName: string;
};

//신규 케이스
const ReceptionStep2Type2 = ({
  patientData,
  receiptData,
  hospitalData,
  setParentStep,
}: {
  patientData: PatientInfo;
  receiptData: CheckReciptResponseEntity;
  setParentStep: Function;
  hospitalData: HospitalData;
}) => {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const makeSubmitPatient = (termsId: Array<number>): ReceptionType2 => {
    return {
      birth: patientData.identityNumber.slice(0, 6).join(""),
      phoneNumber: patientData.phoneNumber.join(""),
      identityGender: patientData.identityNumber[6],
      name: patientData.name,
      selectedDoctorId: hospitalData.selectedDoctorId,
      termsIds: termsId,
      medicalDepartmentId: hospitalData.medicalDepartmentId,
      identityNumber: patientData.identityNumber.slice(7, 13).join(""),
      doctorName: hospitalData.doctorName,
    };
  };

  const submit = (submitPatient: ReceptionType2) => {
    NetworkManager.hospitalService
      .kioskReceiptWithNoHp(submitPatient)
      .then((checkupId) => {
        NetworkManager.checkupService.kioskAllocate(checkupId).then(() => {
          setStep(1);
          usePatientDataStore.setState({
            ...submitPatient,
            checkupId: checkupId,
          });
        });
      })
      .catch((e) => {
        if (e.code === 400015) {
          setIsOpen(true);
        }
      });
  };

  if (step == 0) {
    return (
      <>
        <TermAgreePage
          backFunc={() => {
            setParentStep(3);
          }}
          nextStep={(termsId: Array<number>) => {
            submit(makeSubmitPatient(termsId));
          }}
        />
        <DuplicateCopyModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
      </>
    );
  }

  if (step === 1) {
    return (
      <ScreenWaitStep1
        patientName={patientData.name}
        nextStep={() => {
          navigate("/checkup");
        }}
      />
    );
  }
};
export default ReceptionStep2Type2;
