import { useHumanBodyStore } from "@/features/humanBodyDiagram/context/useHumanBodyStore";
import { directionLabelMappings } from "@/features/humanBodyDiagram/entities/directionLabelMappings";
import type { Direction } from "@/features/humanBodyDiagram/stores/humanBodyPartsSlice";
import { findMatchMapping } from "@/features/humanBodyDiagram/utils/mappingFactory";
import styled from "@emotion/styled";
import { typography_style_heading_2xl_semibold } from "vd-component";

const DirectionLabelRecord: Record<Direction, string> = {
  left: "왼쪽",
  right: "오른쪽",
};

export const HumanBodyDirectionLabelInfo = () => {
  const { mainCategory, subCategory } = useHumanBodyStore((store) => ({
    mainCategory: store.mainCategory,
    subCategory: store.subCategory,
  }));

  const directionLabel = findMatchMapping(directionLabelMappings, {
    mainCategory,
    subCategory,
  });

  if (!directionLabel) return null;

  return (
    <Container>
      <DirectionLabel>
        {DirectionLabelRecord[directionLabel.leftLabel]}
      </DirectionLabel>
      <DirectionLabel>
        {DirectionLabelRecord[directionLabel.rightLabel]}
      </DirectionLabel>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 150px;
  display: flex;
  justify-content: center;
  gap: 270px;
  color: var(--color-opacity-16, rgba(0, 0, 0, 0.16));
  pointer-events: none;
`;
const DirectionLabel = styled.span`
  ${typography_style_heading_2xl_semibold}
`;
