import { useHumanBodyStore } from "@/features/humanBodyDiagram/context/useHumanBodyStore";
import { useEffect } from "react";

export type EventHandler = (event: PointerEvent) => void;
export type EventType = "click" | "mouseover" | "mouseout" | "mousemove"; // 필요한 이벤트 타입 추가
export type EventListeners = {
  [key in EventType]?: EventHandler;
};

/**
 * 인체 다이어그램에 이벤트 리스너 등록
 * */
export const useD3EventListeners = (eventListeners: EventListeners) => {
  const { gSelection, svgSelection } = useHumanBodyStore((store) => ({
    gSelection: store.gSelection,
    svgSelection: store.svgSelection,
  }));

  useEffect(() => {
    if (!gSelection || !svgSelection) return;

    // 모든 이벤트 리스너 등록
    Object.entries(eventListeners)
      .filter(([, handler]) => handler)
      .forEach(([eventType, handler]) => gSelection.on(eventType, handler));

    // 클린업 함수
    return () => {
      Object.keys(eventListeners).forEach((eventType) => {
        gSelection.on(eventType as EventType, null);
      });
    };
  }, [gSelection, svgSelection, eventListeners]);
};
