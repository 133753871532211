import NewLayout from "@/components/common/NewLayout";
import { CustomInputModal } from "@/features/phase3/components/CustomInputModal";
import { usePhase3Hook1 } from "@/features/phase3/store/Phase3Hook1";
import { MicrophoneIcon } from "@/theme/CustomIcons";
import { useEffect, useRef, useState } from "react";
import { Button, COLORS, TextArea, Title } from "vd-component";

export const ScreenPainExplain = ({
  backFunc,
  setPainExplain,
  keywords,
  makeAnswer,
}: {
  backFunc: Function;
  keywords: (string | undefined)[];
  setPainExplain: Function;
  makeAnswer: { id: number | null; content: string }[];
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [focus, setFocus] = useState<boolean>(false);
  const { isCustomInput, toggleIsCustomInput } = usePhase3Hook1(1);

  const [text, setText] = useState(
    makeAnswer.at(-1)?.id === null ? (makeAnswer.at(-1)?.content ?? "") : "",
  );

  const handleConfirm = () => {
    setPainExplain(text);
  };

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (textAreaRef.current?.contains(e.target as Node)) {
        setFocus(true);
      } else {
        setFocus(false);
      }
    };

    window.addEventListener("click", handleClick);
    return () => window.removeEventListener("click", handleClick);
  }, []);

  return (
    <NewLayout
      backFunc={backFunc}
      isShowPatientInfo
      css={{
        padding: "36px 58px",
        flexDirection: "column",
        justifyContent: focus ? "normal" : "center",
      }}
    >
      <div css={{ display: "flex", flexDirection: "column", gap: "24px" }}>
        <div
          css={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title
            size="2x-large"
            title="{0}을 직접 입력해주세요"
            highlightArr={["주 증상"]}
          />
          <div css={{ display: "flex", gap: 12 }}>
            <Button
              variant="secondary"
              size="large"
              css={{
                gap: "4px",
                borderRadius: "999px",
              }}
              leftIcon={<MicrophoneIcon color={COLORS.colorLabelAlternative} />}
              onClick={() => {
                toggleIsCustomInput();
              }}
            >
              <span>음성 입력</span>
            </Button>
            <Button
              size="large"
              css={{ display: focus ? "flex" : "none", borderRadius: "999px" }}
              onClick={handleConfirm}
              disabled={!text || text.trim() === ""}
            >
              <span>확인</span>
            </Button>
          </div>
        </div>

        <TextArea
          size="x-large"
          placeholder="주 증상을 입력하세요"
          handleClearValue={() => {
            setText("");
          }}
          value={text}
          onChange={(e) => {
            const value = e.target.value;
            if (value === " ") return;
            setText(value);
          }}
          css={{ width: "100%", minHeight: "100px", height: "100%" }}
          ref={textAreaRef}
        />

        <div css={{ display: "flex", justifyContent: "center" }}>
          <Button
            size="x-large"
            css={{ display: focus ? "none" : "flex", width: "397px" }}
            disabled={!text || text.trim() === ""}
            onClick={handleConfirm}
          >
            확인
          </Button>
        </div>
      </div>
      <CustomInputModal
        retText={(recordText: string) => {
          setText(recordText);
        }}
        defaultText={""}
        isOpen={isCustomInput}
        toggleIsCustomInput={toggleIsCustomInput}
      />
    </NewLayout>
  );
};
