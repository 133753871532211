import AppSetting from "@/common/config/AppSetting";
import useAuthStore from "@/context/AuthStore";
import { NetworkManager } from "@/network-module/NetworkManager";
import styled from "@emotion/styled";
import { useStomp } from "@network-module/context/StompContext";
import { useEffect } from "react";

const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
});

const KioskRegister = () => {
  const deviceId = AppSetting.getDeviceUniqueKey();
  const { websocket } = useStomp();
  const store = useAuthStore();
  const authService = NetworkManager.authService;

  useEffect(() => {
    if (deviceId == null && store.isDevice) {
      store.setIsDevice(false);
    }
    websocket.subscribe(
      `/kiosk/${deviceId}/signin`,
      (message) => {
        location.reload();
      },
      "signin",
    );
  }, []);

  const getOtp = async () => {
    authService
      .kioskAdd({
        deviceId: AppSetting.getDeviceUniqueKey(),
      })
      .then((data) => {})
      .catch((e) => {
        store.setIsAuth(true);
        if (e.signIned == true) {
          store.setIsAuth(true);
          store.setIsDevice(true);
        }
      });
  };

  useEffect(() => {
    getOtp();
  }, []);

  return (
    // <>
    //   <NumberInputs />
    // </>
    <></>
  );
};
export default KioskRegister;
