// 발뒤 왼쪽
const fb_L = ["R-b-29", "R-b-28", "bL-b-32"];
// 발뒤 오른쪽
const fb_R = ["L-b-29", "L-b-28", "bR-b-32"];

// 발등 왼쪽
const ff_L = [
  "R-b-28",
  "R-b-29",
  "R-b-30",
  "R-b-35",
  "R-b-32",
  "R-b-33",
  "R-b-34",
  "R-b-36",
];
// 발등 오른쪽
const ff_R = [
  "L-b-28",
  "L-b-29",
  "L-b-30",
  "L-b-35",
  "L-b-32",
  "L-b-33",
  "L-b-34",
  "L-b-36",
];

// 발 안쪽 왼쪽
const fs_in_L = ["R-b-29", "R-b-36", "R-b-33", "R-b-37", "bL-b-32"];
// 발 안쪽 오른쪽
const fs_in_R = ["L-b-29", "L-b-36", "L-b-33", "L-b-37", "bR-b-32"];

// 발바닥 왼쪽
const fs_L = ["R-b-38", "R-b-39", "R-b-40", "R-b-41"];
// 발바닥 오른쪽
const fs_R = ["L-b-38", "L-b-39", "L-b-40", "L-b-41"];

// 발 바깥쪽 왼쪽
const fs_out_L = ["R-b-28", "bL-b-32", "R-b-35", "R-b-30", "R-b-32", "R-b-34"];
// 발 바깥쪽 오른쪽
const fs_out_R = ["L-b-28", "bR-b-32", "L-b-35", "L-b-30", "L-b-32", "L-b-34"];

// 발 전체 왼쪽
const foot_L = [...fb_L, ...ff_L, ...fs_in_L, ...fs_L, ...fs_out_L];
// 발 전체 오른쪽
const foot_R = [...fb_R, ...ff_R, ...fs_in_R, ...fs_R, ...fs_out_R];

export {
  fb_L,
  fb_R,
  ff_L,
  ff_R,
  foot_L,
  foot_R,
  fs_L,
  fs_R,
  fs_in_L,
  fs_in_R,
  fs_out_L,
  fs_out_R,
};

