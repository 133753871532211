import type {
  Predicate,
  PredicateParams,
} from "@/features/humanBodyDiagram/utils/conditionUtils";

export type Mapping<MappedValue> = {
  (params: PredicateParams): MappedValue | undefined;
  value: MappedValue;
};

export type MappingFactory = <MappedValue>(
  predicate: Predicate,
  mappedValue: MappedValue
) => Mapping<MappedValue>;

export const mappingFactory: MappingFactory = (predicate, mappedValue) => {
  const mapping = (params: PredicateParams) => {
    return predicate(params) ? mappedValue : undefined;
  };

  mapping.value = mappedValue;
  return mapping;
};

export const findMatchMapping = <MappedValue, Params extends PredicateParams>(
  mappings: Mapping<MappedValue>[],
  params: Params
): MappedValue | undefined => {
  return mappings.reduce(
    (acc: MappedValue | undefined, mapping) => acc ?? mapping(params),
    undefined
  );
};
