import { ArrowLeftIcon } from "@/theme/CustomIcons";
import styled from "@emotion/styled";
import { ChangeEvent, FC, useEffect, useRef } from "react";
import {
  COLORS,
  typography_style_body_md_semibold,
  UpdateBadge,
} from "vd-component";
import "./PainSlider.css";

const Container = styled.div({
  width: "100%",
});

interface PainSliderProps {
  disabled?: boolean;
  painValue: number;
  setPainValue: (value: number) => void;
}

const PainSlider: FC<PainSliderProps> = ({
  disabled = false,
  painValue,
  setPainValue,
}) => {
  const sliderRef = useRef<HTMLInputElement>(null);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target != null) {
      setPainValue(Number(event.target.value));
    }
  };

  useEffect(() => {
    const slider = sliderRef.current;

    if (slider == null) return;
    slider.style.setProperty("--slider-image", `url(/img/slider/code.svg)`);
  }, [painValue, sliderRef]);

  return (
    <Container>
      <div
        style={{
          display: "flex",
          width: `100%`,
          position: "relative",

          background: COLORS.colorBackgroundLight,
          borderRadius: "999px",
          padding: "20px 24px",
          justifyContent: "space-between",
          marginBottom: "16px",
        }}
      >
        <input
          type="range"
          min="0"
          max="10"
          value={painValue}
          ref={sliderRef}
          className="slider"
          onChange={handleChange}
          disabled={disabled}
        />

        {new Array(11).fill(0).map((_, idx) => {
          return (
            <UpdateBadge
              key={idx}
              size="x-large"
              css={{
                width: "32px",
                height: "32px",
                fontSize: "20px",
                fontWeight: 700,
                background: COLORS.colorBackgroundHeavy,
              }}
              variant="numbering"
              colorType="gray"
              count={idx}
            />
          );
        })}
      </div>
      <div
        css={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          css={{
            padding: "4px 12px",
            display: "flex",
            gap: "4px",
            alignItems: "center",
          }}
        >
          <ArrowLeftIcon />
          <span
            css={[
              typography_style_body_md_semibold,
              { color: COLORS.colorLabelLight },
            ]}
          >
            증상으로 없어요
          </span>
        </div>
        <div
          css={{
            padding: "4px 12px",
            display: "flex",
            gap: "4px",
            alignItems: "center",
          }}
        >
          <span
            css={[
              typography_style_body_md_semibold,
              { color: COLORS.colorLabelLight },
            ]}
          >
            일상생활이 불가능해요
          </span>
          <ArrowLeftIcon css={{ transform: "rotate(180deg)" }} />
        </div>
      </div>
    </Container>
  );
};

export default PainSlider;
