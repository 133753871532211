import { css } from "@emotion/react";
import { UpdateBadge } from "vd-component";
import { DesignSystemSize, DesignSystemState } from "../DesignSystemType";

const defaultStyle = css`
  flex: 1;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

const stateStyle = {
  [DesignSystemState.SELECTED]: css`
    color: var(--color-label-nomal, #2e2f33);
    background: var(--color-inverse-label-light, #fff);
  `,
  [DesignSystemState.DEFAULT]: css`
    color: var(--color-label-light, #46474c);
    background: var(--color-background-nomal, #dde0e2);
  `,
  [DesignSystemState.DISABLED]: css`
    color: var(--color-label-nomal, #2e2f33);
    background: var(--color-inverse-label-light, #fff);
    opacity: 0.6;
  `,
};
const sizeStyle = {
  [DesignSystemSize.LARGE]: css`
    padding: var(--spacing-12, 12px) var(--spacing-16, 16px);
    border-radius: var(--Border-Radius-8, 8px);
    font-size: 16px;
  `,
  [DesignSystemSize.MEDIUM]: css`
    padding: var(--spacing-8, 8px) var(--spacing-16, 16px);
    border-radius: var(--Border-Radius-6, 6px);
    font-size: 14px;
  `,
  [DesignSystemSize.SMALL]: css`
    padding: var(--spacing-8, 8px) var(--spacing-12, 12px);
    border-radius: var(--Border-Radius-4, 4px);
    font-size: 12px;
  `,
  [DesignSystemSize.XSMALL]: css`
    padding: var(--spacing-4, 4px) var(--spacing-8, 8px);
    border-radius: var(--Border-Radius-4, 4px);
    font-size: 12px;
  `,
};

export const SegmentedControlChild: React.FC<{
  size: DesignSystemSize;
  state: DesignSystemState;
  text: string;
  count?: number;
  onClick?: () => void;
}> = ({ size, state, text, count, onClick }) => {
  return (
    <div
      css={[defaultStyle, stateStyle[state], sizeStyle[size]]}
      onClick={onClick}
    >
      {text}
      {count > 0 && (
        <div
          style={{
            visibility: count >= 1 ? "visible" : "hidden",
          }}
        >
          <UpdateBadge
            size="medium"
            count={count}
            variant="numbering"
            colorType="primary"
          />
        </div>
      )}
    </div>
  );
};
