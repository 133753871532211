import Help from "@/assets/icons/help.svg";
import SubHeader from "@/components/common/SubHeader";
import { ReceiptSearchResponse } from "@/network-module/services/HospitalService";
import { t } from "i18next";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Button,
  COLORS,
  Title,
  typography_style_body_md_medium,
} from "vd-component";
import PatientCard from "./PatientCard";

const PatientSelectList = ({
  nextStep,
  patientList,
  backFunc,
  setStep,
}: {
  nextStep: Function;
  patientList: Array<any> | undefined;
  backFunc: Function;
  setStep: Function;
}) => {
  const [selectedPatient, setSelectedPatient] =
    useState<ReceiptSearchResponse>();

  const location = useLocation();
  const isReception = location.pathname.includes("/reception");
  const selectPatient = (patient: any) => {
    setSelectedPatient(patient);
  };

  return (
    <main
      css={{
        background: COLORS.colorBackgroundLight,
        height: "100%",
        padding: "0 40px 40px",
        display: "flex",
        flexDirection: "column",
        gap: "25px",
      }}
    >
      <SubHeader backFunc={backFunc} />
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          padding: patientList.length > 3 ? "50px 58px 11px" : "73px 58px 34px",
          gap: "24px",
          background: COLORS.colorBackgroundWhite,
          borderRadius: "12px",
          boxShadow:
            "0 0 2px 0 rgba(0, 0, 0, 0.08), 0 2px 4px 0 rgba(0, 0, 0, 0.08), 0 2px 8px 0 rgba(0, 0, 0, 0.12)",
        }}
      >
        {/* 타이틀 */}
        <Title
          title={t("checkup.patientSelect.title")}
          highlightArr={[t("common.name"), t("common.birth")]}
          description={t("checkup.patientSelect.description")}
          size="2x-large"
          css={{ alignItems: "flex-start" }}
        />

        {/* 환자 리스트 */}
        <div
          css={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: "20px",
            maxHeight: patientList.length > 3 ? "164px" : "124px",
            overflowY: "auto",
          }}
        >
          {patientList.map((patient) => {
            return (
              <PatientCard
                key={patient.patientId}
                patient={patient}
                nextStep={nextStep}
                selectedPatient={selectedPatient}
                selectPatient={selectPatient}
              />
            );
          })}
        </div>

        {/* 버튼 */}
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            alignItems: "center",
          }}
        >
          <Button
            size="x-large"
            css={{ width: "397px", height: "68px" }}
            onClick={() => {
              if (selectedPatient?.hospitalPatientId === 0) return;
              nextStep(selectedPatient);
            }}
            disabled={!selectedPatient}
          >
            {t("common.confirm")}
          </Button>
          {isReception && (
            <div
              css={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
                padding: "4px 6px",
                cursor: "pointer",
              }}
              onClick={() => setStep(2)}
            >
              <img src={Help} alt="helpIcon" />
              <span
                css={[
                  typography_style_body_md_medium,
                  { color: COLORS.colorLabelLight },
                ]}
              >
                본인의 정보가 보이지 않나요?
              </span>
            </div>
          )}
        </div>
      </div>
    </main>
  );
};
export default PatientSelectList;
