import { ReactNode } from "react";
import styled from "@emotion/styled";

interface ModalProps {
  isActive: boolean;
}

export const ModalOverlay = styled.div<ModalProps>(
  {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 999,
  },
  ({ isActive }) => ({
    display: isActive ? "flex" : "none",
  })
);

export const ModalContent = styled.div({
  background: "#fff",
  padding: "20px",
  borderRadius: "8px",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
  width: "100%",
  display: "flex",
  transition: "opacity 0.3s ease, transform 0.3s ease",
});
