import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./locales/en/translation.json";
import translationKO from "./locales/ko/translation.json";

const resources = {
  en: {
    translation: translationEN,
  },
  ko: {
    translation: translationKO,
  },
};

i18next.use(initReactI18next).init({
  resources,
  lng: import.meta.env.VITE_DISPLAY_LANGUAGE,
  detection: {
    order: ["path", "htmlTag", "cookie", "navigator"],
  },
  fallbackLng: import.meta.env.VITE_DISPLAY_LANGUAGE,
  ns: ["translation"],
  debug: true,
  load: "languageOnly",
  partialBundledLanguages: true,
});

export default i18next;
