import { Header } from "@/components/layout/Layouts";
import { t } from "i18next";
import { Button, ButtonContainer } from "vd-component";
import ScreenDetailHook from "./ScreenDetail.hook";
import {
  Container,
  Footer,
  IndexContainer,
  IndexContent,
  QuestionContainerDisplay,
  QuestionCustomTextArea,
} from "./ScreenDetail.style";

interface ScreenDetailProps {
  departmentId: number;
  setDetailQuestion: Function;
  backFunc: Function;
  setStep: Function;
}

const ScreenDetail = ({
  departmentId,
  setDetailQuestion,
  backFunc,
  setStep,
}: ScreenDetailProps) => {
  const {
    maxIndex,
    currentIndex,
    prevQuestion,
    nextQuestion,
    pagePassable,
    mainDisplayQuestion,
    subDisplayQuestion,
    toggleAnswer,
    checkAnswer,
    displayInfo,
    questionListById,
    isInputOpen,
    customAnswer,
    toggledCustomAnswer,
    setToggledCustomAnswer,
    toggleCustomAnswer,
    unSetCustomAnswer,
    returnDetailQuestion,
    toggleOnAnswer,
    toggleOffAnswer,
  } = ScreenDetailHook(departmentId);

  return (
    <>
      <Container>
        <Header data={true} backText={t("common.goBack")} backFunc={backFunc} />
        <QuestionContainerDisplay
          setToggledCustomAnswer={setToggledCustomAnswer}
          checkAnswer={checkAnswer}
          toggleAnswer={toggleAnswer}
          currentIndex={currentIndex}
          mainDisplayQuestion={mainDisplayQuestion}
          subDisplayQuestion={subDisplayQuestion}
          displayType={displayInfo[currentIndex]}
          questionListById={questionListById}
          customAnswer={customAnswer}
          unSetCustomAnswer={unSetCustomAnswer}
        />
        <Footer>
          <IndexContainer>
            <IndexContent maxIndex={maxIndex} currentIndex={currentIndex} />
          </IndexContainer>

          <ButtonContainer css={{ gap: "12px" }}>
            <Button
              onClick={() => prevQuestion(setStep)}
              css={{ padding: "16px 76.5px" }}
              size="x-large"
              variant="secondary-line"
            >
              {t("checkup.screenDetailQuestion.backBtn")}
            </Button>
            <Button
              disabled={!pagePassable()}
              onClick={() => {
                if (pagePassable()) {
                  if (nextQuestion()) {
                    setDetailQuestion(returnDetailQuestion());
                  }
                }
              }}
              css={{ padding: "16px 76.5px" }}
              size="x-large"
              variant="primary"
            >
              {t("checkup.screenDetailQuestion.nextBtn")}
            </Button>
          </ButtonContainer>
        </Footer>
      </Container>
      {isInputOpen && (
        <QuestionCustomTextArea
          setToggledCustomAnswer={setToggledCustomAnswer}
          questionTitle={questionListById[toggledCustomAnswer]?.question ?? ""}
          customInputText={customAnswer[toggledCustomAnswer] ?? ""}
          setCustomInputText={(text: string) => {
            const answerId = questionListById[
              toggledCustomAnswer
            ]?.screeningAnswer?.find((answer) => answer.type == 1)?.id;

            if (text.length > 0) {
              toggleCustomAnswer(toggledCustomAnswer, text);
              toggleOnAnswer(
                toggledCustomAnswer,
                answerId,
                // questionListById[toggledCustomAnswer]?.multiAnswer
              );
            } else {
              toggleOffAnswer(toggledCustomAnswer, answerId);
            }
          }}
        />
      )}
    </>
  );
};
export default ScreenDetail;
