import NewLayout from "@/components/common/NewLayout";
import { NetworkManager } from "@/network-module/NetworkManager";
import { useState } from "react";
import { Button, Title } from "vd-component";
import DoctorCard from "./DoctorCard";

const ReceptionSelectDoctor = ({
  nextStep,
  backFunc,
}: {
  nextStep: Function;
  backFunc: Function;
}) => {
  const [doctorId, setDoctorId] = useState(0);
  const [doctorIndex, setDoctorIndex] = useState(0);

  const { data: doctorData } =
    NetworkManager.hospitalService.getDoctorListWithWait();

  return (
    <NewLayout
      backFunc={backFunc}
      css={{
        width: "100%",
        height: "100%",
        display: "flex",
        gap: "24px",
        flexDirection: "column",
        padding: "53px 58px",
      }}
    >
      <Title
        size="2x-large"
        title="진료 받으실 {0}를 선택해주세요"
        highlightArr={["의사"]}
      />
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(3,1fr)",
          width: "100%",
          gap: "20px",
          maxHeight: "230px",
          minHeight: "200px",
          overflowY: "auto",
        }}
      >
        {doctorData?.map((doctor, idx) => {
          return (
            <DoctorCard
              key={doctor.id}
              doctor={doctor}
              idx={idx}
              doctorId={doctorId}
              setDoctorId={setDoctorId}
              setDoctorIndex={setDoctorIndex}
            />
          );
        })}
      </div>
      <div css={{ display: "flex", justifyContent: "center" }}>
        <Button
          size="x-large"
          css={{ width: "397px", height: "68px" }}
          disabled={doctorId === 0}
          onClick={() => {
            if (doctorId == 0) return;
            nextStep(
              doctorId,
              doctorData[doctorIndex].name,
              doctorData[doctorIndex].departments[0].id
            );
          }}
        >
          <span>접수</span>
        </Button>
      </div>
    </NewLayout>
  );
};

export default ReceptionSelectDoctor;
