import styled from "@emotion/styled";

import { useEffect, useRef, useState } from "react";

import {
  COLORS,
  Tooltip,
  typography_style_heading_2xl_medium,
} from "vd-component";
import Error from "../../../assets/icons/error.svg";
import { PatientSizeType } from "../store/ReceptionPatientSizeHook";

const InputContainer = styled.div({
  display: "flex",
  justifyContent: "center",
  ".whiteBox": {
    background: "var(--white001)",
    width: "95%",
    borderRadius: "14px",
    padding: "30px 36.5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  ".bodySizeWhiteInput": {
    background: "var(--white001)",
    borderRadius: "14px",
    padding: "12px 24px",
    height: "96px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    border: "4px solid transparent",
    fontSize: "32px",
    fontWeight: 500,
    outline: "none",
  },
});

const numberInputSpan = (numArray: Array<string>) => {
  return numArray.map((v, k) => {
    let color = "";
    let text = v;

    if (v == "_") {
      color = "var(--grey004)";
      text = "0";
    }
    return (
      <span
        key={k}
        css={{
          color: color,
          fontSize:
            "clamp(1.966875rem, 3.1251241310824227vw, 6.406504468718967rem)",
          fontWeight: "600",
        }}
      >
        {text}
      </span>
    );
  });
};

const numberInputSpan2 = (numArray: Array<string>) => {
  return numArray.map((v, k) => {
    let color = "";
    let text = v;
    if (v == "_") {
      color = COLORS.colorLabelAlternative;
      text = "0";
    }
    return (
      <span
        key={k}
        css={{
          color,
        }}
      >
        {text}
      </span>
    );
  });
};

export const NameInputContent = ({ name }: { name: string }) => {
  const nameSplit = name.split("");
  return (
    <InputContainer>
      <div className="whiteBox">
        <span
          style={{
            fontSize: "52px",
            fontWeight: "600",
            color: name.length > 0 ? "var(--black001)" : "var(--grey004)",
          }}
        >
          {name.length > 0 ? nameSplit.join(" ") : `홍 길 동`}
        </span>
      </div>
    </InputContainer>
  );
};

export const IdentityInputContent = ({
  identityNumber,
  keypadRef,
}: {
  identityNumber: Array<string>;
  keypadRef: React.RefObject<HTMLDivElement>;
}) => {
  const [focus, setFocus] = useState(false);
  const identityRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (
        identityRef.current?.contains(e.target as Node) ||
        keypadRef.current?.contains(e.target as Node)
      ) {
        setFocus(true);
      } else {
        setFocus(false);
      }
    };

    window.addEventListener("click", handleClick);
    return () => window.removeEventListener("click", handleClick);
  }, []);
  return (
    <div
      css={[
        typography_style_heading_2xl_medium,
        {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "4px",
          height: "96px",
          border: focus ? "4px solid" : "2px solid",
          borderColor: focus ? COLORS.colorPrimaryLight : COLORS.colorLineNomal,
          borderRadius: "12px",
          padding: "12px 24px",
          color: COLORS.colorLabelNomal,
          boxShadow: focus
            ? "0 0 0 4px rgba(0, 0, 0, 0.08)"
            : "0 0 0 0 rgba(0, 0, 0, 0)",
        },
      ]}
      ref={identityRef}
    >
      <div css={{ display: "flex", gap: "2px" }}>
        {numberInputSpan2(identityNumber.slice(0, 6))}
      </div>
      <span>-</span>
      <span
        css={{
          color: identityNumber[6] == "_" ? COLORS.colorLabelAlternative : "",
        }}
      >
        {identityNumber[6] == "_" ? "0" : identityNumber[6]}
      </span>
      {Array(6)
        .fill(0)
        .map((v, k) => {
          let color = "";

          if (identityNumber[6] == "_") {
            color = COLORS.colorLabelAlternative;
          }

          return (
            <span
              key={k}
              css={{
                color: color,
                fontSize:
                  "clamp(1.966875rem, 3.1251241310824227vw, 6.406504468718967rem)",
                fontWeight: "600",
              }}
            >
              •
            </span>
          );
        })}
    </div>
  );
};

export const PhoneInputContent = ({
  phoneNumber,
  keypadRef,
}: {
  phoneNumber: Array<string>;
  keypadRef: React.RefObject<HTMLDivElement>;
}) => {
  const [focus, setFocus] = useState(false);
  const identityRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setFocus(true);
    const handleClick = (e: MouseEvent) => {
      if (
        identityRef.current?.contains(e.target as Node) ||
        keypadRef.current?.contains(e.target as Node)
      ) {
        setFocus(true);
      } else {
        setFocus(false);
      }
    };

    window.addEventListener("click", handleClick);
    return () => window.removeEventListener("click", handleClick);
  }, []);
  return (
    <div
      css={[
        typography_style_heading_2xl_medium,
        {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "4px",
          height: "96px",
          border: focus ? "4px solid" : "2px solid",
          borderColor: focus ? COLORS.colorPrimaryLight : COLORS.colorLineNomal,
          borderRadius: "12px",
          padding: "12px 24px",
          color: COLORS.colorLabelNomal,
          boxShadow: focus
            ? "0 0 0 4px rgba(0, 0, 0, 0.08)"
            : "0 0 0 0 rgba(0, 0, 0, 0)",
        },
      ]}
    >
      <div css={{ display: "flex", gap: "2px" }}>
        {numberInputSpan2(phoneNumber.slice(0, 3))}
      </div>
      <span>-</span>
      <div css={{ display: "flex", gap: "2px" }}>
        {numberInputSpan2(phoneNumber.slice(3, 7))}
      </div>
      <span>-</span>
      <div css={{ display: "flex", gap: "2px" }}>
        {numberInputSpan2(phoneNumber.slice(7, 11))}
      </div>
    </div>
  );
};

export const BodyInputContent = ({
  patientSize,
}: {
  patientSize: PatientSizeType;
}) => {
  const { bodyFocus, weightRef, heightRef, setBodyFocus, bodySize, error } =
    patientSize;
  const focusWeight = bodyFocus === "weight";
  const focusHeight = bodyFocus === "height";

  const errorWeight = error.weight;
  const errorHeight = error.height;

  useEffect(() => {
    setBodyFocus("height");
  }, []);

  return (
    <Tooltip
      size="large"
      variant="secondary"
      content="키와 몸무게 입력시 더 개인화된 증상 요약을 받을 수 있어요"
      isOpen={bodySize.height === "" && bodySize.weight === ""}
      isHoverAction={false}
      tooltipBoxStyles={{ zIndex: 2 }}
    >
      <InputContainer
        css={{
          flexDirection: "column",
          gap: "16px",
          position: "relative",
        }}
      >
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            position: "relative",
            width: "100%",
            "&::after": {
              content: "'cm'",
              display: "block",
              position: "absolute",
              top: errorHeight ? "calc(50% - 16px)" : "50%",
              right: "24px",
              transform: "translateY(-50%)",
              fontSize: "32px",
              fontWeight: 600,
            },
          }}
        >
          <input
            className="bodySizeWhiteInput"
            onFocus={() => {
              setBodyFocus("height");
            }}
            value={bodySize.height}
            placeholder="0.00"
            readOnly
            style={{
              border: focusHeight ? "4px solid" : "2px solid",
              borderColor: errorHeight
                ? "#FF6363"
                : focusHeight
                ? "var(--color-inverse-primary-light, #747DEF)"
                : COLORS.colorLineNomal,
              boxShadow: focusHeight ? "0 0 0 4px rgba(0, 0, 0, 0.08)" : "none",
            }}
            ref={heightRef}
          />
          {errorHeight && (
            <div css={{ display: "flex", gap: "6px", alignItems: "center" }}>
              <img src={Error} alt="error" />
              <span style={{ color: "var(--color-system-error, #FF4242)" }}>
                키와 몸무게 모두 입력해야합니다
              </span>
            </div>
          )}
        </div>
        <div
          css={{
            position: "relative",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            "&::after": {
              content: "'kg'",
              display: "block",
              position: "absolute",
              top: errorWeight ? "calc(50% - 16px)" : "50%",
              right: "24px",
              transform: "translateY(-50%)",
              fontSize: "32px",
              fontWeight: 600,
            },
          }}
        >
          <input
            className="bodySizeWhiteInput"
            onFocus={() => {
              setBodyFocus("weight");
            }}
            value={bodySize.weight}
            placeholder="0.00"
            readOnly
            style={{
              border: focusWeight ? "4px solid" : "2px solid",
              borderColor: errorWeight
                ? "#FF6363"
                : focusWeight
                ? "var(--color-inverse-primary-light, #747DEF)"
                : COLORS.colorLineNomal,
              boxShadow: focusWeight ? "0 0 0 4px rgba(0, 0, 0, 0.08)" : "none",
            }}
            ref={weightRef}
          />
          {errorWeight && (
            <div css={{ display: "flex", gap: "6px", alignItems: "center" }}>
              <img src={Error} alt="error" />
              <span style={{ color: "var(--color-system-error, #FF4242)" }}>
                키와 몸무게 모두 입력해야합니다
              </span>
            </div>
          )}
        </div>
      </InputContainer>
    </Tooltip>
  );
};

interface DoctorSelectBoxProps {
  selectedId: number;
  doctorId: number;
  clinicClosure: boolean;
}

export const DoctorSelectBox = styled.div<DoctorSelectBoxProps>((props) => ({
  height: "100%",
  display: "flex",
  background:
    props.selectedId == props.doctorId ? "#E8EAFF" : "var(--white001)",
  margin: "18px 12px",
  borderRadius: "10px",
  flexDirection: "column",
  border:
    props.selectedId == props.doctorId
      ? "2px solid var(--color-line-primary, #747DEF)"
      : "2px solid var(--color-line-nomal, #DBDBDB)",
  ".closureText": {
    padding: props.clinicClosure ? "8px 22.5px" : "8px 16px",
    fontSize: "16px",
    color: props.clinicClosure ? "#999" : "var(--white001)",
    marginBottom: "15px",
    background: props.clinicClosure ? "#DBDBDB" : "#5560EB",
    borderRadius: "6px",
    width: "fit-content",
  },
}));
