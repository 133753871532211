import StorageManager from "@/common/services/StorageManager";
import axios from "axios";
import ErrorRegister from "./ErrorRegister";
import HttpClient from "./HttpClient";

export default class AxiosClient implements HttpClient {
  api = axios.create({
    baseURL: import.meta.env.VITE_API_URL,
    timeout: 10000,
    withCredentials: true,
    headers: {
      "Access-Control-Allow-Origin": import.meta.env.VITE_API_URL,
    },
  });
  accessToken: string = "";

  private errRegister = new ErrorRegister(this.api);

  constructor() {
    this.api.interceptors.request.use((config) => {
      const tokenData = StorageManager.getToken();
      if (tokenData !== null) {
        config.headers.Authorization = `Bearer ${tokenData.accessToken}`;
      }
      return config;
    });

    this.api.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error?.response?.status == null) throw error;
        const handler = this.errRegister.getStatusCodeHandle(
          error.response.status
        );
        return handler(error);
      }
    );

    // if (import.meta.env.VITE_USE_MOCK) {
    //   registerMockup(this.api);
    // }
  }

  get<T>(
    url: string,
    options?: { params?: any; signal?: AbortSignal; headers?: any }
  ): Promise<T> {
    return this.api.get<T>(url, options).then((res) => res.data);
  }

  post<T>(
    url: string,
    data?: any,
    options?: { params?: any; signal?: AbortSignal }
  ): Promise<T> {
    return this.api.post<T>(url, data, options).then((res) => res.data);
  }

  put<T>(
    url: string,
    data?: any,
    options?: { params?: any; signal?: AbortSignal }
  ): Promise<T> {
    return this.api.put<T>(url, data, options).then((res) => res.data);
  }

  delete<T>(
    url: string,
    data?: any,
    options?: { params: any; signal?: AbortSignal }
  ): Promise<T> {
    return this.api.delete<T>(url, data).then((res) => res.data);
  }
}
