import styled from "@emotion/styled";
import { ButtonHTMLAttributes, CSSProperties } from "react";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  variant?: "default" | "withIcon";
  src?: string;
  alt?: string;
  placeholder?: "empty" | "blur" | undefined;
  imgStyle?: object;
  labelStyle?: object;
  pressedCss?: CSSProperties;
}

const Button = ({
  type = "button",
  label,
  variant = "default",
  src,
  alt,
  placeholder = "empty",
  imgStyle,
  labelStyle,
  pressedCss = {},
  ...props
}: ButtonProps) => {
  return (
    <>
      <ButtonContainer
        label={label}
        type={type}
        {...props}
        pressedCss={pressedCss}
      >
        {src && (
          <img
            src={src}
            alt={alt}
            aria-placeholder={placeholder}
            css={{
              ...imgStyle,
              aspectRatio: "1",
            }}
          />
        )}
        <span css={{ ...labelStyle }}>{label}</span>
      </ButtonContainer>
    </>
  );
};

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  pressedCss?: CSSProperties;
}

const ButtonContainer = styled.button<ButtonProps>((props) => ({
  position: "relative",
  padding: "12px 24px",
  borderRadius: "24px",
  display: "flex",
  alignItems: "center",
  ":active": { ...props.pressedCss },
}));

const BUTTON_VARIANT_STYLE = {};

export default Button;
