import { AuthRepository } from "@network-module/repository/AuthRepository";
import { Device } from "../entities/DeviceEntity";

export class AuthService {
  repo: AuthRepository;
  constructor(repo: AuthRepository) {
    this.repo = repo;
  }

  kioskAdd(data: Device) {
    return this.repo.kioskAdd(data);
  }

  kioskSignInFirst(data: Device) {
    return this.repo.kioskSignInFirst(data);
  }

  searchPatients(birth: string, identityGender: string | number) {
    return this.repo.searchPatients(birth, identityGender);
  }
}
