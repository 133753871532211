import AiBgLottie from "@assets/lottie/ai_bg.json";
import Player from "lottie-react";
const AiBg = () => {
  return (
    <div
      css={{
        width: "100%",
        height: "100%",
        position: "fixed",
        top: 0,
        left: 0,
      }}
    >
      <div
        css={{
          background: "rgba(0, 0, 0, 0.2)",
          width: "100%",
          height: "100%",
          zIndex: 1,
          position: "fixed",
          top: 0,
          left: 0,
        }}
      />
      <Player loop={true} animationData={AiBgLottie} />
    </div>
  );
};

export default AiBg;
