import {
  usePatientDataStore,
  usePatientStore,
} from "@/features/Screen/store/ScreenStore";
import { NetworkManager } from "@/network-module/NetworkManager";
import { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import { Button, ButtonContainer, Popup, Title } from "vd-component";

interface GoToFirstModalProps {
  isOpen: boolean;
  checkupId: number;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}
const GoToFirstModal = ({
  isOpen,
  checkupId,
  setIsOpen,
}: GoToFirstModalProps) => {
  const navigate = useNavigate();
  const patientStore = usePatientStore();
  const patientDataStore = usePatientDataStore();
  const handleToFirst = () => {
    NetworkManager.checkupService.kioskResetForce(checkupId).then(() => {
      patientStore.resetData();
      patientDataStore.resetData();
      navigate("/");
    });
  };

  return (
    <Popup
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      css={{
        padding: "24px 20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "32px",
        zIndex: 3,
      }}
      backdropStyles={{
        zIndex: 3,
      }}
    >
      <Title
        size="x-large"
        title="처음으로 돌아가시겠습니까?"
        description="처음으로 돌아가는 경우<br />작성한 내용이 모두 삭제됩니다"
        css={{ textAlign: "center" }}
      />
      <ButtonContainer css={{ width: "360px" }}>
        <Button
          size="large"
          variant="secondary-line"
          onClick={() => {
            setIsOpen(false);
          }}
        >
          취소
        </Button>
        <Button size="large" variant="primary" onClick={handleToFirst}>
          확인
        </Button>
      </ButtonContainer>
    </Popup>
  );
};

export default GoToFirstModal;
