// 전후면 왼손
const body_hand_L = ["L-hand-32", "bL-hand-34"];
// 전후면 오른손
const body_hand_R = ["R-hand-32", "bR-hand-33"];

// 전후면 왼발
const body_foot_L = ["L-foot-31", "bL-foot-35"];
// 전후면 오른발
const body_foot_R = ["R-foot-33", "bR-foot-34"];

export { body_foot_L, body_foot_R, body_hand_L, body_hand_R };
