import SubHeader from "@/components/common/SubHeader";
import LoadingFolder from "@assets/imgs/folder.png";
import LoadingBot from "@assets/imgs/robot.png";
import { useEffect, useState } from "react";
import AiLoading from "./AiLoading";

interface AiLoadingComponentProps {
  isFirst: boolean;
  handleModal: () => void;
}
const AiLoadingComponent = ({
  isFirst,
  handleModal,
}: AiLoadingComponentProps) => {
  const [isShowHeader, setIsShowHeader] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsShowHeader(true);
    }, 10000);
  }, []);
  return (
    <div css={{ display: "fixed", top: 0, left: 0, zIndex: 9999 }}>
      {isShowHeader && (
        <SubHeader
          backFunc={handleModal}
          backText="처음으로"
          isShowHospital={false}
          css={{
            position: "relative",
            width: "100%",
            zIndex: 99999,
            padding: "12px 40px",
          }}
        />
      )}
      {/* 다음 질문시 로딩 아이콘 */}
      {!isFirst && (
        <AiLoading
          icon={
            <img
              css={{ width: "240px", height: "240px" }}
              src={LoadingFolder}
            />
          }
          title="AI가 다음 질문을 만들고 있어요!<br />
잠시만 기다려주세요."
        />
      )}

      {/* 첫 문항 보여주기전 로딩 아이콘 */}
      {isFirst && (
        <AiLoading
          icon={
            <img
              css={{
                width: "240px",
                height: "240px",
              }}
              src={LoadingBot}
            />
          }
          title="문진을 기반으로 AI가<br />
8가지 맞춤 설문을 만들고 있어요!"
        />
      )}
    </div>
  );
};

export default AiLoadingComponent;
