import {
  usePatientDataStore,
  usePatientStore,
} from "@/features/Screen/store/ScreenStore";
import { NetworkManager } from "@/network-module/NetworkManager";
import { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import { Button, ButtonContainer, Popup, Title } from "vd-component";

interface ErrorGoToFirstModalProps {
  isOpen: boolean;
  checkupId: number;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}
const ErrorGoToFirstModal = ({
  isOpen,
  checkupId,
  setIsOpen,
}: ErrorGoToFirstModalProps) => {
  const navigate = useNavigate();
  const patientStore = usePatientStore();
  const patientDataStore = usePatientDataStore();
  const handleToFirst = () => {
    NetworkManager.checkupService.kioskResetForce(checkupId).then(() => {
      patientStore.resetData();
      patientDataStore.resetData();
      navigate("/");
    });
  };

  return (
    <Popup
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(true);
      }}
      css={{
        padding: "24px 20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "32px",
        zIndex: 3,
      }}
      backdropStyles={{
        zIndex: 3,
      }}
    >
      <Title
        size="x-large"
        title="네트워크 에러가 발생했습니다."
        description=""
        css={{ textAlign: "center" }}
      />
      <ButtonContainer css={{ width: "360px" }}>
        <Button size="large" variant="primary" onClick={handleToFirst}>
          처음으로
        </Button>
      </ButtonContainer>
    </Popup>
  );
};

export default ErrorGoToFirstModal;
