import { HumanBodyDirectionLabelInfo } from "@/features/humanBodyDiagram/components/DirectionLabelInfo/HumanBodyDirectionLabelInfo";
import { HumanBodyDirectionTabs } from "@/features/humanBodyDiagram/components/DirectionTab/HumanBodyDirectionTabs";
import { HumanBodyHeader } from "@/features/humanBodyDiagram/components/HumanBodyHeader";
import { HumanBodySvg } from "@/features/humanBodyDiagram/components/HumanBodySvg/HumanBodySvg";
import { HumanBodyResetButton } from "@/features/humanBodyDiagram/components/ResetButton/HumanBodyResetButton";
import { HumanBodySubCategoryTabs } from "@/features/humanBodyDiagram/components/SubCategoryTab/HumanBodySubCategoryTabs";
import { DepartmentEnum } from "@/features/humanBodyDiagram/entities/departmentMappings";
import ScreenSelectBodyButtonContainer from "@/features/Screen/ScreenSelectBody/ScreenSelectBodyButtonContainer";
import styled from "@emotion/styled";
import { useEffect } from "react";
import { useHumanBodyStore } from "../../context/useHumanBodyStore";
import {
  BodyMainCategoryEnum,
  BodySubCategoryEnum,
} from "../../entities/categories";

interface HumanBodyViewerProps {
  department?: DepartmentEnum;
  painAreas: string[];
  setBodyData: (data: string[]) => void;
}

export const HumanBodyWideViewer = ({
  painAreas,
  department,
  setBodyData,
}: HumanBodyViewerProps) => {
  const getMainCategory = () => {
    switch (department) {
      case DepartmentEnum.SURGERY:
        return BodyMainCategoryEnum.BODY;
      case DepartmentEnum.NEUROLOGY:
        return BodyMainCategoryEnum.NHEAD;
      case DepartmentEnum.ENT:
        return BodyMainCategoryEnum.EHEAD;
      case DepartmentEnum.INTERNAL:
        return BodyMainCategoryEnum.BODY;
    }
  };

  const getSubCategory = () => {
    switch (department) {
      case DepartmentEnum.SURGERY:
        return BodySubCategoryEnum.FRONT;
      case DepartmentEnum.NEUROLOGY:
        return BodySubCategoryEnum.BACK;
      case DepartmentEnum.ENT:
        return BodySubCategoryEnum.LEFT;
      case DepartmentEnum.INTERNAL:
        return BodySubCategoryEnum.FRONT;
    }
  };

  const { setDepartment, handleChangeMainCategory, setSubCategory } =
    useHumanBodyStore((store) => ({
      mainCategory: store.mainCategory,
      subCategory: store.subCategory,
      setDepartment: store.setDepartment,
      handleChangeMainCategory: store.handleChangeMainCategory,
      setSubCategory: store.setSubCategory,
    }));

  useEffect(() => {
    setDepartment(department);
    handleChangeMainCategory(getMainCategory());
    setSubCategory(getSubCategory());
  }, [department]);

  return (
    <>
      <BodyContainer>
        <HumanBodyHeader department={department} />

        <ContentContainer>
          {department === DepartmentEnum.SURGERY && <HumanBodyDirectionTabs />}

          <HumanBodySvgContainer css={{ zIndex: 1 }}>
            <HumanBodyResetButton />
            {department && (
              <HumanBodySvg department={department} painAreas={painAreas} />
            )}
            <HumanBodyDirectionLabelInfo />
          </HumanBodySvgContainer>

          <HumanBodySubCategoryTabs />
        </ContentContainer>
      </BodyContainer>
      <ScreenSelectBodyButtonContainer
        setBodyData={setBodyData}
        department={department}
      />
    </>
  );
};

const BodyContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px;
`;
const ContentContainer = styled.div`
  margin-top: 14px;

  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 8px;
  gap: 12px;
  background-color: white;
  overflow: hidden;
`;
const HumanBodySvgContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
