import {
  usePatientDataStore,
  usePatientStore,
} from "@/features/Screen/store/ScreenStore";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Popup,
  typography_style_heading_lg_semibold,
} from "vd-component";

const NetworkModal = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const patientStore = usePatientStore();
  const patientDataStore = usePatientDataStore();

  const [isOpen, setIsOpen] = useState(true);
  const handleReset = () => {
    patientStore.resetData();
    patientDataStore.resetData();
    setIsOpen(false);

    if (location.pathname === "/qr") return navigate("/qr");
    return navigate("/");
  };
  return (
    <Popup
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(true);
      }}
      css={{
        padding: "20px 24px",
        width: "350px",
        height: "200px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          gap: "4px",
          alignItems: "center",
        }}
      >
        <p css={[typography_style_heading_lg_semibold]}>
          인터넷 연결이 끊어졌습니다.
        </p>
        <p>다시 연결하려면 처음부터 다시 시작해주세요.</p>
      </div>
      <Button size="large" css={{ width: "100%" }} onClick={handleReset}>
        처음으로
      </Button>
    </Popup>
  );
};

export default NetworkModal;
