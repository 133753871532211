import { InputType, PadType } from "@/common/utils/Type";
import Keypad10Num from "@/components/common/Keypad10Num";
import PersonalDataInput from "@/components/kiosk/PersonalDataInput";

import {
  IdentityInputContent,
  PhoneInputContent,
} from "@/features/Reception/components/Reception.style";
import ReceptionIdentitiyValidPopup from "@/features/Reception/components/ReceptionIdentitiyValidPopup";
import ReceptionPatientNumberLogic from "@/features/Reception/store/ReceptionPatientNumberHook";
import ReceptionPatientPhaseLogic from "@/features/Reception/store/ReceptionPatientPhaseHook";
import {
  CheckReceiptEntity,
  PatientInfo,
} from "@/network-module/entities/HospitalEntity";
import { NetworkManager } from "@/network-module/NetworkManager";
import { useEffect, useRef, useState } from "react";
import ReceptionValidPopup from "../../features/Reception/components/ReceptionValidPopup";

const questionList = [
  { padType: PadType.num, inputType: InputType.phoneNumber },
  { padType: PadType.num, inputType: InputType.identityNumber },
];

const ReceptionSearchPatient = ({
  defaultName,
  nextStep,
  backFunc,
  defaultPhase = 0,
  patientData,
}: {
  defaultName: string;
  nextStep: Function;
  backFunc: Function;
  defaultPhase?: number;
  patientData: PatientInfo;
}) => {
  const keypadRef = useRef<HTMLDivElement>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [validateModalOpen, setValidateModalOpen] = useState<boolean>(false);

  const [patientInfo, setPatientInfo] = useState<any>({
    name: defaultName,
    identityNumber: [],
    phoneNumber: [],
  });

  const isRef = defaultPhase === 1;
  const phaseLogic = ReceptionPatientPhaseLogic(defaultPhase);

  const numberLogic = ReceptionPatientNumberLogic(isRef ? 7 : 11);

  const [errorList, setErrorList] = useState<Array<string>>([]);

  const checkNumberValidation = (identityCode: Array<string>) => {
    let validate = true;
    let error = [];
    if (!Array.isArray(identityCode) || identityCode.length !== 7) {
      error.push("잘못된 데이터입니다.");
      validate = false;
    }

    const inputDate = identityCode.join("");

    const year = parseInt(inputDate.slice(0, 2));
    const month = parseInt(inputDate.slice(2, 4));
    const day = parseInt(inputDate.slice(4, 6));

    const identityGender = parseInt(identityCode[6]);

    if (isNaN(year) || isNaN(month) || isNaN(day)) {
      error.push("숫자 형식의 입력이 아닙니다.");
      validate = false;
    }

    if (year < 0 || year > 99) {
      error.push("년도는 00에서 99 사이여야 합니다.");
      validate = false;
    }

    if (month < 1 || month > 12) {
      error.push("월은 01에서 12 사이여야 합니다.");
      validate = false;
    }

    if (identityGender < 1 || identityGender > 4) {
      error.push("뒷자리는 1에서 4 사이여야 합니다.");
      validate = false;
    }
    const defaultYear = identityGender > 2 ? 2000 : 1900;

    const daysInMonth = new Date(defaultYear + year, month, 0).getDate();
    if (day < 1 || day > daysInMonth) {
      error.push(
        `해당 월(${month})의 유효한 일은 01에서 ${daysInMonth} 사이여야 합니다.`,
      );
      validate = false;
    }

    return { validate, error };
  };

  const makeReciptForm = (
    phoneNumber: Array<string | number>,
  ): CheckReceiptEntity => {
    return {
      birth: patientInfo.identityNumber.slice(0, 6).join(""),
      phoneNumber: phoneNumber.join(""),
      identityGender: patientInfo.identityNumber[6],
      name: defaultName,
    };
  };
  const makeReciptForm2 = (
    phoneNumber: Array<string | number>,
    birth: string,
    identityGender: string,
  ): CheckReceiptEntity => {
    return {
      birth,
      phoneNumber: phoneNumber.join(""),
      identityGender,
      name: defaultName,
    };
  };

  const isButtonAvailable = () => {
    if ([0, 1].includes(phaseLogic.phase)) {
      return !numberLogic.number.includes("_");
    }
    return false;
  };

  useEffect(() => {
    setPatientInfo(patientData);

    if (patientData.phoneNumber.length > 0 && phaseLogic.phase === 0) {
      numberLogic.setLogicNumber(patientData.phoneNumber);
    }
    if (patientData.identityNumber.length > 0 && phaseLogic.phase === 1) {
      numberLogic.setLogicNumber(patientData.identityNumber);
    }
  }, [patientData]);

  return (
    <>
      <PersonalDataInput
        buttonAvailable={isButtonAvailable()}
        padContent={() => {
          return (
            <Keypad10Num numberLogic={numberLogic} keypadRef={keypadRef} />
          );
        }}
        inputContent={() => {
          if (phaseLogic.phase == 0) {
            return (
              <PhoneInputContent
                phoneNumber={numberLogic.number}
                keypadRef={keypadRef}
              />
            );
          }
          if (phaseLogic.phase == 1) {
            return (
              <IdentityInputContent
                identityNumber={numberLogic.number}
                keypadRef={keypadRef}
              />
            );
          }
          return <div></div>;
        }}
        backFunc={() => {
          if (phaseLogic.phase == 0) {
            backFunc();
          }
          if (phaseLogic.phase == 1) {
            backFunc();
          }
          phaseLogic.setPhase(phaseLogic.phase - 1);
        }}
        inputFunc={() => {
          if (phaseLogic.phase == 1) {
            let checkValidate = checkNumberValidation(numberLogic.number);

            if (!checkValidate.validate) {
              setValidateModalOpen(true);
              setErrorList(checkValidate.error);
              return false;
            }
          }

          if (phaseLogic.phase == 0) {
            numberLogic.initLength(11);
          }

          setPatientInfo((prevInfo) => {
            if (phaseLogic.phase == 0) {
              prevInfo.phoneNumber = numberLogic.number;
            }
            if (phaseLogic.phase == 1) {
              prevInfo.identityNumber = numberLogic.number;
            }

            return prevInfo;
          });

          if (phaseLogic.phase == 1) {
            NetworkManager.hospitalService
              .checkReceiptType(
                makeReciptForm2(
                  patientInfo.phoneNumber,
                  numberLogic.number.slice(0, 6).join(""),
                  numberLogic.number[6],
                ),
              )
              .then((data) => {
                nextStep(data, patientInfo);
              })
              .catch((e) => {
                setModalOpen(true);
              });
          } else {
            // 재진 환자인지 초진인지 당일접수인지
            NetworkManager.hospitalService
              .checkReceiptType(makeReciptForm(numberLogic.number))
              .then((data) => {
                nextStep(data, patientInfo);
              })
              .catch((e) => {
                setModalOpen(true);
              });
          }
        }}
        inputType={questionList[phaseLogic.phase].inputType}
      />

      {/* 주민등록번호 불일치 모달 */}
      <ReceptionIdentitiyValidPopup
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
      />

      {/* 유효성체크모달 */}
      <ReceptionValidPopup
        setValidateModalOpen={setValidateModalOpen}
        validateModalOpen={validateModalOpen}
        errorList={errorList}
      />
    </>
  );
};

export default ReceptionSearchPatient;
