export const HighLight = ({
  text = "",
  textColor = "#5560EB",
}: {
  text: string;
  textColor?: string;
}) => {
  return (
    <span
      style={{
        color: textColor,
        userSelect: "none",
      }}
    >
      {text}
    </span>
  );
};

export const HighLightComplex = ({
  hlArr,
  hlStr,
}: {
  hlArr: Array<string>;
  hlStr: string;
}) => {
  const parts = hlStr.split(/\{(\d+)\}/g);

  return (
    <>
      {" "}
      {parts.map((part: number | string, index) =>
        index % 2 === 1 ? (
          <HighLight key={index} text={hlArr[part as number]} />
        ) : (
          part
        )
      )}
    </>
  );
};
