import { preloadSvgCache } from "@/features/humanBodyDiagram/helper/preloadSvgCache";
import { create } from "zustand";

export interface SvgCacheState {
  svgCache: Record<string, string>;
  setSvgCache: (key: string, svg: string) => void;
  getSvgCache: (key: string) => string | undefined;
}

export const useSvgCacheStore = create<SvgCacheState>((set, get) => {
  preloadSvgCache().then((initialCache) => {
    set({ svgCache: initialCache });
  });

  return {
    svgCache: {},
    setSvgCache: (key, svg) =>
      set((state) => ({
        svgCache: { ...state.svgCache, [key]: svg },
      })),
    getSvgCache: (key) => get().svgCache[key],
  };
});
