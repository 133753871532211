import {
  ArrowRotateLeftIcon,
  MicrophoneIcon,
  WaveformIcon,
} from "@/theme/CustomIcons";
import { forwardRef, useEffect } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import "regenerator-runtime";
import "regenerator-runtime/runtime";
import {
  Button,
  ButtonContainer,
  COLORS,
  Title,
  typography_style_heading_md_medium,
} from "vd-component";
import { CenterBox } from "./Phase3.style";
import { RecordIconBtn } from "./RecordIconBtn";

export const CustomInputModal = forwardRef<
  HTMLDivElement,
  {
    isOpen: boolean;
    toggleIsCustomInput: () => void;
    defaultText?: string;
    retText?: Function;
  }
>(({ isOpen, toggleIsCustomInput, defaultText, retText }, ref) => {
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  function onRecordClick() {
    if (listening) {
      SpeechRecognition.stopListening();
    } else {
      SpeechRecognition.startListening({ continuous: true });
    }
  }

  if (!browserSupportsSpeechRecognition) {
    return <span>음성인식을 지원하지 않는 브라우져 입니다</span>;
  }

  const handleConfirm = () => {
    onRecordClick();
    resetTranscript();
    if (retText) {
      retText(defaultText + " " + transcript);
    }
    toggleIsCustomInput();
  };

  useEffect(() => {
    if (isOpen) {
      SpeechRecognition.startListening({ continuous: true });
      resetTranscript();
    } else {
      SpeechRecognition.stopListening();
    }
  }, [isOpen]);

  return (
    <div
      css={{
        display: isOpen ? "flex" : "none",
        position: "fixed",
        background: COLORS.colorOpacity80,
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 4,
      }}
      ref={ref || undefined}
    >
      <img
        src="/src/assets/icons/record/recordBg.svg"
        alt="voice_bg"
        css={{
          display: listening ? "block" : "none",
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 2,
          animation: "spin 5s linear infinite",
          "@keyframes spin": spinAnimation,
        }}
      />
      <div
        css={{
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 3,

          width: "100%",
        }}
      >
        <Title
          size="2x-large"
          title="음성 입력"
          description="말씀하시는 내용이 입력되고 있습니다"
          css={{
            color: COLORS.colorStaticWhite,
            marginBottom: "33px",

            width: "100%",
          }}
        />

        {/* 정지 / 재생 / 버튼 */}
        <div
          css={{
            display: "flex",
            gap: "24px",
            alignItems: "center",
            marginBottom: "24px",
            justifyContent: "center",
          }}
        >
          <RecordIconBtn listening={listening} onClick={onRecordClick} />
          <CenterBox
            css={{
              width: "132px",
              height: "132px",
            }}
          >
            {listening ? (
              <WaveformIcon color={COLORS.colorBackgroundWhite} />
            ) : (
              <MicrophoneIcon
                color={COLORS.colorBackgroundWhite}
                css={{ width: "84px", height: "84px" }}
              />
            )}
          </CenterBox>
          <CenterBox
            css={{
              width: "84px",
              height: "84px",
              background: COLORS.colorBackgroundWhite,
            }}
            onClick={() => resetTranscript()}
          >
            <ArrowRotateLeftIcon color={COLORS.colorLabelNomal} />
          </CenterBox>
        </div>

        {/* 음성내용 */}
        <p
          css={[
            typography_style_heading_md_medium,
            {
              color: COLORS.colorStaticWhite,
              marginBottom: "48px",
              maxHeight: "130px",
              overflowY: "auto",
              padding: "0 40px",
              textAlign: "center",
            },
          ]}
        >
          {transcript}
        </p>

        {/* 하단버튼 */}
        <div css={{ display: "flex", gap: "8px", justifyContent: "center" }}>
          <ButtonContainer css={{ width: "335px" }}>
            <Button
              size="large"
              variant="secondary-line"
              onClick={() => toggleIsCustomInput()}
            >
              취소
            </Button>
            <Button size="large" onClick={handleConfirm}>
              확인
            </Button>
          </ButtonContainer>
        </div>
      </div>
    </div>
    // <Modal
    //   isOpen={isOpen}
    //   onClose={(event) => {
    //     return;
    //   }}
    //   modalStyle={{
    //     minWidth: "531px",
    //     width: "50%",
    //     height: "482px",
    //     aspectRatio: 1,
    //     padding: "32px",
    //     gap: "24px",
    //     flexDirection: "column",
    //     justifyContent: "center",
    //     alignItems: "center",
    //   }}
    // >
    //   <TitleContainer>
    //     <RecordIconBtn listening={listening} onClick={onRecordClick} />
    //     <h2>말씀하시는 내용이 입력되고 있습니다.</h2>
    //   </TitleContainer>
    //   <div
    //     css={{
    //       flex: 1,
    //       padding: "12px 16px",
    //       borderRadius: "5px",
    //       background: "var(--new-fa-patient-surface-g-001, #F1F2F3)",
    //       width: "100%",
    //     }}
    //   >
    //     {defaultText + " " + transcript}
    //   </div>
    //   <BottomButtonContainer className="w-full">
    //     <Button
    //       css={{
    //         marginRight: "32px",
    //         flex: 1,
    //       }}
    //       label="취소"
    //       onClick={() => {
    //         toggleIsCustomInput();
    //       }}
    //     />
    //     <Button
    //       css={{
    //         width: "34.26124197002141%",
    //         marginRight: "12px",
    //       }}
    //       label="다시하기"
    //       onClick={() => {
    //         resetTranscript();
    //       }}
    //     />
    //     <Button
    //       disabled={!transcript}
    //       onClick={() => {
    // onRecordClick();
    // resetTranscript();
    // if (retText) {
    //   retText(defaultText + " " + transcript);
    // }
    // toggleIsCustomInput();
    //       }}
    //       css={{
    //         width: "34.26124197002141%",
    //         background: "#5560EB",
    //       }}
    //       label="입력하기"
    //     />
    //   </BottomButtonContainer>
    // </Modal>
  );
});

const spinAnimation = {
  from: {
    transform: "translate(-50%, -50%) rotate(0deg)",
  },
  to: {
    transform: "translate(-50%, -50%) rotate(360deg)",
  },
};
