import { useEffect } from "react";
import { create } from "zustand";

interface AuthStore {
  isAuth: boolean;
  isDevice: boolean;
  setIsAuth: (value: boolean) => void;
  setIsDevice: (value: boolean) => void;
}

const useAuthStore = create<AuthStore>((set) => ({
  isAuth: false,
  isDevice: true,
  setIsAuth: (value) => set({ isAuth: value }),
  setIsDevice: (value) => set({ isDevice: value }),
}));

export const useAuth = () => {
  const store = useAuthStore();

  useEffect(() => {
    if (window?.parent == null || store.isAuth) return;

    if (
      localStorage.getItem("hospital") != null &&
      localStorage.getItem("access_token") != null &&
      localStorage.getItem("refresh_token") != null
    ) {
      store.setIsAuth(true);
      store.setIsDevice(true);
    }
  }, []);

  return { isAuth: store.isAuth, isDevice: store.isDevice };
};

export default useAuthStore;
