import { ScreenPainScore } from "@/features/PainScore/components/PainScore";
import { NetworkManager } from "@/network-module/NetworkManager";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ScreenAdditionalPain from "../../features/Screen/ScreenAdditionalPain";
import ScreenCureElapse from "../../features/Screen/ScreenCureElapse";
import ScreenFollowUpComplete from "./ScreenFollowUpComplete";
import ScreenSurvey from "./ScreenSurvey";
import GoToFirstModal from "@/features/phase3/components/GoToFirstModal";

type SubmitType = {
  checkUpId: number;
  painScore: string[];
  elapse: {
    id: number;
    title: string;
    question: string;
    answers: { id: number; content: string }[];
  };
  additionalPain: {
    id: number;
    title: string;
    question: string;
    answers: { id: number; content: string }[];
  };
  asks: string;
  relatedCheckUpId: number;
  savedUrl: [];
};
const ScreenReusePage = ({ patientData }: { patientData: any }) => {
  const navigate = useNavigate();
  const [submitPatientData, setSubmitPatientData] = useState<SubmitType>({
    checkUpId: patientData.checkupId,
    painScore: [],
    elapse: {
      id: 0,
      title: "",
      question: "",
      answers: [],
    },
    additionalPain: {
      id: 0,
      title: "",
      question: "",
      answers: [],
    },
    asks: "",
    relatedCheckUpId: patientData.relatedPreClinic,
    savedUrl: [],
  });

  const [step, setStep] = useState(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  if (step == 0) {
    return (
      <>
        <ScreenCureElapse
          backFunc={() => {
            setIsOpen(true);
          }}
          departmentId={patientData.medicalDepartmentId}
          nextStep={(data) => {
            setSubmitPatientData((prev) => {
              return { ...prev, elapse: data };
            });
            setStep(1);
          }}
        />
        <GoToFirstModal
          checkupId={submitPatientData.checkUpId}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      </>
    );
  }

  if (step == 1) {
    return (
      <ScreenPainScore
        title="치료를 받고 달라진 <br />
    {0}를 선택해 주세요."
        highlightArr={["증상 정도"]}
        isShowPrevScore
        backFunc={() => {
          setStep(0);
        }}
        setPainScore={(data: any) => {
          setSubmitPatientData((prev) => {
            return { ...prev, painScore: data };
          });
          setStep(2);
        }}
        preScore={patientData.painScore}
      />
    );
  }

  if (step == 2) {
    return (
      <ScreenAdditionalPain
        backFunc={() => {
          setStep(1);
        }}
        departmentId={patientData.medicalDepartmentId}
        nextStep={(data) => {
          setSubmitPatientData((prev) => {
            return { ...prev, additionalPain: data };
          });
          setStep(3);
        }}
      />
    );
  }

  if (step == 3) {
    return (
      <ScreenSurvey
        backFunc={() => {
          setStep(2);
        }}
        nextStep={(data) => {
          let submitData = submitPatientData;
          submitData.asks = data;
          NetworkManager.checkupService.saveReuse(submitData).then((res) => {
            setStep(4);
          });
        }}
        departmentId={patientData.medicalDepartmentId}
      />
    );
  }

  if (step == 4) {
    return <ScreenFollowUpComplete />;
  }

  navigate("/checkup");
};

export default ScreenReusePage;
