import { SquareButton } from "@/components/common/buttons";
import { HighLight } from "@/components/common/HighLight";
import { Modal } from "@/components/common/modal/Modal";
import { t } from "i18next";

const ReceptionIdentitiyValidPopup = ({
  modalOpen,
  setModalOpen,
}: {
  modalOpen: boolean;
  setModalOpen: (value: boolean) => void;
}) => {
  return (
    <Modal
      modalStyle={{
        width: "70%",
        height: "70%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "56px 52px",
        justifyContent: "space-evenly",
      }}
      isOpen={modalOpen}
      onClose={() => {}}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          fontSize: "32px",
          alignItems: "center",
          lineHeight: "150%",
        }}
      >
        <HighLight text="입력하신 주민등록번호와" textColor="black" />
        <HighLight text="일치하는 정보를 찾을 수 없습니다." />
        <HighLight text="다시 입력해 주세요." />
      </div>
      <SquareButton
        buttonStyle={{}}
        buttonType="confirm"
        label={t("common.confirm")}
        labelStyle={{
          margin: "19.5px 32px",
        }}
        onClick={() => {
          setModalOpen(false);
        }}
      />
    </Modal>
  );
};

export default ReceptionIdentitiyValidPopup;
