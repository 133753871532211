import { MouseEventHandler, ReactNode } from "react";
import { ModalContent, ModalOverlay } from "./ModalStyles";
import { Interpolation } from "@emotion/react";

interface ModalProps {
  isOpen: boolean;
  onClose: MouseEventHandler<HTMLDivElement>;
  children?: ReactNode;
  modalStyle?: Interpolation<any>;
}

export const Modal = ({
  isOpen,
  onClose,
  children,
  modalStyle,
}: ModalProps) => {
  return (
    <ModalOverlay isActive={isOpen} onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()} css={modalStyle}>
        {children}
      </ModalContent>
    </ModalOverlay>
  );
};
