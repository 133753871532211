import SubHeader from "@/components/common/SubHeader";
import { MicrophoneIcon } from "@/theme/CustomIcons";
import { useEffect, useRef, useState } from "react";
import { Button, COLORS, TextArea, Title } from "vd-component";
import { usePhase3Hook1 } from "../store/Phase3Hook1";
import AiBg from "./AiBg";
import { CustomInputModal } from "./CustomInputModal";

interface Props {
  questionTitle: string;
  isShowInput: boolean;
  setIsShowInput: (isShowInput: boolean) => void;
  customInputText: string;
  setCustomInputText: (customInputText: string) => void;
}

export const AIQuestionCustomTextarea = ({
  questionTitle,
  isShowInput,
  setIsShowInput,
  customInputText,
  setCustomInputText,
}: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLDivElement>(null);
  const recordRef = useRef<HTMLDivElement>(null);
  const boxRef = useRef<HTMLDivElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [text, setText] = useState(customInputText || "");
  const [focus, setFocus] = useState<boolean>(true);

  const { isCustomInput, toggleIsCustomInput } = usePhase3Hook1(1);

  const handleConfirm = () => {
    setCustomInputText(text);
    setIsShowInput(false);
  };

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (
        (containerRef.current?.contains(e.target as Node) &&
          !boxRef.current?.contains(e.target as Node)) ||
        titleRef.current?.contains(e.target as Node)
      ) {
        setFocus(false);
      }
    };

    window.addEventListener("click", handleClick);
    return () => window.removeEventListener("click", handleClick);
  }, [setIsShowInput]);

  return (
    <div
      css={{
        display: isShowInput ? "block" : "none",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 2,
      }}
    >
      <AiBg />

      <div
        css={{
          display: "flex",
          flexDirection: "column",
          zIndex: 2,
          height: "100%",
          position: "relative",
        }}
      >
        <SubHeader
          backFunc={() => {
            setIsShowInput(false);
          }}
          mode="dark"
          backText="뒤로가기"
          isShowPatientInfo
          css={{
            padding: "12px 40px",
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            zIndex: 3,
          }}
        />
        <div
          css={{
            padding: focus ? "62px 120px 0px " : "0px 120px",
            position: "absolute",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",

            alignItems: "center",
          }}
          ref={containerRef}
        >
          <div
            css={{
              display: "flex",
              flexDirection: "column",
              gap: "24px",
              width: "100%",
            }}
            ref={boxRef}
          >
            <div
              css={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "60px",
              }}
              ref={titleRef}
            >
              <Title
                size="x-large"
                title={questionTitle}
                css={{ color: COLORS.colorInverseLabelLight }}
              />
              <div css={{ display: "flex", gap: 12 }}>
                <Button
                  variant="tertiary"
                  size="large"
                  css={{
                    gap: "4px",
                    borderRadius: "999px",
                  }}
                  leftIcon={
                    <MicrophoneIcon color={COLORS.colorInverseLabelLight} />
                  }
                  onClick={() => {
                    setFocus(false);
                    toggleIsCustomInput();
                  }}
                >
                  <span>음성 입력</span>
                </Button>
                <Button
                  variant="secondary-line"
                  size="large"
                  css={{
                    display: focus ? "flex" : "none",
                    borderRadius: "999px",
                  }}
                  onClick={handleConfirm}
                  disabled={!text || text.trim() === ""}
                >
                  <span>확인</span>
                </Button>
              </div>
            </div>

            <TextArea
              id="custom-textarea"
              size="x-large"
              variant="dark"
              placeholder="직접 입력해주세요"
              handleClearValue={() => {
                setText("");
              }}
              autoFocus
              onFocus={() => {
                setFocus(true);
              }}
              value={text}
              onChange={(e) => {
                const value = e.target.value;
                if (value === " ") return;
                setText(value);
              }}
              css={{ width: "100%", height: "100px", padding: "16px" }}
              containerStyles={{ width: "100%", height: "100px" }}
              isShowMaxLength={false}
              maxLength={undefined}
              ref={textareaRef}
            />

            <div
              css={{
                display: focus ? "none" : "flex",
                justifyContent: "center",
                marginTop: "12px",
              }}
            >
              <Button
                size="x-large"
                variant="secondary-line"
                css={{ width: "397px" }}
                disabled={!text || text.trim() === ""}
                onClick={handleConfirm}
              >
                확인
              </Button>
            </div>
          </div>
        </div>
        <div>
          <CustomInputModal
            retText={(recordText: string) => {
              setText(recordText);
            }}
            defaultText={""}
            isOpen={isCustomInput}
            toggleIsCustomInput={toggleIsCustomInput}
            ref={recordRef}
          />
        </div>
      </div>
    </div>
  );
};
