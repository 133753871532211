export const useDrag = <Tdata>(identifier: string) => {
  const handleDragStart =
    (item: Tdata) =>
    (e: {
      dataTransfer: { setData: (arg0: string, arg1: string) => void };
    }) => {
      const dragData = { data: item, originListId: identifier };
      e.dataTransfer.setData("application/json", JSON.stringify(dragData));
    };

  const handleDragOver = (e: { preventDefault: () => void }) => {
    e.preventDefault();
  };

  const handleDrop = (e: {
    preventDefault: () => void;
    dataTransfer: { getData: (arg0: string) => string };
  }) => {
    e.preventDefault();
    const transferedData = e.dataTransfer.getData("application/json");
    const item = JSON.parse(transferedData);

    const dropEvent = new CustomEvent("itemDropped", {
      detail: { original: item, targetListId: identifier },
    });
    window.dispatchEvent(dropEvent);
  };

  return { handleDragStart, handleDragOver, handleDrop };
};
