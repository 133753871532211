export type PredicateParams = Record<string, any>;

export type Predicate = (params: PredicateParams) => boolean;

export const and =
  (...predicates: Predicate[]): Predicate =>
  (params) =>
    predicates.every((predicate) => predicate(params));

export const or =
  (...predicates: Predicate[]): Predicate =>
  (params) =>
    predicates.some((predicate) => predicate(params));

export const createCustomCondition =
  (predicate: (params: PredicateParams) => boolean): Predicate =>
  (params) =>
    predicate(params);
