import NewLayout from "@/components/common/NewLayout";
import ReceptionPatientTextLogic from "@/features/Reception/store/ReceptionPatientTextLogic";
import { PatientInfo } from "@/network-module/entities/HospitalEntity";
import { useEffect, useState } from "react";
import {
  Button,
  InputTextField,
  Title,
  Tooltip,
  typography_style_heading_2xl_medium,
} from "vd-component";

const onlyKorean = (text: string, jamo: boolean = false) => {
  if (jamo) {
    return text.replace(/[^ㄱ-힣]/g, "");
  }
  return text.replace(/[^가-힣]/g, "");
};

const ReceptionNameInput = ({
  nextStep,
  backFunc,
  patientData,
}: {
  nextStep: Function;
  backFunc: Function;
  patientData: PatientInfo;
}) => {
  const textLogic = ReceptionPatientTextLogic();
  const [focus, setFocus] = useState<boolean>(false);

  useEffect(() => {
    if (!patientData) return;
    if (patientData.name) {
      textLogic.setText(patientData.name);
    }
  }, [patientData]);

  return (
    <NewLayout
      backFunc={backFunc}
      css={{
        padding: "36px",
        display: "flex",
        flexDirection: "column",
        justifyContent: focus ? "flex-start" : "center",
        alignItems: "center",
        gap: "24px",
      }}
    >
      <div css={{ display: "flex", flexDirection: "column", gap: "24px" }}>
        <Title
          title="{0}을 입력해주세요"
          highlightArr={["이름"]}
          size="2x-large"
          css={{ alignItems: "flex-start" }}
        />

        <div css={{ display: "flex", gap: "20px" }}>
          <Tooltip
            content="이름을 누르면 키보드가 나와요"
            isOpen={!focus && !textLogic.text}
            isHoverAction={false}
            position="bottom-center"
          >
            <InputTextField
              value={textLogic.text}
              handleClearValue={() => {
                textLogic.setText("");
              }}
              placeholder="이름"
              onFocus={() => {
                setFocus(true);
              }}
              onBlur={() => {
                setFocus(false);
              }}
              onChange={(e) => {
                if (e.target.value.length > 8) {
                  return;
                }
                const newVal = onlyKorean(e.target.value, true);
                textLogic.setText(newVal);
              }}
              css={[
                typography_style_heading_2xl_medium,
                {
                  width: "321px",
                  padding: "12px 24px",
                  height: "96px",
                  borderRadius: "12px",
                },
              ]}
            />
          </Tooltip>
          <Button
            size="2x-large"
            css={{ height: "96px", minWidth: "168px" }}
            onClick={() => {
              if (onlyKorean(textLogic.text) != "") {
                nextStep(onlyKorean(textLogic.text));
              }
            }}
          >
            확인
          </Button>
        </div>
      </div>
    </NewLayout>
  );
};

export default ReceptionNameInput;
