import { useQuery } from "@tanstack/react-query";
import { TermRepository } from "../repository/TermRepository";

export class TermService {
  repo: TermRepository;
  constructor(repo: TermRepository) {
    this.repo = repo;
  }

  // 약관 리스트
  getTermSummaryList(hospitalId: number | string) {
    return useQuery({
      queryKey: ["getTermSummaryList"],
      initialData: [],
      queryFn: async ({ signal }) => {
        return this.repo.getTermSummaryList(hospitalId);
      },
    });
  }

  // 약관 상세
  getTermSummaryDetail(termId: number, isModalOpen: boolean) {
    return useQuery({
      queryKey: ["getTermSummaryDetail", termId],
      queryFn: async ({ signal }) => {
        return this.repo.getTermSummaryDetail(termId, signal);
      },
      enabled: !!isModalOpen,
    });
  }
}
