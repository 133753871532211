import NewLayout from "@/components/common/NewLayout";
import { Interpolation, Theme } from "@emotion/react";
import { useState } from "react";
import {
  Button,
  IconBadge,
  Title,
  typography_style_heading_3xl,
} from "vd-component";
import PainSlider from "./PainSlider";

interface PainScoreProps {
  setPainScore: Function;
  backFunc: Function;
  preScore?: number;
  isShowPrevScore?: boolean;
  layoutStyle?: Interpolation<Theme>;
  title: string;
  highlightArr?: string[];
}
export const ScreenPainScore = ({
  setPainScore,
  backFunc,
  preScore,
  isShowPrevScore = false,
  layoutStyle,
  title,
  highlightArr = [],
}: PainScoreProps) => {
  const [painValue, setPainValue] = useState(0);
  const clampedValue = Math.max(0, Math.min(11, Math.round(preScore)));

  const onSelect = (score: number) => {
    let scoreData = [`${score}`];
    setPainScore(scoreData);
  };

  return (
    <NewLayout
      backFunc={backFunc}
      isShowPatientInfo={true}
      css={[
        {
          padding: "36px 80px",
          flexDirection: "column",
          gap: "32px",
          justifyContent: "normal",
        },
        layoutStyle,
      ]}
    >
      <div
        css={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Title size="2x-large" title={title} highlightArr={highlightArr} />

        <div css={{ display: "flex", alignItems: "center", gap: "24px" }}>
          {isShowPrevScore && (
            <IconBadge size="x-large" badgeType="opacity-bg" variant="gray">
              이전 증상 점수 {clampedValue}점
            </IconBadge>
          )}
          <span css={[typography_style_heading_3xl]}>{painValue}점</span>
        </div>
      </div>
      <PainSlider painValue={painValue} setPainValue={setPainValue} />
      <div css={{ display: "flex", justifyContent: "center" }}>
        <Button
          size="x-large"
          css={{ width: "397px" }}
          onClick={() => onSelect?.(painValue)}
        >
          확인
        </Button>
      </div>
    </NewLayout>
  );
};
