import AppSetting from "@/common/config/AppSetting";
import StorageManager from "@/common/services/StorageManager";
import { AxiosInstance, AxiosResponse } from "axios";
import { KioskFirstEntity } from "./entities/KioskFirstEntity";

class ErrorRegister {
  private statusCodeRegister = new Map<
    number,
    (result: AxiosResponse) => any
  >();

  private customErrorRegister = new Map<
    number,
    (result: AxiosResponse) => any
  >();

  private api;

  constructor(api: AxiosInstance) {
    this.api = api;
    this.initStatusCodeHandle();
    this.initCustomErrorHandle();
  }

  initStatusCodeHandle() {
    this.setStatusCodeHandle(400, (error: any) => {
      const handler = this.getCustomErrorHandle(error.response.data.code);
      if (handler == undefined) throw error.response.data;
      return handler(error);
    });
    this.setStatusCodeHandle(401, (error: any) => {
      return this.api
        .post(
          "/auth/kiosk/refresh",
          {
            deviceId: AppSetting.getDeviceUniqueKey(),
          },
          { withCredentials: true }
        )
        .then((res) => {
          const { accessToken, refreshToken } = res.data;
          if (accessToken == null) return res;
          StorageManager.setToken(accessToken, refreshToken);
          error.config.headers["Authorization"] = `Bearer ${accessToken}`;

          return res;
        })
        .catch((e) => {
          console.log(e);
        });
    });
    this.setStatusCodeHandle(403, () => {
      return [];
    });
    this.setStatusCodeHandle(500, (error: any) => {
      throw error;
    });
  }

  setStatusCodeHandle(statusCode: number, statusErrorHandle: any) {
    this.statusCodeRegister.set(statusCode, statusErrorHandle);
  }

  getStatusCodeHandle(statusCode: number) {
    const handler = this.statusCodeRegister.get(statusCode);
    if (handler === undefined) {
      return (error: any) => {
        throw error;
      };
    }
    return handler;
  }

  initCustomErrorHandle() {
    this.customErrorRegister.set(400001, () => {});
    this.customErrorRegister.set(400013, async (error) => {
      try {
        const signIn: KioskFirstEntity = await this.api
          .post("/auth/kiosk/signin/first", {
            deviceId: AppSetting.getDeviceUniqueKey(),
          })
          .then((res) => {
            return res.data;
          });

        StorageManager.setData("kioskId", signIn.kioskId);
        StorageManager.setData("hospitalId", `${signIn.hospitalId}`);
        StorageManager.setToken(signIn.accessToken, signIn.refreshToken);
        StorageManager.setData("hospital", signIn.hospital_name);
        throw { signIned: true };
      } catch (e) {
        throw { signIned: false };
      }
    });
  }

  setCustomErrorHandle(errorCode: number, customErrorHandle: any) {
    this.customErrorRegister.set(errorCode, () => {
      customErrorHandle();
    });
  }

  getCustomErrorHandle(errorCode: number) {
    return this.customErrorRegister.get(errorCode);
  }
}

export default ErrorRegister;
