import { CSSInterpolation } from "@emotion/css/create-instance";
import { HTMLAttributes } from "react";
import { COLORS } from "vd-component";
import SubHeader from "./SubHeader";

interface NewLayoutProps extends HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  containerStyle?: CSSInterpolation;
  backFunc: Function;
  isShowPatientInfo?: boolean;
  isShowHospital?: boolean;
  backText?: string;
}

const NewLayout = ({
  children,
  backFunc,
  containerStyle,
  isShowPatientInfo,
  isShowHospital,
  backText,
  ...props
}: NewLayoutProps) => {
  return (
    <div
      css={{
        width: "100%",
        minHeight: "fit-content",
        height: "100%",
        display: "flex",
        gap: "24px",
        flexDirection: "column",
        padding: "0 40px 40px",
        background: COLORS.colorBackgroundLight,
        containerStyle,
      }}
    >
      <SubHeader
        backFunc={backFunc}
        isShowPatientInfo={isShowPatientInfo}
        backText={backText}
      />
      <div
        css={{
          display: "flex",
          height: "100%",
          padding: "48px 58px",
          gap: "141px",
          background: COLORS.colorBackgroundWhite,
          borderRadius: "12px",
          justifyContent: "space-between",
          boxShadow:
            "0 0 2px 0 rgba(0, 0, 0, 0.08), 0 2px 4px 0 rgba(0, 0, 0, 0.08), 0 2px 8px 0 rgba(0, 0, 0, 0.12)",
        }}
        {...props}
      >
        {children}
      </div>
    </div>
  );
};

export default NewLayout;
