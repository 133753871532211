// 손바닥 왼쪽
const hb_in_L = ["R-t-50", "R-t-51", "R-t-53", "R-t-52", "R-t-14", "R-t-15"];
// 손바닥 오른쪽
const hb_in_R = ["L-t-50", "L-t-51", "L-t-53", "L-t-52", "L-t-14", "L-t-15"];

// 손등 왼쪽
const hf_out_L = ["R-t-50", "bL-t-54", "R-t-53", "bL-t-18", "bL-t-17"];
// 손등 오른쪽
const hf_out_R = ["L-t-50", "bR-t-54", "L-t-53", "bR-t-18", "bR-t-17"];

// 손 전체 왼쪽
const hand_L = [...hb_in_L, ...hf_out_L];
// 손 전체 오른쪽
const hand_R = [...hb_in_R, ...hf_out_R];

export { hand_L, hand_R, hb_in_L, hb_in_R, hf_out_L, hf_out_R };
