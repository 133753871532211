import {
  EventHandler,
  useD3EventListeners,
} from "@/features/humanBodyDiagram/hooks/useD3EventListeners";
import {
  SvgSelection,
  type GSelection,
} from "@/features/humanBodyDiagram/stores/humanBodySvgSlice";
import {
  useSvgCacheStore,
  type SvgCacheState,
} from "@/features/humanBodyDiagram/stores/humanBodySvgSrcStore";
import * as d3 from "d3";
import { useEffect, useRef } from "react";

interface D3SvgEventListeners {
  onClick?: EventHandler;
  onMousemove?: EventHandler;
  onMouseover?: EventHandler;
  onMouseout?: EventHandler;
}

interface Props extends D3SvgEventListeners {
  src: string;
  onLoadSvg: ({
    svgSelection,
    gSelection,
  }: {
    svgSelection: SvgSelection;
    gSelection: GSelection;
  }) => void;
  onInitSvg?: () => void;
}

export const D3Svg = ({
  src,
  onLoadSvg,
  onInitSvg,
  onClick,
  onMousemove,
  onMouseover,
  onMouseout,
}: Props) => {
  const { getSvgCache, setSvgCache } = useSvgCacheStore();
  const svgRef = useRef<SVGSVGElement | null>(null);

  useD3EventListeners({
    click: onClick,
    mousemove: onMousemove,
    mouseover: onMouseover,
    mouseout: onMouseout,
  });

  useEffect(() => {
    const loadSvg = async () => {
      if (!svgRef.current) return;
      const svgSelection = d3.select(svgRef.current);

      try {
        onInitSvg?.();
        const importedNode = await svgSrcToNode(src, setSvgCache, getSvgCache);
        const gSelection = createGSelection({
          svgSelection,
          importedNode,
        });

        onLoadSvg?.({ svgSelection, gSelection });
      } catch (err) {
        console.error("Error loading SVG", err);
      }
    };

    loadSvg();

    return () => {
      d3.select(svgRef.current).selectAll("*").remove();
    };
  }, [src]);

  return <svg ref={svgRef} css={{ width: "100%", height: "100%" }} />;
};

const svgSrcToNode = async (
  src: string,
  setSvgCache: SvgCacheState["setSvgCache"],
  getSvgCache: SvgCacheState["getSvgCache"],
) => {
  const cachedSvg = getSvgCache(src);
  if (cachedSvg) {
    const parser = new DOMParser(); // 문자열을 DOM 노드로 변환
    const doc = parser.parseFromString(cachedSvg, "image/svg+xml"); // 문자열을 SVG 노드로 변환
    return document.importNode(doc.documentElement, true); // SVG 노드를 DOM 노드로 복사
  }

  const data = await d3.xml(src); // 인체부위 결정
  const importedNode = document.importNode(data.documentElement, true); // 인체부위 Svg를 DOM 노드로 복사
  setSvgCache(src, new XMLSerializer().serializeToString(importedNode)); // 복사된 노드를 문자열로 변환하여 캐시에 저장
  return importedNode;
};

const createGSelection = ({
  svgSelection,
  importedNode,
}: {
  svgSelection: SvgSelection;
  importedNode: HTMLElement;
}) => {
  // svg 속성 설정 (메서드 체인으로 속성 추가 가능)
  svgSelection
    .style("transform", "translateZ(0)") // CSS 하드웨어 가속 활성화
    .style("backface-visibility", "hidden") // CSS 하드웨어 가속 활성화
    .style("perspective", "1000px") // 3D 효과를 더 부드럽게
    .style("will-change", "transform") // 브라우저에게 변환 최적화 힌트 제공
    .attr("viewBox", importedNode.getAttribute("viewBox"));

  // NOTE: g는 인체부위를 포함한 svg 요소들을 그룹화하는 역할로, 그룹화를 통해 줌, 드래그와 같은 기능이 g요소를 통해 제어될 때 모든 svg가 함께 동작함
  //! NOTE: gSelection은 d3.xml 호출 이후에 생성되어야 이후 프로세스가 올바르게 동작함
  const gSelection = svgSelection.append("g");
  gSelection.node()?.appendChild(importedNode);

  return gSelection;
};
