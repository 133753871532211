import {
  usePatientDataStore,
  usePatientStore,
} from "@/features/Screen/store/ScreenStore";
import { Paper02Icon } from "@/theme/CustomIcons";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Title } from "vd-component";

const ScreenCompleteWait = () => {
  const navigate = useNavigate();
  const patientAreaStore = usePatientStore();
  const patientDataStore = usePatientDataStore();
  useEffect(() => {
    setTimeout(() => {
      patientAreaStore.resetData();
      patientDataStore.resetData();
      navigate("/");
    }, 3000);
  }, []);

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        gap: "24px",
      }}
    >
      <Paper02Icon css={{ width: "148px", height: "148px" }} />
      <Title
        size="2x-large"
        title="사전문진이 완료 되었습니다!<br />{0}로 안내해 드리겠습니다."
        highlightArr={["곧 진료실"]}
        css={{ textAlign: "center" }}
      />
    </div>
  );
};

export default ScreenCompleteWait;
