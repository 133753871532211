import { useStomp } from "@/network-module/context/StompContext";
import { useEffect, useReducer, useState } from "react";

import "regenerator-runtime";
import "regenerator-runtime/runtime";

export function usePhase3Hook1(checkupId: number) {
  const [isFirst, setIsFirst] = useState(true);
  const [isCustomInput, toggleIsCustomInput] = useReducer(
    (state) => !state,
    false
  );

  const { websocket } = useStomp();

  useEffect(() => {
    if (isFirst) {
      setIsFirst(false);
    }
  }, []);

  const onRelisten = () => {
    const customEvent = new CustomEvent("restart", {
      detail: {
        message: "retart listenling",
      },
    });

    document.dispatchEvent(customEvent);
  };

  return {
    isCustomInput,
    toggleIsCustomInput,
    websocket,
    onRelisten,
  };
}
