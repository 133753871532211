import { DepartmentEnum } from "@/features/humanBodyDiagram/entities/departmentMappings";
import { usePatientStore } from "@/features/Screen/store/ScreenStore";
import { useEffect } from "react";
import "regenerator-runtime";
import "regenerator-runtime/runtime";
import ScreenSelectBodyNew from "../screening/ScreenSelectBodyNew";

const Test = () => {
  const patientStore = usePatientStore();
  useEffect(() => {
    patientStore.setName("홍길동");
    patientStore.setBirth("001005");
    patientStore.setGender("1");
  }, []);
  // return (
  //   <ScreenDetail
  //     backFunc={() => {}}
  //     departmentId={2}
  //     setDetailQuestion={(data: any) => {
  //       console.log(data);
  //     }}
  //   />
  // );
  return (
    <ScreenSelectBodyNew
      backFunc={() => {}}
      department={DepartmentEnum.INTERNAL}
      setBodyData={() => {}}
    />
  );
  // return <ReceptionQRPage />;
};

export default Test;
