export function getGenderBoolean(idGender: number) {
  // 1, 3 남자-false / 2, 4 여자-true
  return !(idGender % 2);
}

export function getDateFormatByIdentityBirth(
  birth: string,
  identitiyGender: number
) {
  let year = birth.slice(0, 2);
  let month = birth.slice(2, 4);
  let date = birth.slice(4, 6);

  let yearPrefix = "";
  if ([1, 2].includes(identitiyGender)) {
    yearPrefix = "19";
  } else if ([3, 4].includes(identitiyGender)) {
    yearPrefix = "20";
  }

  return `${yearPrefix}${year}년 ${month}월 ${date}일`;
}
