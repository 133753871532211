import ScheduleOn from "@/assets/icons/schedule_on.svg";

import UsersOn from "@/assets/icons/users_on.svg";
import { DoctorWait } from "@/network-module/entities/DoctorEntity";
import {
  COLORS,
  IconBadge,
  typography_style_body_lg_regular,
  typography_style_heading_md_semilbold,
} from "vd-component";

interface DoctorCardProps {
  doctor: DoctorWait;
  setDoctorId: Function;
  setDoctorIndex: Function;
  idx: number;
  doctorId: number;
}
const DoctorCard = ({
  doctor,
  setDoctorId,
  setDoctorIndex,
  idx,
  doctorId,
}: DoctorCardProps) => {
  const isSelected = doctorId === doctor.id;
  return (
    <div
      css={{
        padding: "16px 20px",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        height: "200px",
        border: isSelected ? "2px solid" : "1px solid",
        borderColor: isSelected
          ? COLORS.colorInversePrimaryNomal
          : COLORS.colorLineLight,
        borderRadius: "12px",
        opacity: doctor.clinicClosure ? 0.6 : 1,
        boxShadow: isSelected
          ? "0 0 8px rgba(0, 0, 0, 0.08), 0 8px 16px rgba(0, 0, 0, 0.08), 0 16px 20px rgba(0, 0, 0, 0.12)"
          : "none",
      }}
      onClick={() => {
        if (doctor.clinicClosure) return false;
        setDoctorId(doctor.id);
        setDoctorIndex(idx);
      }}
    >
      {/* 진료상태 & 의사명 */}
      <div css={{ display: "flex", flexDirection: "column", gap: "12px" }}>
        <div
          css={{
            display: "flex",
            gap: "12px",
            alignItems: "center",
          }}
        >
          <IconBadge
            size="x-large"
            badgeType="filled-bg"
            variant="primary"
            css={{
              width: "fit-content",
              background: doctor.clinicClosure
                ? COLORS.colorBackgroundNomal
                : COLORS.colorPrimaryNomal,
              color: doctor.clinicClosure
                ? COLORS.colorLabelLight
                : COLORS.colorInverseLabelLight,
            }}
          >
            {doctor.clinicClosure ? "휴진" : "진료중"}
          </IconBadge>
          <IconBadge
            size="x-large"
            badgeType="opacity-bg"
            variant="primary"
            css={{ width: "fit-content" }}
          >
            {/* TODO:  과 여러개일때 수정 */}
            {doctor.departments[0].name}
          </IconBadge>
        </div>

        <span css={[typography_style_heading_md_semilbold]}>
          {doctor.name} 의사
        </span>
      </div>

      {/* 대기인원 & 대기 시간 */}
      <div css={{ display: "flex", flexDirection: "column" }}>
        <div
          css={{
            display: "flex",
            height: "36px",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div css={{ display: "flex", alignItems: "center", gap: "4px" }}>
            <img src={UsersOn} alt="Icon" />
            <span css={[typography_style_body_lg_regular]}>대기 인원</span>
          </div>
          <span css={[typography_style_body_lg_regular]}>
            {doctor.waitCount}명
          </span>
        </div>
        <div
          css={{
            display: "flex",
            height: "36px",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div css={{ display: "flex", alignItems: "center", gap: "4px" }}>
            <img src={ScheduleOn} alt="Icon" />
            <span css={[typography_style_body_lg_regular]}>대기 시간</span>
          </div>
          <span css={[typography_style_body_lg_regular]}>
            {doctor.waitCount * 6}분
          </span>
        </div>
      </div>
    </div>
  );
};

export default DoctorCard;
