import StorageManager from "@/common/services/StorageManager";

import {
  usePatientDataStore,
  usePatientStore,
} from "@/features/Screen/store/ScreenStore";
import useNetwork from "@/hook/useNetwork";
import { HospitalIcon } from "@/theme/CustomIcons";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { Button, Title } from "vd-component";
import KioskLeft from "./KioskLeft";

export enum IndexType {
  Checkup,
  Reception,
}

const KioskIndex = () => {
  const { isOffline } = useNetwork();
  const hospitalName = StorageManager.getDataNow("hospital");
  const navigate = useNavigate();

  const patientStore = usePatientStore();
  const patientDataStore = usePatientDataStore();

  const handleStartReception = () => {
    patientStore.resetData();
    patientDataStore.resetData();
    navigate("/reception");
  };

  return (
    <main style={{ display: "flex", height: "100%" }}>
      <KioskLeft />
      <div
        style={{
          flex: 7,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            minWidth: "384px",
          }}
        >
          <HospitalIcon css={{ marginBottom: "12px" }} />

          <Title
            title={`${t("reception.index.title1")}${t(
              "common.br",
            )}${hospitalName}${t("reception.index.title2")}`}
            size="2x-large"
            description={t("reception.index.touchGuide")}
            style={{ alignItems: "flex-start", marginBottom: "56px" }}
          />
          <Button
            buttonType="common"
            size="x-large"
            style={{ width: "100%" }}
            disabled={isOffline}
            onClick={handleStartReception}
          >
            {isOffline
              ? "인터넷 연결 확인"
              : `${t("reception.index.touchText")}`}
          </Button>
        </div>
      </div>
    </main>
  );
};

export default KioskIndex;
