import ReceptionAppCheckin from "@/features/Reception/components/ReceptionAppCheckin";
import {
  CheckReciptResponseEntity,
  PatientInfo,
} from "@/network-module/entities/HospitalEntity";
import { NetworkManager } from "@/network-module/NetworkManager";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import DuplicateCopyModal from "./DuplicateCopyModal";
import { HospitalData } from "./ReceptionPage";

type ReceptionType0 = {
  reservationId: any;
  phoneNumber: string;
  name: string;
  birth: string;
  identityGender: number | string;
  relatedPreClinicId: null;
  selectedDoctorId: number | null;
  medicalDepartmentId: number | null;
  identityNumber: string;
};

//당일 접수 케이스
const ReceptionStep2Type0 = ({
  patientData,
  receiptData,
  hospitalData,
}: {
  patientData: PatientInfo;
  receiptData: CheckReciptResponseEntity;
  hospitalData: HospitalData;
}) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const [step, setStep] = useState(0);

  const submitPatient = {
    reservationId: receiptData.reserveData?.id,
    phoneNumber: patientData.phoneNumber.join(""),
    name: patientData.name,
    birth: patientData.identityNumber.slice(0, 6).join(""),
    identityGender: patientData.identityNumber[6],
    relatedPreClinicId: null,
    selectedDoctorId: hospitalData.selectedDoctorId,
    medicalDepartmentId: hospitalData.medicalDepartmentId,
    identityNumber: patientData.identityNumber.slice(7, 13).join(""),
  };

  const submit = () => {
    NetworkManager.hospitalService
      .kioskCheckIn(submitPatient)
      .then(() => {
        setStep(3);
      })
      .catch((e) => {
        if (e.code === 400015) {
          setIsOpen(true);
        }
      });
  };

  if (step == 0) {
    return (
      <>
        <ReceptionAppCheckin
          nextStep={() => {
            submit();
            navigate("/");
          }}
          patientName={patientData.name}
        />
        <DuplicateCopyModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
      </>
    );
  }

  navigate("/reception");
};
export default ReceptionStep2Type0;
