import { useState } from "react";

const ReceptionPatientNumberLogic = (length: number) => {
  const [numLength, setLength] = useState<number>(length);
  const [number, setNumber] = useState<string[]>(Array(numLength).fill("_"));
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const initLength = (inputLength: number) => {
    setLength(inputLength);
    setNumber(Array(inputLength).fill("_"));
    setCurrentIndex(0);
  };

  const setLogicNumber = (numArray: Array<string>) => {
    setNumber(numArray);
    setCurrentIndex(numArray.length);
  };

  const inputNumber = (num: string) => {
    if (currentIndex > numLength - 1) {
      return;
    }
    let newInputs = [...number];
    newInputs[currentIndex] = `${num}`;
    setCurrentIndex(currentIndex + 1);
    setNumber(newInputs);
  };

  const removeNumber = () => {
    let index = currentIndex;
    if (index < 0) {
      return;
    }
    let newInputs = [...number];
    newInputs[index - 1] = "_";
    if (index > 0) {
      setCurrentIndex(index - 1);
    }
    setNumber(newInputs);
  };

  const resetNumber = () => {
    setNumber(Array(numLength).fill("_"));
    setCurrentIndex(0);
  };

  return {
    number,
    inputNumber,
    removeNumber,
    resetNumber,
    initLength,
    setLogicNumber,
  };
};

export default ReceptionPatientNumberLogic;
