import {
  CheckReceiptEntity,
  CheckReciptResponseEntity,
  PatientInfo,
} from "@/network-module/entities/HospitalEntity";
import { NetworkManager } from "@/network-module/NetworkManager";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { ReceiptSearchResponse } from "@/network-module/services/HospitalService";

import ReceptionSelectDoctor from "@/features/Reception/components/ReceptionSelectDoctor";
import { usePatientDataStore } from "@/features/Screen/store/ScreenStore";
import ReceptionNameInput from "./ReceptionNameInput";
import PatientSelectList from "./ReceptionPatientSelectList/PatientSelectList";
import ReceptionSearchPatient from "./ReceptionSearchPatient";
import ReceptionStep2Type0 from "./ReceptionStep2Type0";
import ReceptionStep2Type1 from "./ReceptionStep2Type1";
import ReceptionStep2Type2 from "./ReceptionStep2Type2";

export interface HospitalData {
  selectedDoctorId: number | null;
  medicalDepartmentId: number | null;
  doctorName: string;
}

const ReceptionPage = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);

  const [patientList, setPatientList] = useState<ReceiptSearchResponse[]>([]);

  const [receiptData, setReceiptData] = useState<CheckReciptResponseEntity>({
    hospitalPatientId: null,
    reserveData: null,
    type: null,
    alreadyCheckingPreClinicRecordId: null,
  });
  const [patientData, setPatientData] = useState<PatientInfo>({
    name: "",
    identityNumber: [],
    phoneNumber: [],
  });
  const [hospitalData, setHospitalData] = useState<HospitalData>({
    selectedDoctorId: null,
    medicalDepartmentId: null,
    doctorName: "",
  });

  const makeReciptForm = (patientInfo): CheckReceiptEntity => {
    return {
      birth: patientInfo.birth,
      phoneNumber: patientInfo.phoneNumber,
      identityGender: patientInfo.identityGender,
      name: patientInfo.name,
    };
  };

  if (step == 0) {
    return (
      <ReceptionSelectDoctor
        backFunc={() => {
          navigate("/");
        }}
        nextStep={(id: number, name: string, departmentId: number) => {
          setHospitalData((prev) => {
            return {
              ...prev,
              selectedDoctorId: id,
              medicalDepartmentId: departmentId,
              doctorName: name,
            };
          });

          setStep(1);
        }}
      />
    );
  }

  if (step == 1) {
    return (
      <ReceptionSearchPatient
        backFunc={() => {
          setStep((prev) => prev - 1);
        }}
        patientData={patientData}
        defaultName={patientData.name}
        nextStep={async (
          data: CheckReciptResponseEntity,
          patientInfo: PatientInfo,
        ) => {
          const existPatient =
            await NetworkManager.hospitalService.getReceiptSearch(
              patientInfo.phoneNumber.join(""),
            );

          if (existPatient?.length > 0) {
            setPatientList(existPatient);
            setStep(5);

            return;
          }

          setReceiptData(data);
          setPatientData((prev) => {
            return {
              ...prev,
              phoneNumber: patientInfo.phoneNumber,
            };
          });
          setStep(2);
        }}
      />
    );
  }

  if (step == 2) {
    return (
      <ReceptionNameInput
        backFunc={() => {
          return patientList?.length > 0 ? setStep(5) : setStep(1);
        }}
        patientData={patientData}
        nextStep={(name: string) => {
          setPatientData((prev) => {
            return { ...prev, name: name };
          });
          setStep(3);
        }}
      />
    );
  }
  if (step === 3) {
    return (
      <ReceptionSearchPatient
        defaultPhase={1}
        backFunc={() => {
          setStep((prev) => prev - 1);
        }}
        patientData={patientData}
        defaultName={patientData.name}
        nextStep={(
          data: CheckReciptResponseEntity,
          patientInfo: PatientInfo,
        ) => {
          setReceiptData(data);
          setPatientData((prev) => {
            return {
              ...prev,
              identityNumber: patientInfo.identityNumber,
            };
          });
          setStep(4);
        }}
      />
    );
  }

  if (step === 4) {
    if (receiptData.reserveData != null) {
      //당일 접수 케이스
      return (
        <ReceptionStep2Type0
          patientData={patientData}
          receiptData={receiptData}
          hospitalData={hospitalData}
        />
      );
    }

    if (receiptData.hospitalPatientId != null) {
      //재방문 케이스
      return (
        <ReceptionStep2Type1
          patientData={patientData}
          receiptData={receiptData}
          setParentStep={setStep}
          hospitalData={hospitalData}
        />
      );
    }

    //신규 등록
    return (
      <ReceptionStep2Type2
        patientData={patientData}
        receiptData={receiptData}
        setParentStep={setStep}
        hospitalData={hospitalData}
      />
    );
  }

  if (step === 5) {
    return (
      <PatientSelectList
        nextStep={async (patient) => {
          usePatientDataStore.setState(patient);
          const identityNumber = `${patient.birth}${patient.identityGender}`;

          const receiptTypeRes =
            await NetworkManager.hospitalService.checkReceiptType(
              makeReciptForm(patient),
            );

          setReceiptData(receiptTypeRes as CheckReciptResponseEntity);
          setPatientData({
            name: patient.name,
            identityNumber: identityNumber.split(""),
            phoneNumber: patient.phoneNumber?.split(""),
          });
          setStep(4);
        }}
        setStep={setStep}
        patientList={patientList}
        backFunc={async () => {
          setPatientList([]);
          setStep(1);
        }}
      />
    );
  }
};

export default ReceptionPage;
