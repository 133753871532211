import { D3Svg } from "@/features/humanBodyDiagram/components/HumanBodySvg/D3Svg";
import { useHumanBodyStore } from "@/features/humanBodyDiagram/context/useHumanBodyStore";
import { PAIN_POINT_CLASS_NAME } from "@/features/humanBodyDiagram/entities/constants";
import type { DepartmentEnum } from "@/features/humanBodyDiagram/entities/departmentMappings";
import {
  body_foot_L,
  body_foot_R,
  body_hand_L,
  body_hand_R,
} from "@/features/humanBodyDiagram/entities/painAreaIds/bodyIds";
import { svgMappings } from "@/features/humanBodyDiagram/entities/svgMappings";
import { useHumanBodyPains } from "@/features/humanBodyDiagram/hooks/useHumanBodyPains";
import { useZoomSetup } from "@/features/humanBodyDiagram/hooks/useZoomSetup";
import { findMatchMapping } from "@/features/humanBodyDiagram/utils/mappingFactory";
import styled from "@emotion/styled";
import { useEffect, useState } from "react";

interface Props {
  department: DepartmentEnum;
  painAreas: string[];
}

export const HumanBodySvg = ({ department, painAreas }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  useHumanBodyPains({ painAreas });
  useZoomSetup();

  const {
    mainCategory,
    subCategory,
    direction,

    handleChangeMainCategory,
    setDirection,

    setSvgSelection,
    setGSelection,
    clearZoom,
  } = useHumanBodyStore((store) => ({
    mainCategory: store.mainCategory,
    subCategory: store.subCategory,
    direction: store.direction,

    handleChangeMainCategory: store.handleChangeMainCategory,
    setDirection: store.setDirection,

    setSvgSelection: store.setSvgSelection,
    setGSelection: store.setGSelection,
    clearZoom: store.clearZoom,
  }));

  const bodyPartSrc = findMatchMapping(svgMappings, {
    mainCategory,
    subCategory,
    direction,
    department,
  });

  useEffect(() => {
    if (!bodyPartSrc) return;
    clearZoom();
  }, [bodyPartSrc]);

  const mapPainPointIds = (ids: string[]) =>
    ids.map((id) => `${id}_${PAIN_POINT_CLASS_NAME}`);

  const bodyClickActionMapping = [
    {
      target: mapPainPointIds(body_hand_L),
      action: () => {
        handleChangeMainCategory("hand");
        setDirection("left");
      },
    },
    {
      target: mapPainPointIds(body_hand_R),
      action: () => {
        handleChangeMainCategory("hand");
        setDirection("right");
      },
    },
    {
      target: mapPainPointIds(body_foot_L),
      action: () => {
        handleChangeMainCategory("foot");
        setDirection("left");
      },
    },
    {
      target: mapPainPointIds(body_foot_R),
      action: () => {
        handleChangeMainCategory("foot");
        setDirection("right");
      },
    },
  ];

  return (
    <>
      {/* {isLoading && (
        <LoadingContainer>
          <LoadingSpinner color={"var(--color-background-heavy)"} />
        </LoadingContainer>
      )} */}
      {bodyPartSrc && (
        <D3Svg
          src={bodyPartSrc}
          onClick={(event) => {
            const target = event.target as SVGElement;
            const elementId = target.id;

            bodyClickActionMapping.forEach(({ target, action }) => {
              if (target.includes(elementId)) {
                action();
              }
            });
          }}
          onInitSvg={() => setIsLoading(true)}
          onLoadSvg={({ svgSelection, gSelection }) => {
            setSvgSelection(svgSelection);
            setGSelection(gSelection);
            setIsLoading(false);
          }}
        />
      )}
    </>
  );
};

const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
