import {
  BodyMainCategoryEnum,
  SubCategoryEnum,
} from "@/features/humanBodyDiagram/entities/categories";
import {
  createMainCategoryCondition,
  createSubCategoryCondition,
} from "@/features/humanBodyDiagram/helper/conditionCreators";
import { Direction } from "@/features/humanBodyDiagram/stores/humanBodyPartsSlice";
import { and } from "@/features/humanBodyDiagram/utils/conditionUtils";
import {
  type Mapping,
  mappingFactory,
} from "@/features/humanBodyDiagram/utils/mappingFactory";

interface DirectionLabel {
  leftLabel: Direction;
  rightLabel: Direction;
}

/**
 * 왼쪽, 오른쪽 라벨 표시 매핑
 * */
export const directionLabelMappings: Mapping<DirectionLabel>[] = [
  mappingFactory(
    and(
      createMainCategoryCondition(BodyMainCategoryEnum.BODY),
      createSubCategoryCondition(SubCategoryEnum.FRONT)
    ),
    {
      leftLabel: "right",
      rightLabel: "left",
    }
  ),
  mappingFactory(
    and(
      createMainCategoryCondition(BodyMainCategoryEnum.BODY),
      createSubCategoryCondition(SubCategoryEnum.BACK)
    ),
    {
      leftLabel: "left",
      rightLabel: "right",
    }
  ),

  // ... 다른 매핑 추가
];
