import { InputType } from "@/common/utils/Type";
import RemoveIcon from "@assets/icons/remove.svg";
import {
  COLORS,
  typography_style_heading_3xl,
  typography_style_heading_sm_semibold,
} from "vd-component";
import { SquareButton } from "./buttons";

const inputButtonStyle = {
  width: "30%",
  height: "84px",

  background: COLORS.colorBackgroundWhite,
  color: COLORS.colorLabelNomal,
  boxShadow:
    "0 0 4px 0 rgba(0, 0, 0, 0.08), 0 0 4px 0 rgba(0, 0, 0, 0.10), 0 0 16px 0 rgba(0, 0, 0, 0.10)",
  padding: "16px 0",
  ":active": { background: COLORS.colorBackgroundLight },
  aspectRatio: "0.5 / 1",
  borderRadius: "8px",
};

const Keypad10Num = ({
  numberLogic,
  type = InputType.identityNumber,
  keypadRef,
}: {
  numberLogic: {
    number: string[];
    inputNumber: Function;
    removeNumber: Function;
    resetNumber: Function;
    initLength: Function;
    setLogicNumber: Function;
  };
  type?: InputType;
  keypadRef: React.RefObject<HTMLDivElement>;
}) => {
  const inputButtonLabel = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  const isBodySize = type === InputType.bodySize;
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: "12px",
        justifyContent: "space-between",
      }}
      ref={keypadRef}
    >
      {inputButtonLabel.map((v, k) => {
        return (
          <SquareButton
            label={`${v}`}
            css={[typography_style_heading_3xl, { ...inputButtonStyle }]}
            buttonType={"cancel"}
            key={k}
            onClick={() => {
              numberLogic.inputNumber(v);
            }}
          />
        );
      })}
      <SquareButton
        onClick={() => {
          if (isBodySize) {
            return;
          }
          numberLogic.resetNumber();
        }}
        label={isBodySize ? "." : "전체지움"}
        css={[
          typography_style_heading_sm_semibold,
          {
            ...inputButtonStyle,
            background: COLORS.colorBackgroundWhite,
          },
        ]}
        buttonType={"cancel"}
      />
      <SquareButton
        onClick={() => {
          numberLogic.inputNumber(0);
        }}
        label="0"
        css={[typography_style_heading_3xl, { ...inputButtonStyle }]}
        buttonType={"cancel"}
      />
      <div
        css={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          ...inputButtonStyle,
        }}
        onClick={() => {
          numberLogic.removeNumber();
        }}
      >
        <img src={RemoveIcon} css={{ width: "36px", height: "40px" }} />
      </div>
    </div>
  );
};

export default Keypad10Num;
