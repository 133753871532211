import styled from "@emotion/styled";
import { CSSProperties } from "react";
import Button, { ButtonProps } from "./Button";

type ButtonVariant = "cancel" | "confirm" | "free" | "selectConfirm";

export const ActionButton = ({ ...props }: ButtonProps) => {
  props.buttonStyle = {
    borderRadius: "4px",
    backgroundColor: "var(--business-color)",
    color: "var(--white001)",
    fontSize: "24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "16px",
    ...(props.buttonStyle as Record<string, any>),
  };

  return <Button {...props} />;
};

const baseButtonStyle = {
  // padding: "8px 12px",
  borderRadius: "4px",
  border: "none",
};

const VariantStyle: Record<ButtonVariant, CSSProperties> = {
  cancel: {
    backgroundColor: "var(--colors-button-button_g02, #E8E9E9)",
  },
  confirm: {
    backgroundColor: "#5560EB",
  },
  selectConfirm: {
    // margin: "19.5px 111.5px",
  },
  free: {},
};

export const CircularButton = styled(ActionButton)<{
  buttonType: ButtonVariant;
}>(baseButtonStyle, ({ buttonType }) => {
  const css = VariantStyle[buttonType];

  if (buttonType == "cancel") {
    css["border"] = "2px solid var(--button_g05, rgba(117, 117, 117, 0.50))";
  }
  if (buttonType == "confirm") {
    css["border"] = "2px solid #FFF";
  }

  css["borderRadius"] = "999px";

  return css as Record<string, string | number>;
});

export const SquareButton = styled(ActionButton)<{
  buttonType: ButtonVariant;
}>(baseButtonStyle, ({ buttonType }) => {
  return VariantStyle[buttonType] as Record<string, string | number>;
});
