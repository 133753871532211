import { ReactNode } from "react";
import { COLORS, Title } from "vd-component";

interface AiLoadingProps {
  icon: ReactNode;
  title: string;
}
const AiLoading = ({ icon, title }: AiLoadingProps) => {
  return (
    <div css={{ width: "100%", height: "100%", overflow: "hidden" }}>
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: "3",
          alignItems: "center",
        }}
      >
        {icon}
        <Title
          size="x-large"
          title={title}
          css={{ color: COLORS.colorInverseLabelLight, textAlign: "center" }}
        />
      </div>
      <div
        css={{
          background: COLORS.colorOpacity52,
          width: "100%",
          height: "100%",
          zIndex: 2,
          position: "fixed",
          top: 0,
          left: 0,
        }}
      />
    </div>
  );
};

export default AiLoading;
