import { NurseIcon } from "@/theme/CustomIcons";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Title } from "vd-component";

const ScreenFollowUpComplete = () => {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      navigate("/");
    }, 3000);
  }, []);
  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        gap: "24px",
      }}
    >
      <NurseIcon />
      <Title
        size="2x-large"
        title="재진 접수가 완료 되었습니다!<br />진료 순서가 되면 {0}로 이동해 주세요!"
        highlightArr={["진료실"]}
        css={{ textAlign: "center" }}
      />
    </div>
  );
};

export default ScreenFollowUpComplete;
