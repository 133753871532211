import CalendarCheckIcon from "@assets/icons/calendar-check.svg";
import { useEffect } from "react";
import { COLORS, Title } from "vd-component";
const ReceptionAppCheckin = ({
  patientName,
  nextStep,
}: {
  patientName: string;
  nextStep: Function;
}) => {
  useEffect(() => {
    setTimeout(() => {
      nextStep();
    }, 3000);
  }, []);

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <img
        src={CalendarCheckIcon}
        alt="calendar-check"
        css={{ marginBottom: "24px" }}
      />
      <Title
        size="2x-large"
        title={`${patientName} 회원님<br />
당일 예약이 확인 되었습니다!`}
        description="잠시만 기다려주세요"
        css={{
          textAlign: "center",
        }}
        descriptionStyleProps={{ color: COLORS.colorLabelLight }}
      />
    </div>
  );
};

export default ReceptionAppCheckin;
