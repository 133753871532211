import StorageManager from "@/common/services/StorageManager";
import { usePatientDataStore } from "@/features/Screen/store/ScreenStore";
import { ArrowLeftLabelLightIcon } from "@/theme/CustomIcons";
import { makePatientData } from "@/utils/PatientUtils";
import { HTMLAttributes, useMemo } from "react";
import {
  Button,
  COLORS,
  typography_style_body_md_medium,
  typography_style_heading_sm_medium,
} from "vd-component";
interface SubHeaderProps extends HTMLAttributes<HTMLDivElement> {
  backFunc?: Function;
  backText?: string;
  isShowPatientInfo?: boolean;
  isShowHospital?: boolean;
  mode?: "light" | "dark";
}

const SubHeader = ({
  backFunc,
  backText = "뒤로가기",
  isShowPatientInfo,
  isShowHospital = true,
  mode = "light",
  ...props
}: SubHeaderProps) => {
  const isLight = mode === "light";
  const hospital = StorageManager.getDataNow("hospital");
  const patientStore = usePatientDataStore.getState();

  const patientInfo = useMemo(() => {
    if (!isShowPatientInfo) return "";

    const patientData = makePatientData(
      patientStore.birth,
      patientStore.identityGender,
    );

    return patientData;
  }, [patientStore, isShowPatientInfo]);

  return (
    <header
      css={{
        padding: "12px 0",
        display: "flex",
        justifyContent: backFunc ? "space-between" : "flex-end",
        alignItems: "center",
      }}
      {...props}
    >
      <Button
        size="medium"
        leftIcon={
          <ArrowLeftLabelLightIcon
            color={
              isLight ? COLORS.colorLabelLight : COLORS.colorInverseLabelLight
            }
          />
        }
        variant={isLight ? "secondary-line" : "dark"}
        css={{
          display: backFunc ? "flex" : "none",
          alignItems: "center",
          gap: "4px",
          padding: "8px 16px",
          borderRadius: "999px",
          backdropFilter: isLight ? "blur(calc(12 / 2))" : "none",
        }}
        onClick={() => {
          if (backFunc) {
            backFunc();
          }
        }}
      >
        <span
          css={[
            typography_style_body_md_medium,
            {
              color: isLight
                ? COLORS.colorLabelLight
                : COLORS.colorInverseLabelLight,
            },
          ]}
        >
          {backText}
        </span>
      </Button>

      <div
        css={{
          display: "flex",
          alignItems: "center",
          gap: "6px",
          color: isLight
            ? COLORS.colorLabelNomal
            : COLORS.colorInverseLabelLight,
        }}
      >
        {isShowPatientInfo && (
          <span css={[typography_style_heading_sm_medium]}>
            {patientStore.name}
          </span>
        )}
        {isShowPatientInfo && (
          <span css={[typography_style_heading_sm_medium]}>{patientInfo}</span>
        )}
        {isShowHospital && (
          <Button
            disabled={true}
            size="medium"
            css={{
              opacity: 1,
              display: "flex",
              alignItems: "center",
              marginLeft: "6px",
              gap: "4px",
              padding: "8px 16px",
              borderRadius: "999px",
              border: isLight ? "1px solid" : "none",
              borderColor: COLORS.colorLineNomal,
              background: isLight
                ? COLORS.colorBackgroundLight
                : COLORS.colorOpacity40,
              backdropFilter: isLight ? "blur(calc(12 / 2))" : "none",
            }}
          >
            <span
              css={[
                typography_style_body_md_medium,
                {
                  color: isLight
                    ? COLORS.colorLabelLight
                    : COLORS.colorInverseLabelLight,
                },
              ]}
            >
              {hospital}
            </span>
          </Button>
        )}
      </div>
    </header>
  );
};

export default SubHeader;
