import { LoadingIcon } from "@/theme/CustomIcons";
import Doctor from "@assets/icons/doctor04.svg";
import { useEffect } from "react";
import { COLORS, Title } from "vd-component";
const ScreenWaitStep1 = ({
  patientName,
  nextStep,
}: {
  patientName: string;
  nextStep: Function;
}) => {
  useEffect(() => {
    setTimeout(() => {
      nextStep();
    }, 3000);
  }, []);

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        gap: "24px",
        backgroundColor: COLORS.colorPrimaryNomal,
      }}
    >
      {/* <img src={Doctor} alt="doctor" /> */}
      <LoadingIcon />
      <Title
        size="2x-large"
        title={`안녕하세요 ${patientName}님<br />맞춤 진료를 위해 사전 문진을 시작합니다`}
        description="본 문진은 환자분의 진료에 활용되는 기초자료입니다."
        css={{ textAlign: "center", color: COLORS.colorInverseLabelLight }}
        descriptionStyleProps={{
          color: COLORS.colorInverseLabelNomal,
        }}
      />
    </div>
  );
};

export default ScreenWaitStep1;
