import { useHumanBodyStore } from "@/features/humanBodyDiagram/context/useHumanBodyStore";
import type {
  GSelection,
  SvgSelection,
} from "@/features/humanBodyDiagram/stores/humanBodySvgSlice";
import type { HumanBodyZoomSlice } from "@/features/humanBodyDiagram/stores/humanBodyZoomSlice";
import * as d3 from "d3";
import { useEffect } from "react";

/**
 * 줌을 컨트롤할 수 있는 컨트롤러 생성
 * - 줌과 드래그 기능이 함께 설정됨
 */
export const useZoomSetup = () => {
  const {
    svgSelection,
    gSelection,
    setZoomControl,
    zoomControl,
    disabledZoom,
  } = useHumanBodyStore((store) => ({
    svgSelection: store.svgSelection,
    gSelection: store.gSelection,
    setZoomControl: store.setZoomControl,
    zoomControl: store.zoomControl,
    disabledZoom: store.disabledZoom,
  }));

  useEffect(() => {
    if (disabledZoom) return;

    if (svgSelection && gSelection) {
      setupZoom({ svgSelection, gSelection, setZoomControl });
    }

    return () => {
      // 클린업 함수: 줌 이벤트 리스너 제거
      if (zoomControl && svgSelection) {
        svgSelection.on(".zoom", null);
      }
    };
  }, [svgSelection, gSelection, disabledZoom]);
};

// 줌 기능 추가
const setupZoom = ({
  svgSelection,
  gSelection,
  setZoomControl,
}: {
  svgSelection: SvgSelection;
  gSelection: GSelection;
  setZoomControl: HumanBodyZoomSlice["setZoomControl"];
}) => {
  const zoom = d3
    .zoom<SVGSVGElement, unknown>()
    .scaleExtent([0.5, 3])
    .on("zoom", (event: d3.D3ZoomEvent<SVGSVGElement, unknown>) => {
      requestAnimationFrame(() => {
        gSelection.attr("transform", event.transform.toString());
      });
    });
  // .filter((event) => {
  //   // 더블클릭 이벤트 필터링
  //   if (event.type === "dblclick") return false; // 더블클릭 이벤트 차단
  //   return !event.ctrlKey && event.button === 0; // 일반 클릭/드래그만 허용
  // });

  // 위에서 기본 더블클릭 줌인 기능을 막고, 줌인과 줌아웃 토글 커스텀 로직
  svgSelection.on("dblclick", (event) => {
    event.preventDefault();
    const transition = svgSelection.transition().duration(200);

    // 현재 transform 상태 가져오기
    const transform = d3.zoomTransform(svgSelection.node()!);
    // 클릭한 위치의 좌표 계산이 필요함
    const [x, y] = d3.pointer(event);
    const currentScale = transform.k;
    const zoomInScale = 1.8;

    if (currentScale < 1.8) {
      // 줌인: 클릭 지점 중심으로
      transition.call(
        zoom.transform,
        transform
          .translate(
            x - currentScale * x * zoomInScale,
            y - currentScale * y * zoomInScale
          )
          .scale(currentScale * zoomInScale)
      );
    } else {
      // 줌아웃: 클릭 지점 중심으로
      transition.call(zoom.transform, d3.zoomIdentity);
    }
  });

  setZoomControl(zoom);
  svgSelection.call(zoom);
};
