import { svgMappings } from "@/features/humanBodyDiagram/entities/svgMappings";

export const preloadSvgCache = async (): Promise<Record<string, string>> => {
  const cache: Record<string, string> = {};
  await Promise.all(
    svgMappings.map(async (mapping) => {
      if (typeof mapping.value === "string") {
        try {
          const response = await fetch(mapping.value);
          const svgText = await response.text();
          cache[mapping.value] = svgText;
        } catch (error) {
          console.error(`Failed to preload SVG: ${mapping.value}`, error);
        }
      }
    })
  );
  return cache;
};
