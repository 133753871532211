import { GrayLayout, Header } from "@/components/layout/Layouts";
import { HumanBodyWideViewer } from "@/features/humanBodyDiagram/components/wide/HumanBodyWideViewer";
import { HumanBodyProvider } from "@/features/humanBodyDiagram/context/useHumanBodyStore";
import { DepartmentEnum } from "@/features/humanBodyDiagram/entities/departmentMappings";

import Close from "@assets/icons/close.svg";
import Zoom from "@assets/lottie/zoom.json";
import styled from "@emotion/styled";
import Player from "lottie-react";
import { MouseEventHandler, useState } from "react";
import {
  COLORS,
  typography_style_body_lg_medium,
  typography_style_heading_lg_semibold,
} from "vd-component";

const BodyViewContainer = styled.div({
  height: "79.9%",
  display: "flex",
  borderRadius: "15.76px",
  background: COLORS.colorBackgroundWhite,
  margin: "16px 40px 40px 40px",
});

const DimContainer = styled.div({
  background: "var(--color-opacity-52, rgba(0, 0, 0, 0.52))",
  position: "absolute",
  width: "100%",
  height: "100%",
  padding: "40px",
  zIndex: 9999,
});

const DimCenterContainer = styled.div({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  color: COLORS.colorStaticWhite,
  justifyContent: "center",
});

const InfoDim = ({
  onClick,
}: {
  onClick: MouseEventHandler<HTMLDivElement>;
}) => {
  return (
    <DimContainer onClick={onClick}>
      <div css={{ width: "100%", height: "32px" }}>
        <img style={{ float: "right" }} src={Close} />
      </div>

      <DimCenterContainer>
        <Player
          style={{ width: "100px", height: "100px" }}
          loop={true}
          animationData={Zoom}
        />
        <span
          css={{
            ...typography_style_heading_lg_semibold,
          }}
        >
          손가락으로 인체도를 확대/축소해보세요
        </span>
        <span
          css={{
            ...typography_style_body_lg_medium,
          }}
        >
          아픈 부위를 더 자세하게 선택가능해요
        </span>
      </DimCenterContainer>
    </DimContainer>
  );
};

const ScreenSelectBodyNew = ({
  backFunc,
  department,
  setBodyData,
}: {
  backFunc: () => void;
  department: DepartmentEnum;
  setBodyData: (data: string[]) => void;
}) => {
  const [dimShow, setDimShow] = useState<boolean>(true);

  return (
    <>
      <GrayLayout>
        <Header data={true} backFunc={backFunc} />
        <BodyViewContainer>
          <HumanBodyProvider>
            <HumanBodyWideViewer
              setBodyData={setBodyData}
              painAreas={[]}
              department={department}
            />
          </HumanBodyProvider>
        </BodyViewContainer>
      </GrayLayout>
      {dimShow && (
        <InfoDim
          onClick={() => {
            setDimShow(false);
          }}
        />
      )}
    </>
  );
};

export default ScreenSelectBodyNew;
