import NewLayout from "@/components/common/NewLayout";
import { NetworkManager } from "@/network-module/NetworkManager";
import { useEffect, useState } from "react";
import {
  Button,
  COLORS,
  Title,
  typography_style_heading_lg_semibold,
} from "vd-component";

type SickState = {
  id: number;
  content: string;
  checked: boolean;
};

const ScreenSelectSickPeriod = ({
  backFunc,
  setPeriodData,
  departmentId,
}: {
  backFunc: Function;
  setPeriodData: Function;
  departmentId: number;
}) => {
  const { data: sickPeriod } =
    NetworkManager.checkupService.getSickPeriod(departmentId);
  const [sickState, setSickState] = useState<SickState[]>([]);

  const makeAnswer = () => {
    let answer;
    sickState.map((sv) => {
      if (sv.checked) {
        answer = {
          content: sv.content,
          id: sv.id,
        };
      }
    });
    return [answer];
  };

  const makeSickData = () => {
    return {
      id: sickPeriod?.id,
      title: "symptom_history",
      question: sickPeriod?.question,
      answers: makeAnswer(),
    };
  };

  const checkOneChecked = () => {
    let requiredChecked = false;
    sickState.forEach((sick) => {
      if (sick.checked) {
        requiredChecked = true;
        return;
      }
    });
    return requiredChecked;
  };

  useEffect(() => {
    if (sickPeriod?.screeningAnswer) {
      setSickState(() => {
        return sickPeriod.screeningAnswer.map((v) => {
          return {
            id: v.id,
            content: v.content,
            checked: false,
          };
        });
      });
    }
  }, [sickPeriod]);

  return (
    <NewLayout
      backFunc={backFunc}
      isShowPatientInfo={true}
      css={{
        padding: "44px 62px",
        flexDirection: "column",
        gap: "24px",
        justifyContent: "normal",
      }}
    >
      <Title
        size="2x-large"
        title="{0}을 선택해 주세요."
        highlightArr={["아팠던 기간"]}
        description="통증이 심해진 기준으로 선택해주세요."
        css={{ alignItems: "flex-start" }}
      />
      <div
        css={{
          display: "grid",
          gridTemplateColumns: "repeat(4, 1fr)",
          gap: "20px",
          minHeight: "180px",
        }}
      >
        {sickState.map((sv) => {
          const isSelected = sv.checked;
          return (
            <div
              key={sv.id}
              css={{
                padding: "20px 15px",
                borderRadius: "12px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: isSelected ? "2px solid" : "1px solid",
                borderColor: isSelected
                  ? COLORS.colorInversePrimaryNomal
                  : COLORS.colorLineLight,
                height: "80px",
                boxShadow: isSelected
                  ? "0 0 8px 0 rgba(0, 0, 0, 0.08), 0 8px 16px 0 rgba(0, 0, 0, 0.08), 0 16px 20px 0 rgba(0, 0, 0, 0.12)"
                  : "none",
              }}
              onClick={() => {
                setSickState(
                  sickState.map((sick) =>
                    sick.id === sv.id
                      ? { ...sick, checked: !sv.checked }
                      : { ...sick, checked: false },
                  ),
                );
              }}
            >
              <span css={[typography_style_heading_lg_semibold]}>
                {sv.content}
              </span>
            </div>
          );
        })}
      </div>

      <div css={{ display: "flex", justifyContent: "center" }}>
        <Button
          size="x-large"
          css={{ width: "397px" }}
          disabled={!checkOneChecked()}
          onClick={() => {
            setPeriodData(makeSickData());
          }}
        >
          확인
        </Button>
      </div>
    </NewLayout>
  );
};

export default ScreenSelectSickPeriod;
