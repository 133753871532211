import ResetIcon from "@/assets/icons/reset_icon.svg";
import { useHumanBodyStore } from "@/features/humanBodyDiagram/context/useHumanBodyStore";
import styled from "@emotion/styled";
import { typography_style_heading_sm_semibold } from "vd-component";
import { BodyMainCategoryEnum } from "../../entities/categories";
import { DepartmentEnum } from "../../entities/departmentMappings";

export const HumanBodyResetButton = () => {
  const { department, resetPainArea, handleChangeMainCategory, resetZoom } =
    useHumanBodyStore((store) => ({
      department: store.department,
      resetPainArea: store.resetPainArea,
      handleChangeMainCategory: store.handleChangeMainCategory,
      resetZoom: store.resetZoom,
    }));

  const handleReset = () => {
    resetPainArea();
    resetZoom({ duration: 300 });
    if (department === DepartmentEnum.NEUROLOGY) {
      handleChangeMainCategory(BodyMainCategoryEnum.NHEAD);
    } else {
      handleChangeMainCategory("body");
    }
  };

  return (
    <Container>
      <ButtonContainer onClick={handleReset}>
        <img src={ResetIcon} alt="reset" />
        <span>전체 초기화</span>
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.div`
  border-radius: 999px;
  margin-left: auto;
  position: absolute;
  ${typography_style_heading_sm_semibold}
  display: flex;
  justify-content: flex-end;
  z-index: 2;
  right: 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
