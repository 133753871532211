import StorageManager from "@/common/services/StorageManager";
import KioskLeft from "@/components/kiosk/KioskLeft";
import { NetworkManager } from "@/network-module/NetworkManager";
import { ArrowRotateLeftIcon } from "@/theme/CustomIcons";
import dayjs from "dayjs";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { COLORS, Title, typography_style_body_md_medium } from "vd-component";

const ReceptionQRPage = () => {
  const [remainingTime, setRemainingTime] = useState(0);

  const hospitalId = StorageManager.getDataNow("hospitalId");
  const {
    data: qrCodeData,
    isRefetching,
    refetch,
  } = NetworkManager.hospitalService.getKioskQRCode(hospitalId);

  // 00:05:00에 refetch
  useEffect(() => {
    if (remainingTime <= 0 || isNaN(remainingTime)) return;

    const intervalQr = setInterval(() => {
      console.log("interval 된 시간", dayjs().format("YYYY.MM.DD HH:mm:ss"));
      refetch();
    }, remainingTime);

    return () => clearInterval(intervalQr);
  }, [remainingTime]);

  // refetch후 남은 시간을 다시 계산
  useEffect(() => {
    if (isRefetching || !qrCodeData) return;
    const endOfDay = dayjs().endOf("day");
    const date = dayjs.unix(qrCodeData?.time);
    const currentServerTime = date.format("YYYY-MM-DD HH:mm:ss");
    const getRemainingTime = endOfDay.diff(currentServerTime, "ms") + 300000; // 자정지나 5분후에 호출기위해
    console.log(
      "isRefetch되었을때의 다음 24시간 시작 시간",
      endOfDay.format("YYYY.MM.DD HH:mm:ss"),
      "\nms로 나타내었을때:",
      getRemainingTime,
    );

    setRemainingTime(getRemainingTime);
  }, [isRefetching, qrCodeData]);

  return (
    <main css={{ display: "flex", height: "100%" }}>
      <KioskLeft />
      <div
        css={{
          flex: 7,
          display: "flex",
          flexDirection: "column",
          gap: "12px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Title
          title={t("qr.description")}
          highlightArr={[t("qr.phone")]}
          size="2x-large"
          css={{ textAlign: "center" }}
        />

        <img src={qrCodeData?.url} alt="qrCode" css={{ width: "50%" }} />

        {qrCodeData && (
          <div
            css={{
              display: "flex",
              gap: "4px",
              alignItems: "center",
              marginTop: "-30px",
              cursor: "pointer",
            }}
            onClick={() => refetch()}
          >
            <ArrowRotateLeftIcon css={{ width: "18px", height: "18px" }} />
            <span
              css={[
                typography_style_body_md_medium,
                { color: COLORS.colorLabelLight },
              ]}
            >
              새로고침
            </span>
          </div>
        )}
      </div>
    </main>
  );
};

export default ReceptionQRPage;
