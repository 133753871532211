import NetworkModal from "@/components/common/NetworkModal";
import useNetwork from "@/hook/useNetwork";
import { PropsWithChildren, useEffect } from "react";

const KioskProvider = ({ children }: PropsWithChildren) => {
  const { isOffline, setIsOffline } = useNetwork();

  useEffect(() => {
    const handleOnline = () => setIsOffline(false);
    const handleOffline = () => setIsOffline(true);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return (
    <>
      {children}

      {isOffline && <NetworkModal />}
    </>
  );
};

export default KioskProvider;
