import { HighLight } from "@/components/common/HighLight";
import { Modal } from "@/components/common/modal/Modal";
import { NetworkManager } from "@/network-module/NetworkManager";
import CloseIcon from "@assets/icons/close.svg";
import axios from "axios";
import "prismjs/themes/prism-tomorrow.css";
import { MouseEventHandler, useEffect, useState } from "react";
// import { NotionRenderer } from "react-notion";
import "react-notion/src/styles.css";
import NotionRenderer from "./NotionRender";

export function extractLastPartAfterSlash(url: string): string {
  const parts = url.split("/");
  return parts[parts.length - 1];
}

const TermContentModal = ({
  isModalOpen,
  termId = 0,
  onClose,
}: {
  isModalOpen: boolean;
  termId: number;
  onClose: MouseEventHandler | Function;
}) => {
  const [response, setResponse] = useState<Object | null>(null);
  const { data: termSummaryDetail } =
    NetworkManager.termService.getTermSummaryDetail(termId, isModalOpen);

  useEffect(() => {
    if (
      termId !== 0 &&
      isModalOpen &&
      termSummaryDetail?.id &&
      termSummaryDetail?.content
    ) {
      let id = extractLastPartAfterSlash(termSummaryDetail?.content);

      axios
        .get(`https://notion-api.splitbee.io/v1/page/${id}`)
        .then((data) => {
          setResponse(data.data);
        })
        .catch((e) => {
          setResponse(null); // 에러 발생 시 null 처리
        });
    }
  }, [termId, isModalOpen, termSummaryDetail]);

  const handleModalClose = () => {
    onClose(0);
    setResponse(null); // 모달 닫을 때 상태 초기화
  };

  if (!isModalOpen || termId === 0) {
    return null; // 모달이 닫혀 있거나 termId가 0일 경우 렌더링 안 함
  }

  return (
    <Modal
      onClose={handleModalClose}
      isOpen={isModalOpen}
      modalStyle={{
        width: "100%",
        height: "100%",
        background: "var(--surface_g02)",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ width: "100%" }}>
        <img
          style={{ float: "right" }}
          onClick={handleModalClose}
          src={CloseIcon}
          width={32}
          height={32}
        ></img>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <div
          style={{
            fontSize: "40px",
            marginBottom: "44px",
          }}
        >
          <HighLight
            textColor="black"
            text={`${termSummaryDetail?.title ?? ""}`}
          />
        </div>
        <div
          style={{
            background: "white",
            width: "80%",
            height: "70%",
            padding: "38px",
            overflow: "scroll",
          }}
        >
          {response ? (
            // <NotionRenderer
            //   bl={response}
            //   fullPage={true}
            //   darkMode={false}
            // />
            <NotionRenderer data={response} />
          ) : (
            <div>Loading...</div> // 데이터가 없을 때 로딩 표시
          )}
        </div>
      </div>
    </Modal>
  );
};

export default TermContentModal;
