import FootBackLeft from "@/assets/bodypart/surgery/surgery_foot_back_left.svg";
import FootBackRight from "@/assets/bodypart/surgery/surgery_foot_back_right.svg";
import FootBottomLeft from "@/assets/bodypart/surgery/surgery_foot_bottom_left.svg";
import FootBottomRight from "@/assets/bodypart/surgery/surgery_foot_bottom_right.svg";
import FootTopLeft from "@/assets/bodypart/surgery/surgery_foot_top_left.svg";
import FootTopRight from "@/assets/bodypart/surgery/surgery_foot_top_right.svg";

import FootInSideLeft from "@/assets/bodypart/surgery/surgery_foot_inside_left.svg";
import FootInSideRight from "@/assets/bodypart/surgery/surgery_foot_inside_right.svg";
import FootOutSideLeft from "@/assets/bodypart/surgery/surgery_foot_outside_left.svg";
import FootOutSideRight from "@/assets/bodypart/surgery/surgery_foot_outside_right.svg";

import HandInLeft from "@/assets/bodypart/surgery/surgery_hand_inside_left.svg";
import HandInRight from "@/assets/bodypart/surgery/surgery_hand_inside_right.svg";
import HandOutLeft from "@/assets/bodypart/surgery/surgery_hand_outside_left.svg";
import HandOutRight from "@/assets/bodypart/surgery/surgery_hand_outside_right.svg";

import InternalBodyBack from "@/assets/bodypart/internal/internal_body_back.svg";
import InternalBodyFront from "@/assets/bodypart/internal/internal_body_front.svg";

import SurgeryBodyBack from "@/assets/bodypart/surgery/surgery_body_back.svg";
import SurgeryBodyFront from "@/assets/bodypart/surgery/surgery_body_front.svg";

import NeurologyBodyBack from "@/assets/bodypart/neurology/neurology_body_back.svg";
import NeurologyBodyLeft from "@/assets/bodypart/neurology/neurology_body_left.svg";
import NeurologyBodyRight from "@/assets/bodypart/neurology/neurology_body_right.svg";

import ENTBodyLeft from "@/assets/bodypart/ent/ent_body_left.svg";
import ENTBodyRight from "@/assets/bodypart/ent/ent_body_right.svg";

import {
  BodyMainCategoryEnum,
  BodySubCategoryEnum,
  FootSubCategoryEnum,
  HandSubCategoryEnum,
  PartDirectionEnum,
} from "@/features/humanBodyDiagram/entities/categories";
import { DepartmentEnum } from "@/features/humanBodyDiagram/entities/departmentMappings";
import {
  createDepartmentCondition,
  createDirectionCondition,
  createMainCategoryCondition,
  createSubCategoryCondition,
} from "@/features/humanBodyDiagram/helper/conditionCreators";
import { and } from "@/features/humanBodyDiagram/utils/conditionUtils";
import {
  type Mapping,
  mappingFactory,
} from "@/features/humanBodyDiagram/utils/mappingFactory";

/**
 * 인체 다이어그램 svg src 조건 매핑
 * */
export const svgMappings: Mapping<string>[] = [
  // 전신
  // 전신 > 내과
  mappingFactory(
    and(
      createMainCategoryCondition(BodyMainCategoryEnum.BODY),
      createSubCategoryCondition(BodySubCategoryEnum.FRONT),
      createDepartmentCondition(DepartmentEnum.INTERNAL)
    ),
    InternalBodyFront
  ),
  mappingFactory(
    and(
      createMainCategoryCondition(BodyMainCategoryEnum.BODY),
      createSubCategoryCondition(BodySubCategoryEnum.BACK),
      createDepartmentCondition(DepartmentEnum.INTERNAL)
    ),
    InternalBodyBack
  ),

  // 전신 > 외과
  mappingFactory(
    and(
      createMainCategoryCondition(BodyMainCategoryEnum.BODY),
      createSubCategoryCondition(BodySubCategoryEnum.FRONT),
      createDepartmentCondition(DepartmentEnum.SURGERY)
    ),
    SurgeryBodyFront
  ),
  mappingFactory(
    and(
      createMainCategoryCondition(BodyMainCategoryEnum.BODY),
      createSubCategoryCondition(BodySubCategoryEnum.BACK),
      createDepartmentCondition(DepartmentEnum.SURGERY)
    ),
    SurgeryBodyBack
  ),

  // 밑으로 다 외과임
  // 손
  // 손 > 손바닥
  mappingFactory(
    and(
      createMainCategoryCondition(BodyMainCategoryEnum.HAND),
      createSubCategoryCondition(HandSubCategoryEnum.IN),
      createDirectionCondition(PartDirectionEnum.RIGHT)
    ),
    HandInRight
  ),
  mappingFactory(
    and(
      createMainCategoryCondition(BodyMainCategoryEnum.HAND),
      createSubCategoryCondition(HandSubCategoryEnum.IN),
      createDirectionCondition(PartDirectionEnum.LEFT)
    ),
    HandInLeft
  ),

  // 손 > 손등
  mappingFactory(
    and(
      createMainCategoryCondition(BodyMainCategoryEnum.HAND),
      createSubCategoryCondition(HandSubCategoryEnum.OUT),
      createDirectionCondition(PartDirectionEnum.RIGHT)
    ),
    HandOutRight
  ),
  mappingFactory(
    and(
      createMainCategoryCondition(BodyMainCategoryEnum.HAND),
      createSubCategoryCondition(HandSubCategoryEnum.OUT),
      createDirectionCondition(PartDirectionEnum.LEFT)
    ),
    HandOutLeft
  ),

  // 발
  // 발 > 발등
  mappingFactory(
    and(
      createMainCategoryCondition(BodyMainCategoryEnum.FOOT),
      createSubCategoryCondition(FootSubCategoryEnum.TOP),
      createDirectionCondition(PartDirectionEnum.RIGHT)
    ),
    FootTopRight
  ),
  mappingFactory(
    and(
      createMainCategoryCondition(BodyMainCategoryEnum.FOOT),
      createSubCategoryCondition(FootSubCategoryEnum.TOP),
      createDirectionCondition(PartDirectionEnum.LEFT)
    ),
    FootTopLeft
  ),

  // 발 > 발뒤
  mappingFactory(
    and(
      createMainCategoryCondition(BodyMainCategoryEnum.FOOT),
      createSubCategoryCondition(FootSubCategoryEnum.BACK),
      createDirectionCondition(PartDirectionEnum.RIGHT)
    ),
    FootBackRight
  ),
  mappingFactory(
    and(
      createMainCategoryCondition(BodyMainCategoryEnum.FOOT),
      createSubCategoryCondition(FootSubCategoryEnum.BACK),
      createDirectionCondition(PartDirectionEnum.LEFT)
    ),
    FootBackLeft
  ),

  // 발 > 발바닥
  mappingFactory(
    and(
      createMainCategoryCondition(BodyMainCategoryEnum.FOOT),
      createSubCategoryCondition(FootSubCategoryEnum.BOTTOM),
      createDirectionCondition(PartDirectionEnum.RIGHT)
    ),
    FootBottomRight
  ),
  mappingFactory(
    and(
      createMainCategoryCondition(BodyMainCategoryEnum.FOOT),
      createSubCategoryCondition(FootSubCategoryEnum.BOTTOM),
      createDirectionCondition(PartDirectionEnum.LEFT)
    ),
    FootBottomLeft
  ),

  // 발 > 바깥쪽
  mappingFactory(
    and(
      createMainCategoryCondition(BodyMainCategoryEnum.FOOT),
      createSubCategoryCondition(FootSubCategoryEnum.OUT_SIDE),
      createDirectionCondition(PartDirectionEnum.RIGHT)
    ),
    FootOutSideRight
  ),
  mappingFactory(
    and(
      createMainCategoryCondition(BodyMainCategoryEnum.FOOT),
      createSubCategoryCondition(FootSubCategoryEnum.OUT_SIDE),
      createDirectionCondition(PartDirectionEnum.LEFT)
    ),
    FootOutSideLeft
  ),

  // 발 > 안쪽
  mappingFactory(
    and(
      createMainCategoryCondition(BodyMainCategoryEnum.FOOT),
      createSubCategoryCondition(FootSubCategoryEnum.IN_SIDE),
      createDirectionCondition(PartDirectionEnum.RIGHT)
    ),
    FootInSideRight
  ),
  mappingFactory(
    and(
      createMainCategoryCondition(BodyMainCategoryEnum.FOOT),
      createSubCategoryCondition(FootSubCategoryEnum.IN_SIDE),
      createDirectionCondition(PartDirectionEnum.LEFT)
    ),
    FootInSideLeft
  ),

  // 신경과
  mappingFactory(
    and(
      createMainCategoryCondition(BodyMainCategoryEnum.NHEAD),
      createSubCategoryCondition(BodySubCategoryEnum.RIGHT),
      createDepartmentCondition(DepartmentEnum.NEUROLOGY)
    ),
    NeurologyBodyRight
  ),
  mappingFactory(
    and(
      createMainCategoryCondition(BodyMainCategoryEnum.NHEAD),
      createSubCategoryCondition(BodySubCategoryEnum.LEFT),
      createDepartmentCondition(DepartmentEnum.NEUROLOGY)
    ),
    NeurologyBodyLeft
  ),
  mappingFactory(
    and(
      createMainCategoryCondition(BodyMainCategoryEnum.NHEAD),
      createSubCategoryCondition(BodySubCategoryEnum.BACK),
      createDepartmentCondition(DepartmentEnum.NEUROLOGY)
    ),
    NeurologyBodyBack
  ),

  // 이비인후과
  mappingFactory(
    and(
      createMainCategoryCondition(BodyMainCategoryEnum.EHEAD),
      createSubCategoryCondition(BodySubCategoryEnum.RIGHT),
      createDepartmentCondition(DepartmentEnum.ENT)
    ),
    ENTBodyRight
  ),
  mappingFactory(
    and(
      createMainCategoryCondition(BodyMainCategoryEnum.EHEAD),
      createSubCategoryCondition(BodySubCategoryEnum.LEFT),
      createDepartmentCondition(DepartmentEnum.ENT)
    ),
    ENTBodyLeft
  ),

  // ... 다른 매핑 추가
];
