import { DirectionTab } from "@/features/humanBodyDiagram/components/DirectionTab/DirectionTab";
import { useHumanBodyStore } from "@/features/humanBodyDiagram/context/useHumanBodyStore";
import { BodyMainCategoryEnum } from "@/features/humanBodyDiagram/entities/categories";
import { t } from "i18next";

export const HumanBodyDirectionTabs = () => {
  const { mainCategory, direction, setDirection } = useHumanBodyStore(
    (store) => ({
      mainCategory: store.mainCategory,
      direction: store.direction,
      setDirection: store.setDirection,
    })
  );

  if (mainCategory === BodyMainCategoryEnum.BODY) return <></>;

  return (
    <DirectionTab
      key={`${mainCategory}-${direction}`}
      css={{
        height: "45px",
        width: "100%",
        display: "flex",
      }}
      value={direction}
      suffixLabel={t(`humanBody.${mainCategory.toLowerCase()}`)}
      onClick={(item) => setDirection(item)}
    />
  );
};
