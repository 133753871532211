/** @jsxImportSource @emotion/react */
"use client";
import { useDrag } from "@/hook/useDrag";
import styled, { Interpolation } from "@emotion/styled";
import { isEqual } from "lodash";
import { HTMLAttributes, Key, LiHTMLAttributes, memo, ReactNode } from "react";

const WarnText = styled.span({
  color: "var(--text_g04, #A4A4A4)",
  fontSize: "0.625rem",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "16px",
  letterSpacing: "0.1px",
});

interface UnOrderListProps extends HTMLAttributes<HTMLUListElement> {
  children: ReactNode;
}

export interface NewUnOrderListProps<Tdata>
  extends HTMLAttributes<HTMLUListElement> {
  items: Tdata[];
  listRef?: React.RefObject<HTMLUListElement>;
  listIdentifier?: string;
  itemTemplateProps: ItemTemplateProps<Tdata>;
  toBeRendered?: (item: Tdata, index: number) => boolean;
  listContainerStyle?: Interpolation<any>;
  warnText?: string;
  warnTextStyle?: Interpolation<any>;
}

export interface ItemTemplateProps<Tdata>
  extends LiHTMLAttributes<HTMLLIElement> {
  itemTemplate: (item: Tdata, index: number) => ReactNode;
  itemContainerStyle?: (item: Tdata, index: number) => Interpolation<any>;
  onItemClick?: (item: Tdata, index: number) => void;
  itemKey?: (item: Tdata, index: number) => Key | null | undefined;
  itemDraggable?: boolean;
}

interface ListItemProps<Tdata> extends ItemTemplateProps<Tdata> {
  item: Tdata;
  index: number;
}

const NewList = <Tdata,>({
  items,
  itemTemplateProps,
  listIdentifier,
  toBeRendered,
  listRef,
  listContainerStyle,
  warnText = "",
  warnTextStyle,
  ...rest
}: NewUnOrderListProps<Tdata>) => {
  const { handleDragOver, handleDrop } = useDrag(listIdentifier ?? "");

  const makeItems = () => {
    if (items.length == 0)
      return (
        <div css={warnTextStyle}>
          <WarnText>{warnText}</WarnText>
        </div>
      );

    return items.map((item, index) => {
      if (toBeRendered?.(item, index) == false) {
        return;
      }

      return (
        <MemoizedItemTemplate
          key={itemTemplateProps.itemKey?.(item, index) ?? index}
          item={item}
          index={index}
          {...itemTemplateProps}
        />
      );
    });
  };

  return (
    <ul
      ref={listRef}
      css={listContainerStyle}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      {...rest}
    >
      {makeItems()}
    </ul>
  );
};

export const MemoizedItemTemplate = memo(
  <Tdata,>({
    item,
    index,
    itemTemplate,
    itemContainerStyle,
    onItemClick,
    itemDraggable,
  }: ListItemProps<Tdata>) => {
    const { handleDragStart } = useDrag("");
    return (
      <li
        key={index}
        draggable={itemDraggable}
        onDragStart={handleDragStart(item)}
        css={[
          {
            fontSize: "0.7rem",
            cursor: "pointer",
            userSelect: "none",
          },
          itemContainerStyle?.(item, index),
        ]}
        onClick={() => onItemClick?.(item, index)}
      >
        {itemTemplate(item, index)}
      </li>
    );
  },
  (prevProps, nextProps) => isEqual(prevProps.item, nextProps.item)
) as <Tdata>(props: ListItemProps<Tdata>) => React.ReactElement;

NewList.UnOrderList = (props: UnOrderListProps) => {
  const { children, ...rest } = props;

  return <ul {...rest}>{children}</ul>;
};

export default NewList;
