import {
  type HumanBodyPartsSlice,
  humanBodyPartsSlice,
} from "@/features/humanBodyDiagram/stores/humanBodyPartsSlice";
import {
  type HumanBodySvgSlice,
  humanBodySvgSlice,
} from "@/features/humanBodyDiagram/stores/humanBodySvgSlice";
import {
  type HumanBodyZoomInitProps,
  type HumanBodyZoomSlice,
  humanBodyZoomSlice,
} from "@/features/humanBodyDiagram/stores/humanBodyZoomSlice";
import {
  createContext,
  type PropsWithChildren,
  useContext,
  useState,
} from "react";
import { createStore, useStore } from "zustand";
import {
  humanBodyPainArea,
  HumanBodyPainArea,
} from "../stores/humanBodyPainArea";

type HumanBodyStore = HumanBodySvgSlice &
  HumanBodyZoomSlice &
  HumanBodyPartsSlice &
  HumanBodyPainArea;

interface InitProps {
  zoomOptions?: HumanBodyZoomInitProps;
}

const createHumanBodyStore = ({ zoomOptions = {} }: InitProps) => {
  return createStore<HumanBodyStore>((...args) => ({
    ...humanBodySvgSlice(...args),
    ...humanBodyZoomSlice(zoomOptions)(...args),
    ...humanBodyPartsSlice(...args),
    ...humanBodyPainArea(...args),
  }));
};

const HumanBodyContext = createContext<ReturnType<
  typeof createHumanBodyStore
> | null>(null);

export const HumanBodyProvider = ({
  children,
  ...initProps
}: PropsWithChildren<InitProps>) => {
  const [store] = useState(() => createHumanBodyStore(initProps));

  return (
    <HumanBodyContext.Provider value={store}>
      {children}
    </HumanBodyContext.Provider>
  );
};

/**
 * Context의 역할
 * 작게 쪼개진 slice 상태들을 통합합니다.
 * slice 상태에 접근할 때 useHumanBodyStore를 통해 접근합니다.
 * HumanBodyProvider가 여러개일 경우, context 상태는 각자 독립적으로 관리 됩니다.(여러 인체도의 상태를 개별로 분리하기 위함)
 * */
export const useHumanBodyStore = <T,>(
  selector: (state: HumanBodyStore) => T
): T => {
  const store = useContext(HumanBodyContext);
  if (!store) throw new Error("HumanBodyProvider를 찾을 수 없습니다");
  return useStore(store, selector);
};
