import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import {
  Button,
  COLORS,
  Popup,
  typography_style_body_lg_medium,
  typography_style_heading_lg_semibold,
} from "vd-component";

interface DuplicateCopyModalProps {
  isOpen: boolean;
  onClose: () => void;
}
const DuplicateCopyModal = ({ isOpen, onClose }: DuplicateCopyModalProps) => {
  const navigate = useNavigate();
  return (
    <Popup isOpen={isOpen} onClose={onClose} css={{ padding: "20px 24px" }}>
      <div
        css={{
          width: "360px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "6px",
          marginBottom: "32px",
        }}
      >
        <span
          css={[
            typography_style_heading_lg_semibold,
            { color: COLORS.colorLabelNomal },
          ]}
        >
          {t("error.errorAlreadyExist")}
        </span>
        <span
          css={[
            typography_style_body_lg_medium,
            { color: COLORS.colorLabelLight },
          ]}
          dangerouslySetInnerHTML={{
            __html: t("reception.modal.moreInfo"),
          }}
        ></span>
      </div>
      <Button
        style={{ width: "100%" }}
        buttonType="common"
        size="large"
        onClick={() => navigate("/")}
      >
        {t("common.confirm")}
      </Button>
    </Popup>
  );
};

export default DuplicateCopyModal;
