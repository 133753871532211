import { InputType } from "@/common/utils/Type";
import { t } from "i18next";
import { Button, Title } from "vd-component";

interface InputFormProps {
  inputType: InputType;
  inputContent: Function;
  buttonAvailable: boolean;
  inputFunc: Function;
  skipFunc?: Function;
}
const InputForm = ({
  inputType,
  inputContent,
  buttonAvailable,
  inputFunc,
  skipFunc,
}: InputFormProps) => {
  const getQuestionText = (inputType: InputType) => {
    let qText = "";
    switch (inputType) {
      case InputType.identityNumber:
        qText = "주민등록번호 전체를<br />입력해주세요";
        break;
      case InputType.phoneNumber:
        qText = "전화번호를 입력해주세요";
        break;
      case InputType.bodySize:
        qText = "먼저 {0}와 {1}를 알려주세요";
        break;
    }
    return qText;
  };

  return (
    <div
      style={{
        width: "100%",
        // maxWidth: "50%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Title
        title={getQuestionText(inputType)}
        highlightArr={["키", "몸무게"]}
        size="2x-large"
        css={{ marginBottom: "24px", whiteSpace: "nowrap" }}
      />

      {inputContent()}

      <div css={{ display: "flex", marginTop: "24px", gap: "12px" }}>
        {skipFunc && (
          <Button
            css={{
              width: "100%",
            }}
            variant="secondary-line"
            size="x-large"
            onClick={() => {
              skipFunc && skipFunc();
            }}
          >
            {t("common.goSkip")}
          </Button>
        )}
        <Button
          css={{
            width: "100%",
            opacity: buttonAvailable ? 1 : 0.6,
            cursor: buttonAvailable ? "pointer" : "not-allowed",
          }}
          size="x-large"
          onClick={() => {
            if (buttonAvailable) {
              inputFunc();
            }
          }}
        >
          {t("common.confirm")}
        </Button>
      </div>
    </div>
  );
};

export default InputForm;
