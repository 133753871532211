import React from "react";

interface NotionBlock {
  value: {
    id: string;
    type: string;
    properties?: {
      title?: Array<Array<string | Array<string>>>;
    };
  };
  role: string;
}

export interface NotionData {
  [key: string]: NotionBlock;
}

interface NotionRendererProps {
  data: NotionData;
}

export const NotionRenderer: React.FC<NotionRendererProps> = ({ data }) => {
  const renderBlock = (block: NotionBlock) => {
    if (
      block.role === "reader" &&
      block.value.type === "text" &&
      block.value.properties?.title
    ) {
      const textContent = block.value.properties.title
        .map((part) => part[0])
        .join("");

      const isBold = block.value.properties.title.some(
        (part) => part.length > 1 && part[1].includes("b")
      );

      if (isBold) {
        return <strong key={block.value.id}>{textContent}</strong>;
      } else {
        return <p key={block.value.id}>{textContent}</p>;
      }
    }
    return null;
  };

  return (
    <div className="notion-content">
      {Object.values(data).map((block) => renderBlock(block))}
    </div>
  );
};

export default NotionRenderer;
