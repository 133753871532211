import ReceptionSelectPrev from "@/features/Reception/components/ReceptionSelectPrev";
import { usePatientDataStore } from "@/features/Screen/store/ScreenStore";
import {
  CheckReciptResponseEntity,
  PatientInfo,
} from "@/network-module/entities/HospitalEntity";
import { NetworkManager } from "@/network-module/NetworkManager";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ScreenWaitStep1 from "../screening/ScreenWaitStep1";
import DuplicateCopyModal from "./DuplicateCopyModal";
import { HospitalData } from "./ReceptionPage";

export type ReceptionType1 = {
  phoneNumber: string;
  name: string;
  birth: string;
  identityGender: number | string;
  relatedPreClinicId: null;
  selectedDoctorId: number | null;
  medicalDepartmentId: number | null;
  identityNumber: string;
  hospitalPatientId: number | string;
  doctorName: string;
};

//재방문 케이스
const ReceptionStep2Type1 = ({
  patientData,
  receiptData,
  setParentStep,
  hospitalData,
}: {
  patientData: PatientInfo;
  receiptData: CheckReciptResponseEntity;
  setParentStep: Function;
  hospitalData: HospitalData;
}) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [step, setStep] = useState(0);

  const { data: prevData } = NetworkManager.hospitalService.getThirtyDays(
    receiptData.hospitalPatientId,
  );

  const submitPatient: ReceptionType1 = {
    hospitalPatientId: receiptData.hospitalPatientId ?? "",
    phoneNumber: patientData.phoneNumber.join(""),
    name: patientData.name,
    birth: patientData.identityNumber.slice(0, 6).join(""),
    identityGender: patientData.identityNumber[6],
    relatedPreClinicId: null,
    selectedDoctorId: hospitalData.selectedDoctorId,
    medicalDepartmentId: hospitalData.medicalDepartmentId,
    identityNumber: patientData.identityNumber.slice(7, 13).join(""),
    doctorName: hospitalData.doctorName,
  };

  const submit = (preClinicRecordId: number) => {
    if (
      receiptData.type === 3 &&
      !!receiptData.alreadyCheckingPreClinicRecordId
    ) {
      if (preClinicRecordId !== null) {
        usePatientDataStore.setState({
          ...submitPatient,
          checkupId: receiptData.alreadyCheckingPreClinicRecordId,
          relatedPreClinic: preClinicRecordId,
          painScore: JSON.parse(prevData[0].painScore)[0],
        });
      } else {
        usePatientDataStore.setState({
          ...submitPatient,
          checkupId: receiptData.alreadyCheckingPreClinicRecordId,
          relatedPreClinic: preClinicRecordId,
        });
      }
      NetworkManager.checkupService
        .kioskAllocate(
          receiptData.alreadyCheckingPreClinicRecordId,
          hospitalData.selectedDoctorId,
        )
        .then(() => {
          setStep(1);
        });
      return;
    }

    NetworkManager.hospitalService
      .kioskReceiptWithHp({
        ...submitPatient,
      })
      .then((checkupId) => {
        NetworkManager.checkupService.kioskAllocate(checkupId).then(() => {});

        if (preClinicRecordId !== null) {
          usePatientDataStore.setState({
            ...submitPatient,
            checkupId: checkupId,
            relatedPreClinic: preClinicRecordId,
            painScore: JSON.parse(prevData[0].painScore)[0],
          });
        } else {
          usePatientDataStore.setState({
            ...submitPatient,
            checkupId: checkupId,
            relatedPreClinic: preClinicRecordId,
          });
        }
        setStep(1);
      })
      .catch((e) => {
        if (e.code === 400015) {
          setIsOpen(true);
        }
      });
  };

  if (step == 0) {
    return (
      <>
        <ReceptionSelectPrev
          backFunc={() => {
            setParentStep(5);
          }}
          hpId={submitPatient.hospitalPatientId}
          nextStep={(preClinicRecordId) => {
            submit(preClinicRecordId);
          }}
        />
        <DuplicateCopyModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
      </>
    );
  }

  if (step === 1) {
    return (
      <ScreenWaitStep1
        patientName={patientData.name}
        nextStep={() => {
          navigate("/checkup");
        }}
      />
    );
  }
};
export default ReceptionStep2Type1;
