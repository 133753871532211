import { useRef, useState } from "react";

export interface PatientSizeType {
  bodySize: { height: string; weight: string };
  bodyFocus: "height" | "weight" | "";
  weightRef: React.RefObject<HTMLInputElement>;
  heightRef: React.RefObject<HTMLInputElement>;
  keypadRef: React.RefObject<HTMLDivElement>;
  setBodySize: React.Dispatch<
    React.SetStateAction<{ height: string; weight: string }>
  >;
  setBodyFocus: React.Dispatch<React.SetStateAction<"height" | "weight" | "">>;
  error: { weight: boolean; height: boolean };
  setError: React.Dispatch<
    React.SetStateAction<{ weight: boolean; height: boolean }>
  >;
  buttonRef: React.RefObject<HTMLDivElement>;
}
const ReceptionPatientSizeHook = (): PatientSizeType => {
  const [bodySize, setBodySize] = useState({ height: "", weight: "" });
  const [bodyFocus, setBodyFocus] = useState<"height" | "weight" | "">(
    "height"
  );
  const weightRef = useRef<HTMLInputElement>(null);
  const heightRef = useRef<HTMLInputElement>(null);
  const keypadRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);
  const [error, setError] = useState({ weight: false, height: false });
  return {
    bodySize,
    setBodySize,
    bodyFocus,
    setBodyFocus,
    weightRef,
    heightRef,
    keypadRef,
    error,
    setError,
    buttonRef,
  };
};

export default ReceptionPatientSizeHook;
