import { HumanBodyMainCategoryTabs } from "@/features/humanBodyDiagram/components/MainCategoryTab/HumanBodyMainCategoryTabs";
import { DepartmentEnum } from "@/features/humanBodyDiagram/entities/departmentMappings";
import styled from "@emotion/styled";

interface Props {
  department?: DepartmentEnum;
}

export const HumanBodyHeader = ({ department }: Props) => {
  return (
    <Container>
      {department === DepartmentEnum.SURGERY && <HumanBodyMainCategoryTabs />}
    </Container>
  );
};

const Container = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.4px;
`;
