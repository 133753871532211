import NewLayout from "@/components/common/NewLayout";
import { GetPain } from "@/network-module/entities/CheckupEntityNew";
import { MicrophoneIcon } from "@/theme/CustomIcons";
import { useEffect, useRef, useState } from "react";
import { Button, COLORS, TextArea, Title } from "vd-component";
import { CustomInputModal } from "../phase3/components/CustomInputModal";
import { usePhase3Hook1 } from "../phase3/store/Phase3Hook1";

interface ScreenAdditionalPainInputProps {
  backFunc: Function;
  nextStep: Function;
  additionalPain: GetPain;
  selectedIndex: number;
}
const ScreenAdditionalPainInput = ({
  backFunc,
  nextStep,
  additionalPain,
  selectedIndex,
}: ScreenAdditionalPainInputProps) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const { isCustomInput, toggleIsCustomInput } = usePhase3Hook1(1);
  const [focus, setFocus] = useState<boolean>(false);
  const [text, setText] = useState("");

  const handleConfirm = () => {
    const selectedAnswer = additionalPain?.screeningAnswer[selectedIndex];

    let elapseData = {
      id: additionalPain?.id,
      title: additionalPain?.title,
      question: additionalPain?.question,
      answers: [
        {
          id: selectedAnswer?.id,
          content: text,
        },
      ],
    };

    nextStep(elapseData);
  };
  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (textAreaRef.current?.contains(e.target as Node)) {
        setFocus(true);
      } else {
        setFocus(false);
      }
    };

    window.addEventListener("click", handleClick);
    return () => window.removeEventListener("click", handleClick);
  }, []);
  return (
    <NewLayout
      backFunc={backFunc}
      isShowPatientInfo={true}
      css={{ padding: "36px 58px" }}
    >
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          width: "100%",
          justifyContent: focus ? "normal" : "center",
          alignItems: "center",
        }}
      >
        <div
          css={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Title
            size="2x-large"
            title="{0}을 직접 입력해주세요"
            highlightArr={["더 아프신 부분"]}
          />
          <div css={{ display: "flex", gap: 12 }}>
            <Button
              variant="secondary"
              size="large"
              css={{
                gap: "4px",
                borderRadius: "999px",
              }}
              leftIcon={<MicrophoneIcon color={COLORS.colorLabelAlternative} />}
              onClick={() => {
                toggleIsCustomInput();
                // setDefaultText(getTextValue());
              }}
            >
              <span>음성 입력</span>
            </Button>
            <Button
              size="large"
              css={{ display: focus ? "flex" : "none", borderRadius: "999px" }}
              onClick={handleConfirm}
              disabled={!text}
            >
              <span>확인</span>
            </Button>
          </div>
        </div>

        <TextArea
          size="x-large"
          placeholder="주 증상을 입력하세요"
          handleClearValue={() => {
            setText("");
          }}
          value={text}
          onChange={(e) => {
            setText(e.target.value);
          }}
          css={{ width: "100%", height: "100px" }}
          containerStyles={{ width: "100%", height: "100px" }}
          ref={textAreaRef}
        />

        <div
          css={{ display: "flex", justifyContent: "center", marginTop: "12px" }}
        >
          <Button
            size="x-large"
            css={{ display: focus ? "none" : "flex", width: "397px" }}
            disabled={!text}
            onClick={handleConfirm}
          >
            확인
          </Button>
        </div>
      </div>
      <CustomInputModal
        retText={(recordText: string) => {
          setText(recordText);
        }}
        defaultText={""}
        isOpen={isCustomInput}
        toggleIsCustomInput={toggleIsCustomInput}
      />
    </NewLayout>
  );
};

export default ScreenAdditionalPainInput;
