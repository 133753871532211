import { useHumanBodyStore } from "@/features/humanBodyDiagram/context/useHumanBodyStore";
import { PAIN_POINT_CLASS_NAME } from "@/features/humanBodyDiagram/entities/constants";
import {
  body_foot_L,
  body_foot_R,
  body_hand_L,
  body_hand_R,
} from "@/features/humanBodyDiagram/entities/painAreaIds/bodyIds";
import {
  foot_L,
  foot_R,
} from "@/features/humanBodyDiagram/entities/painAreaIds/footIds";
import {
  hand_L,
  hand_R,
} from "@/features/humanBodyDiagram/entities/painAreaIds/handIds";
import type { SvgSelection } from "@/features/humanBodyDiagram/stores/humanBodySvgSlice";
import { useEffect } from "react";
import { DepartmentEnum } from "../entities/departmentMappings";

interface Props {
  painAreas: string[];
}

/**
 * 인체 부위의 통증 부위 표시 관리
 * - 전체 통증 부위 숨김(초기화)
 * - api로 응답받은 통증 부위 표시
 * */
export const useHumanBodyPains = ({ painAreas }: Props) => {
  const { selectedPainAreas, togglePainArea, svgSelection, department } =
    useHumanBodyStore((store) => ({
      svgSelection: store.svgSelection,
      selectedPainAreas: store.selectedPainAreas,
      togglePainArea: store.togglePainArea,
      department: store.department,
    }));

  useEffect(() => {
    // if (!svgSelection) return;
    setFirstClickEvent(department, togglePainArea, svgSelection);
  }, [svgSelection]);

  useEffect(() => {
    // if (!svgSelection) return;

    resetPainAreas(svgSelection);
    const connectedBodyPainAreas = connectBodyHandFootPainAreas([
      ...painAreas,
      ...selectedPainAreas,
    ]);
    showPainAreas(svgSelection, [
      ...selectedPainAreas,
      ...connectedBodyPainAreas,
    ]);
  }, [svgSelection, selectedPainAreas, painAreas]);
};

const setFirstClickEvent = (
  department: DepartmentEnum | null,
  togglePainArea: (painArea: string) => void,
  selection: SvgSelection,
) => {
  if (!selection) return;

  // 전신 전후면 손발 제외
  const excludeParts = [
    ...body_hand_R,
    ...body_hand_L,
    ...body_foot_R,
    ...body_foot_L,
  ];

  selection
    .selectAll(`.${PAIN_POINT_CLASS_NAME}`)
    .nodes()
    .forEach((node) => {
      const element = node as HTMLElement;
      element.addEventListener("click", () => {
        let bodyId = "";
        if (department === DepartmentEnum.SURGERY) {
          bodyId = element.parentNode.parentElement?.id;
        } else if (department === DepartmentEnum.INTERNAL) {
          bodyId =
            element.parentNode.parentElement?.parentElement?.parentElement?.id;
        } else if (department === DepartmentEnum.NEUROLOGY) {
          bodyId = element.parentNode?.parentElement?.id;
        } else if (department === DepartmentEnum.ENT) {
          bodyId = element.parentNode?.parentElement?.id;
        }
        if (!excludeParts.includes(bodyId)) {
          togglePainArea(bodyId);
        }
      });
    });
};

/**
 * 전체 통증 부위 숨김
 * */
const resetPainAreas = (selection: SvgSelection) => {
  if (!selection) return;
  selection.selectAll(`.${PAIN_POINT_CLASS_NAME}`).style("opacity", 0);
};

/**
 * api로 응답받은 통증 부위 표시
 * */
const showPainAreas = (selection: SvgSelection, painAreas: string[]) => {
  if (!selection) return;

  painAreas.forEach((id) => {
    selection
      .select(`#${id}`)
      .selectAll(`.${PAIN_POINT_CLASS_NAME}`)
      .style("opacity", 0.8);
  });
};

const painAreaMapping = [
  { source: hand_L, target: body_hand_L },
  { source: hand_R, target: body_hand_R },
  { source: foot_L, target: body_foot_L },
  { source: foot_R, target: body_foot_R },
] as const;

// 손, 발의 통증부위가 있을 경우 전면, 후면에서도 표시되도록
const connectBodyHandFootPainAreas = (painAreas: string[]) => {
  const idSet = painAreas.reduce((acc, painPoint) => {
    painAreaMapping.forEach(({ source, target }) => {
      if (source.includes(painPoint)) {
        target.forEach((id) => acc.add(id));
      }
    });
    return acc;
  }, new Set<string>());

  return Array.from(idSet);
};

const hidePainAreas = (selection: SvgSelection, painAreas: string[]) => {
  painAreas.forEach((id) => {
    selection.select(`#${id}`).style("opacity", "0");
  });
};
