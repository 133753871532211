/** @jsxImportSource @emotion/react */
"use client";
import styled from "@emotion/styled";
import { ButtonHTMLAttributes, CSSProperties } from "react";

export type ButtonVariant = "default" | "iconBtn" | "action";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string;
  variant?: ButtonVariant;
  src?: string;
  alt?: string;
  buttonStyle?: CSSProperties;
  placeholder?: "empty" | "blur" | undefined;
  pressedCss?: CSSProperties;
  labelStyle?: CSSProperties;
}

const BUTTON_VARIANT_STYLE: Record<ButtonVariant, CSSProperties> = {
  default: {},
  iconBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  action: {
    borderRadius: "50px",
    backgroundColor: "var(--business-color)",
    color: "var(--white001)",
    fontSize: "1.5rem",
    display: "flex",
    width: "264px",
    padding: "20px 32px",
    justifyContent: "center",
    alignItems: "center",
    gap: "16px",
    border: "3px solid #FFF",
  },
};

const StyledButton = styled.button<ButtonProps>(
  {
    border: 0,
    margin: 0,
    // padding: "8px 12px",
    borderRadius: "4px",
    cursor: "pointer",
  },
  (props) => [
    props.buttonStyle as Record<string, any>,
    {
      ":active": props.pressedCss,
      ...(props.disabled && {
        opacity: 0.5,
        cursor: "not-allowed",
      }),
    },
    BUTTON_VARIANT_STYLE[props.variant || "default"] as Record<string, any>,
  ]
);

const StyledImage = styled.img<{
  buttonStyle: Record<string, string | number>;
}>(
  {
    position: "relative",
    width: "16px",
    height: "16px",
    margin: "0 4px 0 0",
  },
  ({ buttonStyle }) => ({
    ...buttonStyle,
  })
);

/**
 *
 * @param src 해당 SRC는 variant가 꼭 iconBtn이여야 합니다.
 * @returns
 */

const Button = ({
  type = "button",
  label,
  variant = "default",
  src,
  alt = "",
  placeholder = "empty",
  buttonStyle = {},
  pressedCss = {},
  labelStyle = {},
  ...rest
}: ButtonProps) => (
  <StyledButton
    buttonStyle={buttonStyle}
    type={type}
    variant={variant}
    pressedCss={pressedCss}
    {...rest}
  >
    {variant === "iconBtn" && src && (
      <StyledImage
        src={src}
        alt={alt}
        aria-placeholder={placeholder}
        buttonStyle={buttonStyle as Record<string, string | number>}
      />
    )}
    <span style={labelStyle}>{label}</span>
  </StyledButton>
);

export default Button;
