export enum DesignSystemSize {
  XLARGE,
  LARGE,
  MEDIUM,
  SMALL,
  XSMALL,
}

export enum DesignSystemState {
  SELECTED,
  DEFAULT,
  FOCUS,
  DISABLED,
  FILLED,
  ERROR,
  ON,
  OFF,
}
