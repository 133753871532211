import { COLORS } from "vd-component";

interface PrograssBarProps {
  sequence: number;
}
const PrograssBar = ({ sequence }: PrograssBarProps) => {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        flexDirection: "column",
        padding: "0 40px",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "8px",
          display: "flex",
          borderRadius: "999px",
        }}
      >
        <div
          style={{
            width: `${sequence * 12.5}%`,
            background: COLORS.colorBackgroundWhite,
            height: "9px",
            borderRadius: "999px",
          }}
        />
        <div
          style={{
            width: `${(8 - sequence) * 12.5}%`,
            background: COLORS.colorOpacity16,
            height: "9px",
            backdropFilter: "blur(calc(12 / 2))",
            borderRadius: "999px",
          }}
        />
      </div>
    </div>
  );
};

export default PrograssBar;
