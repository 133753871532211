import { PatientsRepository } from "../repository/PatientsRepository";

export class PatientsService {
  repo: PatientsRepository;

  constructor(repo: PatientsRepository) {
    this.repo = repo;
  }

  postSearchPhone(phone: string): Promise<any> {
    return this.repo.postSearchPhone(phone);
  }
}
