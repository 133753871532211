import NewList from "@/components/common/List/NewList";
import { StyledComponentProps } from "@/type/StyledComponent";
import { t } from "i18next";
import { typography_style_body_md_medium } from "vd-component";

const DIRECTIONS = ["right", "left"] as const;
type Direction = (typeof DIRECTIONS)[number];

interface Props extends StyledComponentProps {
  key: string;
  value?: Direction | null;
  suffixLabel: string;
  onClick: (value: Direction) => void;
}
export const DirectionTab = ({
  key,
  value,
  suffixLabel,
  onClick,
  ...rest
}: Props) => {
  return (
    <NewList
      key={key}
      items={DIRECTIONS as unknown as string[]}
      itemTemplateProps={{
        itemContainerStyle: (item) => {
          return {
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderBottom:
              item == value
                ? "var(--Border-Radius-2, 2px) solid var(--color-inverse-background-line-nomal, #333)"
                : "var(--Border-Radius-1, 1px) solid var(--color-line-nomal, #DBDBDB)",
          };
        },
        itemTemplate: (data) => {
          return (
            <span css={typography_style_body_md_medium}>
              {t(`humanBody.${data.toLocaleLowerCase()}`)}
              {suffixLabel}
            </span>
          );
        },
        onItemClick: (item) => onClick(item as Direction),
      }}
      {...rest}
    />
  );
};
