import type { HumanBodySvgSlice } from "@/features/humanBodyDiagram/stores/humanBodySvgSlice";
import * as d3 from "d3";
import type { StateCreator } from "zustand";

interface ResetZoomOptions {
  duration?: number;
}

interface ZoomInOutOptions {
  duration?: number;
  scale?: number;
}

export interface HumanBodyZoomInitProps {
  disabledZoom?: boolean;
}

export interface HumanBodyZoomSlice {
  zoomControl: d3.ZoomBehavior<SVGSVGElement, unknown> | null;
  disabledZoom: boolean;
  setZoomControl: (
    zoomControl: d3.ZoomBehavior<SVGSVGElement, unknown>
  ) => void;
  clearZoom: () => void;
  resetZoom: (options?: ResetZoomOptions) => void;
  zoomIn: (options?: ZoomInOutOptions) => void;
  zoomOut: (options?: ZoomInOutOptions) => void;
}

export const humanBodyZoomSlice =
  ({
    disabledZoom = false,
  }: HumanBodyZoomInitProps): StateCreator<
    HumanBodyZoomSlice & HumanBodySvgSlice, // Note: HumanBodySvgSlice 스토어에 접근하기 위해 선언
    [],
    [],
    HumanBodyZoomSlice // Note: humanBodyZoomSlice의 역할을 다시 명시
  > =>
  (set, get) => ({
    zoomControl: null,
    disabledZoom: disabledZoom,

    setZoomControl: (zoomControl: d3.ZoomBehavior<SVGSVGElement, unknown>) => {
      set({ zoomControl });
    },

    // 줌 컨트롤을 초기화 하는 함수(드래그 위치, 줌 비율 초기화)
    clearZoom: () => {
      const { svgSelection, zoomControl } = get();

      if (svgSelection && zoomControl) {
        svgSelection.call(zoomControl.transform, d3.zoomIdentity);
      }
    },

    // 줌과 드래그를 원위치로 초기화하는 기능
    resetZoom: (options) => {
      const { zoomControl, svgSelection } = get(); // Note: zustand는 get을 이용해 다른 스토어에 접근할 수 있다.

      if (!svgSelection || !zoomControl) return;

      const selection = options?.duration
        ? svgSelection.transition().duration(options.duration)
        : svgSelection;

      selection.call(zoomControl.transform, d3.zoomIdentity);
    },

    zoomIn: (options) => {
      const DEFAULT_SCALE = 1.2;
      const { zoomControl, svgSelection } = get();

      if (!svgSelection || !zoomControl) return;

      const selection = options?.duration
        ? svgSelection.transition().duration(options.duration)
        : svgSelection;

      selection.call(zoomControl.scaleBy, options?.scale ?? DEFAULT_SCALE);
    },

    zoomOut: (options) => {
      const DEFAULT_SCALE = 0.8;
      const { zoomControl, svgSelection } = get();

      if (!svgSelection || !zoomControl) return;

      const selection = options?.duration
        ? svgSelection.transition().duration(options.duration)
        : svgSelection;

      selection.call(zoomControl.scaleBy, options?.scale ?? DEFAULT_SCALE);
    },
  });
