import { CancelIcon, CheckNormalIcon } from "@/theme/CustomIcons";
import { keyframes } from "@emotion/react";
import { useEffect, useMemo } from "react";
import { COLORS, SelectChip } from "vd-component";
import { AnswerState } from "../store/Phase3Hook";

const Phase3QuestionContainer = ({
  answerState,
  toggleAnswer,
  setDefaultText,
  setIsShowInput,
  defaultText,
  streamIsFinal,
}: {
  answerState: AnswerState[];
  toggleAnswer: Function;
  setDefaultText: Function;
  setIsShowInput: Function;
  defaultText: string;
  streamIsFinal: boolean;
}) => {
  const isEmptyAnswer = useMemo(() => {
    const result = answerState.find((answer) => {
      return answer.type === 2 && answer.checked;
    });
    return !!result;
  }, [answerState]);

  useEffect(() => {
    if (isEmptyAnswer) {
      setDefaultText(false);
    }
  }, [isEmptyAnswer]);

  const answerList = answerState.map((answer, idx) => {
    const isChecked = answer.checked;
    return (
      <SelectChip
        size="xlarge"
        variant="dark"
        key={`${answer.keyword}_${idx}`}
        css={{
          animationName: boxFadeIn,
          animationDuration: "0.4s",
          animationFillMode: "forwards",
          // animationDelay: `${((idx + 1) * 1) / 10}s`,
          opacity: 0,
          background: isChecked
            ? COLORS.colorBackgroundWhite
            : COLORS.colorOpacity40,
          ":hover": {
            background: isChecked
              ? COLORS.colorBackgroundWhite
              : COLORS.colorOpacity40,
          },
          ":active": {
            background: isChecked
              ? COLORS.colorBackgroundWhite
              : COLORS.colorOpacity40,
          },
        }}
        onClick={() => {
          toggleAnswer(idx, answerState[idx].type === 2);
        }}
      >
        <div
          css={{
            gap: "4px",
            display: "flex",
            alignItems: "center",
            animationDelay: `${idx}s`,
            color: isChecked
              ? COLORS.colorLabelNomal
              : COLORS.colorInverseLabelLight,
          }}
        >
          <CheckNormalIcon />
          <span>{answer.keyword}</span>
        </div>
      </SelectChip>
    );
  });

  // 직접입력
  if (answerState?.length > 0 && streamIsFinal) {
    answerList.push(
      <>
        <SelectChip
          size="xlarge"
          variant="dark"
          key="직접입력"
          css={{
            animationName: boxFadeIn,
            animationDuration: "0.4s",
            animationFillMode: "forwards",
            opacity: 0,
            background: defaultText
              ? COLORS.colorBackgroundWhite
              : COLORS.colorOpacity40,
            ":hover": {
              background: defaultText
                ? COLORS.colorBackgroundWhite
                : COLORS.colorOpacity40,
            },
            ":active": {
              background: defaultText
                ? COLORS.colorBackgroundWhite
                : COLORS.colorOpacity40,
            },
          }}
          onClick={() => {
            // toggleAnswer(idx, answerState[idx].type === 2);
            setIsShowInput(true);
          }}
        >
          <div
            css={{
              gap: "4px",
              display: "flex",
              alignItems: "center",
              animationDelay: `${
                answerState?.length == undefined ? 100 : answerState?.length
              }s`,
              color: defaultText
                ? COLORS.colorLabelNomal
                : COLORS.colorInverseLabelLight,
            }}
          >
            <CheckNormalIcon />
            <span
              css={{
                maxWidth: "200px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {defaultText || "직접입력"}
            </span>
          </div>
          {defaultText && (
            <CancelIcon
              css={{ color: COLORS.colorLabelAlternative }}
              onClick={(e) => {
                e.stopPropagation();
                setDefaultText("");
              }}
            />
          )}
        </SelectChip>
      </>,
    );
  }

  return (
    <div css={{ display: "flex", gap: "16px", flexWrap: "wrap" }}>
      {answerList}
    </div>
  );
};

export default Phase3QuestionContainer;

const boxFadeIn = keyframes({
  "0%": {
    opacity: 0,
  },
  "100%": {
    opacity: 1,
  },
});
