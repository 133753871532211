import StorageManager from "@/common/services/StorageManager";
import { usePatientDataStore } from "@/features/Screen/store/ScreenStore";
import { makePatientData } from "@/utils/PatientUtils";
import ArrowLeft from "@assets/icons/arrow-left-label-light.svg";
import styled from "@emotion/styled";
import { t } from "i18next";
import {
  Button,
  typography_style_body_md_medium,
  typography_style_heading_sm_medium,
} from "vd-component";

export const GrayLayout = styled.div({
  width: "100%",
  height: "100%",
  background: "var(--color-background-light, #F1F2F3)",
});

export const Header = ({
  backFunc = () => {},
  backText = t("common.goBack"),
  data = false,
}: {
  backFunc: Function;
  data: boolean;
  backText?: string;
}) => {
  const patientStore = usePatientDataStore.getState();
  const hospital = StorageManager.getDataNow("hospital");
  return (
    <div
      style={{
        padding: "12px 40px",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Button
        onClick={() => backFunc()}
        css={{
          borderRadius: "var(--Border-Radius-999, 999px)",
        }}
        size="medium"
        buttonType="common"
        variant="secondary-line"
      >
        <div style={{ display: "flex", gap: "4px" }}>
          <img src={ArrowLeft} />
          <span css={{ ...typography_style_body_md_medium }}>{backText}</span>
        </div>
      </Button>
      <div
        css={{
          display: "flex",
          alignItems: "center",
          gap: "12px",
        }}
      >
        {data && (
          <div css={{ display: "flex", alignItems: "center", gap: "6px" }}>
            <span css={{ ...typography_style_heading_sm_medium }}>
              {patientStore.name}
            </span>
            <div>
              <span css={{ ...typography_style_heading_sm_medium }}>
                {makePatientData(
                  patientStore.birth,
                  patientStore.identityGender,
                )}
              </span>
            </div>
          </div>
        )}
        <Button
          disabled={true}
          style={{
            opacity: 1,
            borderRadius: "var(--Border-Radius-999, 999px)",
          }}
          size="medium"
          buttonType="common"
          variant="secondary-line"
        >
          <div
            css={{
              display: "flex",
              gap: "4px",
              ...typography_style_body_md_medium,
            }}
          >
            {hospital}
          </div>
        </Button>
      </div>
    </div>
  );
};
