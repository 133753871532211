import { PatientSizeType } from "@/features/Reception/store/ReceptionPatientSizeHook";
import { RemoveIcon } from "@/theme/CustomIcons";
import { COLORS } from "vd-component";
import { SquareButton } from "../common/buttons";

const inputButtonStyle = {
  width: "30%",
  height: "84px",

  background: COLORS.colorBackgroundWhite,
  color: COLORS.colorLabelNomal,
  boxShadow:
    "0 0 4px 0 rgba(0, 0, 0, 0.08), 0 0 4px 0 rgba(0, 0, 0, 0.10), 0 0 16px 0 rgba(0, 0, 0, 0.10)",
  padding: "16px 0",
  ":active": {
    background: COLORS.colorBackgroundLight,
    boxShadow: "0 0 0 4px rgba(0, 0, 0, 0.08)",
  },
  aspectRatio: "0.5 / 1",
  borderRadius: "8px",
};

interface KeypadSize10NumProps {
  patientSize: PatientSizeType;
}
const KeypadSize10Num = ({ patientSize }: KeypadSize10NumProps) => {
  const { bodySize, bodyFocus, setBodySize, keypadRef, error, setError } =
    patientSize;
  const inputButtonLabel = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  const handleInput = (num: string) => {
    if (!bodyFocus) return;

    if (num === "clear") return setBodySize({ weight: "", height: "" });

    if (bodyFocus === "height") {
      setError((prev) => ({ ...prev, height: false }));

      if (
        (num === "." &&
          (bodySize.height.includes(".") || bodySize.height === "")) ||
        Number(bodySize.height + num) >= 270 ||
        (bodySize.height.length === 0 && num === "0")
      )
        return;
      setBodySize((prev) => ({
        ...prev,
        height: `${prev.height}${num}`,
      }));
      return;
    }
    setError((prev) => ({ ...prev, weight: false }));
    if (
      (num === "." &&
        (bodySize.weight.includes(".") || bodySize.weight === "")) ||
      Number(bodySize.weight + num) >= 400 ||
      (bodySize.weight.length === 0 && num === "0")
    )
      return;
    setBodySize((prev) => ({ ...prev, weight: `${prev.weight}${num}` }));
  };

  const handleRemoveText = () => {
    if (!bodyFocus) return;
    if (bodyFocus === "height") {
      setBodySize((prev) => ({ ...prev, height: prev.height.slice(0, -1) }));
      return;
    }
    setBodySize((prev) => ({ ...prev, weight: prev.weight.slice(0, -1) }));
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "12px",
          justifyContent: "space-between",
          // marginBottom: "10%",
        }}
        ref={keypadRef}
      >
        {inputButtonLabel.map((v, k) => {
          return (
            <SquareButton
              label={`${v}`}
              css={{ ...inputButtonStyle }}
              buttonType={"free"}
              key={k}
              onClick={() => {
                handleInput(String(v));
              }}
              labelStyle={{
                userSelect: "none",
                fontWeight: "500",
                fontSize: "41.99px",
              }}
            />
          );
        })}
        <SquareButton
          onClick={() => {
            handleInput("clear");
          }}
          label="전체지움"
          css={{
            ...inputButtonStyle,
          }}
          buttonType={"cancel"}
          labelStyle={{
            fontWeight: "400",
            fontSize: "17.765px",
          }}
        />
        <SquareButton
          onClick={() => {
            handleInput("0");
          }}
          label="0"
          css={{ ...inputButtonStyle }}
          buttonType={"free"}
          labelStyle={{
            userSelect: "none",
            fontWeight: "500",
            fontSize: "41.99px",
          }}
        />
        <div
          css={{
            ...inputButtonStyle,
            // background: "var(--btn_g002)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={handleRemoveText}
        >
          {/* <img src={RemoveIcon} /> */}
          <RemoveIcon width="36px" height="40px" />
        </div>
      </div>
    </>
  );
};

export default KeypadSize10Num;
