import {
  GetPain,
  PhaseAnswer,
  PhaseCheckResponse,
  ScreenQuestion,
  ShortCut,
  ShortCutAnswer,
} from "@network-module/entities/CheckupEntityNew";
import { Phase1RequestEntity } from "@network-module/entities/Phase1RequestEntity";
import { Phase2RequestEntity } from "@network-module/entities/Phase2RequestEntity";
import { BaseRepository } from "./BaseRepository";

export class CheckupRepository extends BaseRepository {
  async start(checkupId: number) {
    try {
      const response = await this.api.post("/checkup", { checkupId });
      return response;
    } catch (error) {
      return error;
    }
  }

  kioskReset(checkUpId: Number) {
    return this.api.put(`/hospital/kiosk/reset/${checkUpId}`, {});
  }

  async kioskAllocate(checkUpId: Number, doctorId?: number) {
    const response = await this.api.post(`/hospital/kiosk/allocate`, {
      checkUpId,
      doctorId: doctorId ?? null,
    });
    return response;
  }

  submitOne(phase1Request: Phase1RequestEntity): Promise<boolean> {
    return this.api.post(`/checkup/phase/one`, phase1Request);
  }

  submitTwo(phase2Request: Phase2RequestEntity): Promise<boolean> {
    return this.api.post(`/checkup/phase/two`, phase2Request);
  }

  phaseCheck(data: PhaseAnswer): Promise<PhaseCheckResponse> {
    return this.api.post("/checkup/phase/check", data);
  }

  checkupStartOrEnd(checkupId: number) {
    return this.api.get(`/hospital/kiosk/start-end`, {
      params: { checkUpId: checkupId },
    });
  }

  getShortCut(departmentId: number | string): Promise<ShortCut[]> {
    return this.api.get(`/checkup/ask-short-cut?departmentId=${departmentId}`);
  }

  saveAsk(shortCutAnswer: ShortCutAnswer): Promise<any> {
    return this.api.post(`/checkup/ask`, shortCutAnswer);
  }

  getSickWhere(departmentId: number, signal: AbortSignal): Promise<GetPain> {
    return this.api.get(
      `/checkup/screening/main-symptom?departmentId=${departmentId}`,
      { signal },
    );
  }

  getSickPeriod(departmentId: number): Promise<GetPain> {
    return this.api.get(
      `/checkup/screening/symptom-history?departmentId=${departmentId}`,
    );
  }

  getCheckupScreen(
    departmentId: number,
    signal: AbortSignal,
  ): Promise<ScreenQuestion[]> {
    return this.api.get(`/checkup/screening?departmentId=${departmentId}`, {
      signal,
    });
  }

  getElapse(departmentId: number, signal: AbortSignal): Promise<GetPain> {
    return this.api.get(
      `/checkup/screening/elapse?departmentId=${departmentId}`,
      {
        signal,
      },
    );
  }

  getAdditionalPain(
    departmentId: number,
    signal: AbortSignal,
  ): Promise<GetPain> {
    return this.api.get(
      `/checkup/screening/additional-pain?departmentId=${departmentId}`,
      {
        signal,
      },
    );
  }

  saveReuse(data: any): Promise<any> {
    return this.api.post(`/checkup/phase/one/reuse`, data);
  }
}
