import { HighLightComplex } from "@/components/common/HighLight";
import { TabletButtonXL } from "@/components/DesignSystem/Button/TabletButton";
import { useHumanBodyStore } from "@/features/humanBodyDiagram/context/useHumanBodyStore";
import { DepartmentEnum } from "@/features/humanBodyDiagram/entities/departmentMappings";
import styled from "@emotion/styled";
import {
  typography_style_heading_lg_semibold,
  typography_style_heading_sm_medium,
} from "vd-component";

const Container = styled.div({
  width: "100%",
  padding: "0px 36px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
});

const ButtonContainer = styled.div({
  display: "flex",
  gap: "12px",
  width: "100%",
  marginTop: "48px",
});

const ScreenSelectBodyButtonContainer = ({
  department,
  setBodyData,
}: {
  department: DepartmentEnum;
  setBodyData: (data: string[]) => void;
}) => {
  const { selectedPainAreas } = useHumanBodyStore((store) => ({
    selectedPainAreas: store.selectedPainAreas,
  }));
  return (
    <Container>
      <div>
        <span
          css={{
            fontSize: "32px",
            letterSpacing: "-0.8px",
            fontWeight: "600",
          }}
        >
          <HighLightComplex
            hlArr={["아픈 부위"]}
            hlStr="{0}를 모두 선택해 주세요."
          />
        </span>
      </div>
      <span css={{ ...typography_style_heading_sm_medium }}>
        아픈 부분을 여러개 선택할 수 있습니다.
      </span>

      <ButtonContainer>
        {department == DepartmentEnum.INTERNAL && (
          <TabletButtonXL
            css={{
              flex: 1,
            }}
            size="large"
            buttonType="common"
            variant="secondary-line"
            onClick={() => {
              setBodyData([]);
            }}
          >
            <span css={{ ...typography_style_heading_lg_semibold }}>
              건너뛰기
            </span>
          </TabletButtonXL>
        )}
        <TabletButtonXL
          disabled={Array.from(selectedPainAreas).length === 0}
          css={{
            flex: 1,
          }}
          size="large"
          buttonType="common"
          variant="primary"
          onClick={() => {
            setBodyData(Array.from(selectedPainAreas));
          }}
        >
          <span css={{ ...typography_style_heading_lg_semibold }}>확인</span>
        </TabletButtonXL>
      </ButtonContainer>
    </Container>
  );
};

export default ScreenSelectBodyButtonContainer;
