import { CustomInputModal } from "@/features/phase3/components/CustomInputModal";
import { usePhase3Hook1 } from "@/features/phase3/store/Phase3Hook1";
import { ScreenQuestion } from "@/network-module/entities/CheckupEntityNew";
import {
  CancelIcon,
  CheckNormalIcon,
  MicrophoneIcon,
  NumbercircleFourIcon,
  NumbercircleOneIcon,
  NumbercircleThrIcon,
  NumbercircleTwoIcon,
  NumberingLineIcon,
  SearchIcon,
} from "@/theme/CustomIcons";
import styled from "@emotion/styled";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  COLORS,
  IconBadge,
  SelectChip,
  TextArea,
  Title,
  typography_style_heading_lg_bold,
  typography_style_heading_lg_semibold,
  typography_style_heading_md_semilbold,
} from "vd-component";
import { DisplayType } from "./ScreenDetail.hook";

import { Header } from "@/components/layout/Layouts";
import { t } from "i18next";
import "regenerator-runtime";
import "regenerator-runtime/runtime";
import { usePatientDataStore } from "../store/ScreenStore";

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: ${COLORS.colorBackgroundLight};
`;
const Footer = styled.div`
  position: fixed;
  bottom: 0;
  padding: 24px 40px;
  display: flex;
  width: 100%;
  background: ${COLORS.colorBackgroundWhite};
  justify-content: space-between;
  border-top: var(--Border-Radius-1, 1px) solid var(--color-line-nomal, #c2c4c8);
`;
const IndexContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

//두개가 정상적으로 나오는 케이스
const QuestionContainerCase1 = styled.div`
  padding: 0px 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: flex-start;
  height: calc(100% - 187px);
  overflow-y: auto;
`;

//한개는 가려진채로 나오는 케이스
const QuestionContainerCase2 = styled.div`
  padding: 0px 40px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 187px);
  overflow-y: auto;
`;

//하나만 나오는 케이스
const QuestionContainerCase3 = styled.div`
  padding: 0px 40px;
  display: flex;
  height: calc(100% - 187px);
  overflow-y: auto;
`;

const PrimaryQuestionContainer = styled.div`
  width: 100%;
  justify-content: space-between;
  gap: 40px;
  display: flex;
  padding: 32px 32px;
  border-radius: 12px;
  background: ${COLORS.colorBackgroundWhite};
  box-shadow:
    0px 0px 1px 0px rgba(0, 0, 0, 0.08),
    0px 0px 1px 0px rgba(0, 0, 0, 0.08),
    0px 1px 1px 0px rgba(0, 0, 0, 0.12);
`;

const QuestionTitleContent = styled.span``;
const QuestionTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  justify-content: center;
`;

const QuestionNumber = styled.span`
  color: ${COLORS.colorPrimaryNomal};
  ${typography_style_heading_lg_bold}
`;
const QuestionTitle = styled.span`
  ${typography_style_heading_lg_semibold}
`;
const QuestionAnswerContainer = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: row;
  flex-wrap: wrap;
`;

const AdditionalQuestionTitleContainer = styled.div`
  margin: 20px 0px 12px 0px;
  display: flex;
  align-items: center;
  gap: 4px;
`;

const SubQuestionContainer = styled.div`
  display: flex;
`;

const NameTitle = styled.span`
  ${typography_style_heading_md_semilbold}
  color: ${COLORS.colorPrimaryNomal};
`;
const AdditionalQuestionTitle = styled.span`
  ${typography_style_heading_md_semilbold}
  color: ${COLORS.colorLabelNomal};
`;

const IndexNumberingIcon = [
  <NumbercircleOneIcon />,
  <NumbercircleTwoIcon />,
  <NumbercircleThrIcon />,
  <NumbercircleFourIcon />,
];

const IndexContent = ({
  maxIndex,
  currentIndex,
}: {
  maxIndex: number;
  currentIndex: number;
}) => {
  return Array.from({ length: maxIndex }, (_, i) => {
    let updatedIcon = React.cloneElement(IndexNumberingIcon[i], {
      key: `icon-${i}`,
      css: {
        color:
          i <= currentIndex
            ? COLORS.colorPrimaryNomal
            : COLORS.colorLabelDisable,
      },
    });

    let numberingIcon = <></>;
    if (i < maxIndex - 1) {
      numberingIcon = React.cloneElement(<NumberingLineIcon />, {
        key: `line-${i}`,
        css: {
          color:
            i <= currentIndex - 1
              ? COLORS.colorPrimaryNomal
              : COLORS.colorLabelDisable,
        },
      });
    }
    return (
      <>
        {updatedIcon}
        {numberingIcon}
      </>
    );
  });
};

const QuestionContent = ({
  number = 0,
  numbering,
  question,
  checkAnswer,
  toggleAnswer,
  customAnswer,
  setToggledCustomAnswer,
  unSetCustomAnswer,
}: {
  number?: number;
  numbering: boolean;
  question: ScreenQuestion;
  checkAnswer: (questionId: number, answerId: number) => boolean;
  toggleAnswer: (
    questionId: number,
    answerId: number,
    multiAnswer: boolean,
  ) => void;
  customAnswer?: string[];
  setToggledCustomAnswer?: (toggledCustomAnswer: number) => void;
  unSetCustomAnswer?: (questionId: number) => void;
}) => {
  const checkMultiAnswer = (multiAnswer: boolean, type: number) => {
    if (type == 2) {
      return false;
    }
    return multiAnswer;
  };
  return (
    <>
      <QuestionTitleContainer
        css={{
          width: question.multiAnswer ? "" : "45%",
        }}
      >
        <QuestionTitleContent>
          {numbering && <QuestionNumber>0{number + " "}</QuestionNumber>}
          <QuestionTitle>{question.question}</QuestionTitle>
        </QuestionTitleContent>
        {question.multiAnswer && (
          <IconBadge variant="primary" size="x-large">
            중복선택 가능
          </IconBadge>
        )}
      </QuestionTitleContainer>
      <QuestionAnswerContainer
        css={{
          width: question.multiAnswer ? "" : "55%",
        }}
      >
        {question.screeningAnswer.map((answer) => {
          const answerContent = () => {
            if (answer.type == 1) {
              if (
                customAnswer?.[question.id] != undefined &&
                customAnswer[question.id] != ""
              ) {
                if (customAnswer[question.id].length > 4) {
                  return customAnswer[question.id].slice(0, 4) + "...";
                }
                return customAnswer[question.id];
              }
            }
            return answer.content;
          };
          return (
            <SelectChip
              chipType={
                checkAnswer(question.id, answer.id) ? "selected" : "unselected"
              }
              onClick={() => {
                if (answer.type == 1) {
                  setToggledCustomAnswer(question.id);
                  return;
                }
                toggleAnswer(
                  question.id,
                  answer.id,
                  checkMultiAnswer(question.multiAnswer, answer.type),
                );
              }}
              css={
                question.multiAnswer
                  ? {
                      borderRadius: "999px",
                    }
                  : {
                      minWidth: "fit-content",
                      borderRadius: "8px",
                      justifyContent: "center",
                      flex: 1,
                    }
              }
              size="large"
              variant="secondary"
              leftIcon={
                question.multiAnswer ? (
                  <CheckNormalIcon
                    color={
                      checkAnswer(question.id, answer.id)
                        ? COLORS.colorInverseLabelLight
                        : COLORS.colorLabelNomal
                    }
                  />
                ) : undefined
              }
              key={answer.id}
            >
              {answerContent()}
              {answer.type == 1 &&
              customAnswer?.[question.id] != undefined &&
              customAnswer[question.id] != "" ? (
                <CancelIcon
                  css={{ color: COLORS.colorLabelAlternative }}
                  onClick={(e) => {
                    e.stopPropagation();
                    unSetCustomAnswer(question.id);

                    toggleAnswer(
                      question.id,
                      answer.id,
                      checkMultiAnswer(question.multiAnswer, answer.type),
                    );
                  }}
                />
              ) : undefined}
            </SelectChip>
          );
        })}
      </QuestionAnswerContainer>
    </>
  );
};

const QuestionContainerDisplay = ({
  checkAnswer,
  toggleAnswer,
  currentIndex,
  mainDisplayQuestion,
  subDisplayQuestion,
  displayType,
  questionListById,
  customAnswer,
  setToggledCustomAnswer,
  unSetCustomAnswer,
}: {
  checkAnswer: (questionId: number, answerId: number) => boolean;
  toggleAnswer: (
    questionId: number,
    answerId: number,
    multiAnswer: boolean,
  ) => void;
  currentIndex: number;
  mainDisplayQuestion: ScreenQuestion[][];
  subDisplayQuestion: Set<number>[];
  displayType: DisplayType;
  questionListById: ScreenQuestion[];
  customAnswer: string[];
  setToggledCustomAnswer: (toggledCustomAnswer: number) => void;
  unSetCustomAnswer: (questionId: number) => void;
}) => {
  const renderQuestion = () => {
    return (
      <>
        {mainDisplayQuestion[currentIndex] &&
          mainDisplayQuestion[currentIndex].map((question, qIndex) => {
            const questionNumber =
              mainDisplayQuestion.slice(0, currentIndex).flat().length +
              qIndex +
              1;
            return (
              <PrimaryQuestionContainer
                key={question.id}
                css={
                  displayType === DisplayType.CASE3
                    ? {
                        gap: "28px",
                        height: "fit-content",
                        justifyContent: "none",
                        flexDirection: "column",
                      }
                    : {}
                }
              >
                <QuestionContent
                  setToggledCustomAnswer={setToggledCustomAnswer}
                  number={questionNumber}
                  numbering={true}
                  question={question}
                  checkAnswer={checkAnswer}
                  toggleAnswer={toggleAnswer}
                  customAnswer={customAnswer}
                  unSetCustomAnswer={unSetCustomAnswer}
                />
              </PrimaryQuestionContainer>
            );
          })}
      </>
    );
  };

  const renderSubQuestion = () => {
    const patientStore = usePatientDataStore.getState();
    return (
      <>
        <AdditionalQuestionTitleContainer>
          <SearchIcon />
          <span>
            <NameTitle>{patientStore.name + "님 "}</NameTitle>
            <AdditionalQuestionTitle>
              더 정확한 진료를 위해서 음주와 관련된 질문을 몇가지 더 물어볼게요!
            </AdditionalQuestionTitle>
          </span>
        </AdditionalQuestionTitleContainer>
        <PrimaryQuestionContainer
          css={{
            flexDirection: "column",
            gap: "20px",
          }}
        >
          {[...subDisplayQuestion[currentIndex]].map((qid) => {
            const question = questionListById[qid];
            return (
              <SubQuestionContainer>
                <QuestionContent
                  numbering={false}
                  question={question}
                  checkAnswer={checkAnswer}
                  toggleAnswer={toggleAnswer}
                />
              </SubQuestionContainer>
            );
          })}
        </PrimaryQuestionContainer>
      </>
    );
  };

  if (displayType === DisplayType.CASE1) {
    return <QuestionContainerCase1>{renderQuestion()}</QuestionContainerCase1>;
  } else if (displayType === DisplayType.CASE2) {
    return (
      <QuestionContainerCase2>
        {renderQuestion()}
        {subDisplayQuestion[currentIndex] != undefined &&
          subDisplayQuestion[currentIndex].size > 0 &&
          renderSubQuestion()}
      </QuestionContainerCase2>
    );
  } else if (displayType === DisplayType.CASE3) {
    return <QuestionContainerCase3>{renderQuestion()}</QuestionContainerCase3>;
  } else {
    return <></>;
  }
};

const QuestionCustomTextArea = ({
  questionTitle,
  customInputText,
  setCustomInputText,
  setToggledCustomAnswer,
}: {
  questionTitle: string;
  customInputText: string;
  setCustomInputText: (customInputText: string) => void;
  setToggledCustomAnswer: (toggledCustomAnswer: number) => void;
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLDivElement>(null);
  const recordRef = useRef<HTMLDivElement>(null);
  const boxRef = useRef<HTMLDivElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [text, setText] = useState(customInputText || "");
  const [focus, setFocus] = useState<boolean>(true);

  const { isCustomInput, toggleIsCustomInput } = usePhase3Hook1(1);

  const handleConfirm = () => {
    setCustomInputText(text);
    setToggledCustomAnswer(0);
  };

  useEffect(() => {
    const textArea = document.getElementById("custom-textarea");
    if (textArea) {
      textArea.focus();
    }
    textArea?.addEventListener("focus", () => {
      setFocus(true);
    });
    textArea?.addEventListener("blur", () => {
      setTimeout(() => {
        setFocus(false);
      }, 100);
    });
  }, []);

  return (
    <div
      css={{
        display: "block",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 2,
        background: COLORS.colorBackgroundLight,
      }}
    >
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          zIndex: 2,
          height: "100%",
          position: "relative",
        }}
      >
        <Header
          data={true}
          backText={t("common.goBack")}
          backFunc={() => {
            setToggledCustomAnswer(0);
          }}
        />
        <div
          css={{
            padding: "40px",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",

            alignItems: "center",
          }}
          ref={containerRef}
        >
          <PrimaryQuestionContainer
            css={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "24px",
              width: "100%",
              justifyContent: "center",
            }}
            ref={boxRef}
          >
            <div
              css={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "60px",
              }}
              ref={titleRef}
            >
              <Title
                size="x-large"
                title={questionTitle}
                css={{ color: COLORS.colorLabelNomal }}
              />
              <div css={{ display: "flex", gap: 12 }}>
                <Button
                  variant="secondary"
                  size="large"
                  css={{
                    gap: "4px",
                    borderRadius: "999px",
                  }}
                  leftIcon={
                    <MicrophoneIcon color={COLORS.colorLabelAlternative} />
                  }
                  onClick={() => {
                    setFocus(false);
                    toggleIsCustomInput();
                  }}
                >
                  <span>음성 입력</span>
                </Button>
                <Button
                  size="large"
                  css={{
                    display: focus ? "flex" : "none",
                    borderRadius: "999px",
                  }}
                  onClick={handleConfirm}
                  disabled={!text || text.trim() === ""}
                >
                  <span>확인</span>
                </Button>
              </div>
            </div>

            <TextArea
              id="custom-textarea"
              size="x-large"
              placeholder="직접 입력해주세요."
              handleClearValue={() => {
                setText("");
              }}
              autoFocus
              onFocus={() => {
                setFocus(true);
              }}
              value={text}
              onChange={(e) => {
                const value = e.target.value;
                if (value === " ") return;
                setText(value);
              }}
              css={{ width: "100%", height: "100px", padding: "16px" }}
              containerStyles={{ width: "100%", height: "100px" }}
              isShowMaxLength={false}
              maxLength={undefined}
              ref={textareaRef}
            />

            <div
              css={{
                display: focus ? "none" : "flex",
                justifyContent: "center",
                marginTop: "12px",
              }}
            >
              <Button
                size="x-large"
                css={{ width: "397px" }}
                disabled={!text || text.trim() === ""}
                onClick={handleConfirm}
              >
                확인
              </Button>
            </div>
          </PrimaryQuestionContainer>
        </div>
        <div>
          <CustomInputModal
            retText={(recordText: string) => {
              setText(recordText);
            }}
            defaultText={""}
            isOpen={isCustomInput}
            toggleIsCustomInput={toggleIsCustomInput}
            ref={recordRef}
          />
        </div>
      </div>
    </div>
  );
};

export {
  Container,
  Footer,
  IndexContainer,
  IndexContent,
  PrimaryQuestionContainer,
  QuestionAnswerContainer,
  QuestionContainerCase1,
  QuestionContainerDisplay,
  QuestionCustomTextArea,
  QuestionNumber,
  QuestionTitle,
  QuestionTitleContainer,
};
