import {
  BodyMainCategoryEnum,
  BodySubCategoryEnum,
  FootSubCategoryEnum,
  HandSubCategoryEnum,
  PartDirectionEnum,
  type SubCategoryEnum,
} from "@/features/humanBodyDiagram/entities/categories";
import { getCategoryGroupKey } from "@/features/humanBodyDiagram/helper/getCategoryGroupKey";
import { createSelector } from "reselect";
import type { StateCreator } from "zustand";

// selector 사용으로 최적화(의존 상태 변화시에만 로직 수행)
const groupKeySelector = createSelector(
  (state: HumanBodyPartsSlice) => state.mainCategory,
  (state: HumanBodyPartsSlice) => state.subCategory,
  (state: HumanBodyPartsSlice) => state.direction,
  (mainCategory, subCategory, direction) => {
    return getCategoryGroupKey({
      mainCategory,
      subCategory,
      direction,
    });
  }
);

export type MainCategory =
  (typeof BodyMainCategoryEnum)[keyof typeof BodyMainCategoryEnum];
export type SubCategory =
  (typeof SubCategoryEnum)[keyof typeof SubCategoryEnum];
export type Direction =
  (typeof PartDirectionEnum)[keyof typeof PartDirectionEnum];

export interface HumanBodyPartsSlice {
  mainCategory: MainCategory;
  subCategory: SubCategory;
  direction: Direction | null;
  handleChangeMainCategory: (mainCategory: MainCategory) => void;
  setSubCategory: (subCategory: SubCategory) => void;
  setDirection: (direction: Direction) => void;
  getSelectedGroupKey: () => ReturnType<typeof getCategoryGroupKey>;
}

export const humanBodyPartsSlice: StateCreator<HumanBodyPartsSlice> = (
  set,
  get
) => ({
  mainCategory: "body",
  subCategory: "",
  direction: null,

  // mainCategory가 변경되면 다른 카테고리의 값을 초기화한다.
  handleChangeMainCategory: (mainCategory) => {
    const subCategoryInitialValue = getSubCategoryInitialValue(mainCategory);
    const { direction } = get();
    const directionInitialValue =
      mainCategory === BodyMainCategoryEnum.BODY
        ? null
        : direction ?? PartDirectionEnum.RIGHT;

    set({
      mainCategory: mainCategory,
      subCategory: subCategoryInitialValue,
      direction: directionInitialValue,
    });
  },

  setSubCategory: (subCategory) => {
    set({ subCategory: subCategory });
  },
  setDirection: (direction) => {
    set({ direction: direction });
  },

  getSelectedGroupKey: () => groupKeySelector(get()),
});

// 유틸리티 함수

// mainCategory에 따라 초기화되는 subCategory 값을 반환한다.
const getSubCategoryInitialValue = (
  mainCategory: MainCategory
): SubCategory => {
  switch (mainCategory) {
    case BodyMainCategoryEnum.EHEAD:
      return BodySubCategoryEnum.LEFT;
    case BodyMainCategoryEnum.NHEAD:
      return BodySubCategoryEnum.BACK;
    case BodyMainCategoryEnum.BODY:
      return BodySubCategoryEnum.FRONT;
    case BodyMainCategoryEnum.HAND:
      return HandSubCategoryEnum.IN;
    case BodyMainCategoryEnum.FOOT:
      return FootSubCategoryEnum.TOP;
  }
};
