import * as d3 from "d3";
import type { StateCreator } from "zustand";

export type SvgSelection = d3.Selection<
  SVGSVGElement,
  unknown,
  null,
  undefined
>;
export type GSelection = d3.Selection<SVGGElement, unknown, null, undefined>;

export interface HumanBodySvgSlice {
  svgSelection: SvgSelection | null;
  gSelection: GSelection | null;

  setSvgSelection: (svgSelection: SvgSelection) => void;
  setGSelection: (gSelection: GSelection) => void;
}

export const humanBodySvgSlice: StateCreator<HumanBodySvgSlice> = (set) => ({
  svgSelection: null,
  gSelection: null,

  setSvgSelection: (svgSelection: SvgSelection) => {
    set({ svgSelection });
  },

  setGSelection: (gSelection: GSelection) => {
    set({ gSelection });
  },
});
