import { InputType } from "@/common/utils/Type";
import KeypadSize10Num from "@/components/common/KeypadSize10Num";
import PersonalDataInput from "@/components/kiosk/PersonalDataInput";
import { DepartmentRecord } from "@/features/humanBodyDiagram/entities/departmentMappings";
import { ScreenPainScore } from "@/features/PainScore/components/PainScore";
import ScreenAIQuestionPage from "@/features/phase3/components/ScreenAIQuestionPage";
import { BodyInputContent } from "@/features/Reception/components/Reception.style";
import ReceptionPatientSizeHook from "@/features/Reception/store/ReceptionPatientSizeHook";
import ScreenDetail from "@/features/Screen/ScreenDetail/ScreenDetail";
import ScreenSelectMainSymptom from "@/features/Screen/ScreenSelectMainSymptom";
import {
  PatientDataStore,
  usePatientDataStore,
  usePatientStore,
} from "@/features/Screen/store/ScreenStore";
import { NetworkManager } from "@/network-module/NetworkManager";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ScreenCompleteWait from "./ScreenCompleteWait";
import ScreenSelectBodyNew from "./ScreenSelectBodyNew";
import ScreenSelectSickPeriod from "./ScreenSelectSickPeriod";
import ScreenSurvey from "./ScreenSurvey";

type SubmitType = {
  checkUpId: number;
  painArea: string[];
  painScore: string[];
  mainSymptom: {
    id: number;
    title: string;
    question: string;
    answers: {
      id: number;
      content: string;
    }[];
  };
  symptomHistory: {
    id: number;
    title: string;
    question: string;
    answers: { id: number; content: string }[];
  };
  screenAnswerList: {
    id: number;
    title: string;
    question: string;
    answers: {
      id: number;
      content: string;
    }[];
  };
  weight: string;
  height: string;
};

const ScreenUsePage = ({ patientData }: { patientData: PatientDataStore }) => {
  const navigate = useNavigate();
  const patientStore = usePatientStore();
  const patientDataStore = usePatientDataStore();
  const patientSize = ReceptionPatientSizeHook();
  const [entHeadache, setEntHeadache] = useState(false);
  const [submitPatientData, setSubmitPatientData] = useState<SubmitType>({
    checkUpId: patientData.checkupId,
    painArea: [],
    painScore: [],
    mainSymptom: {
      id: 0,
      title: "",
      question: "",
      answers: [],
    },
    symptomHistory: {
      id: 0,
      title: "",
      question: "",
      answers: [],
    },
    screenAnswerList: {
      id: 0,
      title: "",
      question: "",
      answers: [],
    },
    weight: "",
    height: "",
  });

  const [step, setStep] = useState(0);

  // 주증상
  if (step == 0) {
    return (
      <ScreenSelectMainSymptom
        backFunc={() => {
          NetworkManager.checkupService
            .kioskResetForce(patientData.checkupId)
            .then(() => {
              patientStore.resetData();
              patientDataStore.resetData();
              navigate("/");
            });
        }}
        departmentId={patientData.medicalDepartmentId}
        setMainSymptomData={(data: any, nextStep?: boolean) => {
          setSubmitPatientData((prev) => {
            return { ...prev, mainSymptom: data };
          });

          if (nextStep) {
            if (patientData.medicalDepartmentId === 3) {
              setStep(2);
            } else if (patientData.medicalDepartmentId === 4) {
              let step = 0;
              for (const element of data.answers) {
                if (element.id === 196) {
                  step = 1;
                  break;
                } else {
                  step = 2;
                }
              }
              if (step === 1) {
                setEntHeadache(true);
              } else {
                setEntHeadache(false);
                setSubmitPatientData((prev) => {
                  return { ...prev, painScore: [], painArea: [] };
                });
              }
              setStep(step);
            } else {
              setStep((prev) => prev + 1);
            }
          }
        }}
      />
    );
  }

  // 인체도
  if (step == 1) {
    return (
      <ScreenSelectBodyNew
        backFunc={() => {
          setStep(0);
        }}
        department={DepartmentRecord[patientData.medicalDepartmentId]}
        setBodyData={(data: any) => {
          setSubmitPatientData((prev) => {
            return { ...prev, painArea: data };
          });
          setStep((prev) => prev + 1);
        }}
      />
    );
  }

  // 기간
  if (step == 2) {
    return (
      <ScreenSelectSickPeriod
        departmentId={patientData.medicalDepartmentId}
        backFunc={() => {
          if (patientData.medicalDepartmentId === 3) {
            setStep(0);
          } else if (patientData.medicalDepartmentId === 4) {
            if (entHeadache) {
              setStep(1);
            } else {
              setStep(0);
            }
          } else {
            setStep((prev) => prev - 1);
          }
        }}
        setPeriodData={(data: any) => {
          setSubmitPatientData((prev) => {
            return { ...prev, symptomHistory: data };
          });
          if (patientData.medicalDepartmentId === 3) {
            setStep(4);
          }
          if (patientData.medicalDepartmentId === 4) {
            if (entHeadache) {
              setStep(3);
            } else {
              setStep(4);
            }
          } else {
            setStep((prev) => prev + 1);
          }
        }}
      />
    );
  }

  // 통증 강도
  if (step == 3) {
    return (
      <ScreenPainScore
        title="{0}를 선택해 주세요"
        highlightArr={["증상의 정도"]}
        backFunc={() => {
          setStep((prev) => prev - 1);
        }}
        setPainScore={(data: any) => {
          setSubmitPatientData((prev) => {
            return { ...prev, painScore: data };
          });
          setStep((prev) => prev + 1);
        }}
        layoutStyle={{
          padding: "32px 80px",
          justifyContent: "center",
        }}
      />
    );
  }

  //키 몸무게 입력
  if (step === 4) {
    return (
      <PersonalDataInput
        isShowPatientInfo={true}
        buttonAvailable={
          patientSize.bodySize.weight !== "" &&
          patientSize.bodySize.height !== ""
        }
        padContent={() => {
          return <KeypadSize10Num patientSize={patientSize} />;
        }}
        inputContent={() => {
          return <BodyInputContent patientSize={patientSize} />;
        }}
        backFunc={() => {
          if (patientData.medicalDepartmentId === 4) {
            if (entHeadache) {
              setStep(3);
            } else {
              setStep(2);
            }
          } else {
            setStep((prev) => prev - 1);
          }
        }}
        inputFunc={() => {
          if (
            patientSize.bodySize.weight === "" ||
            patientSize.bodySize.height === ""
          ) {
            patientSize.setError({
              weight: !!patientSize.bodySize.weight ? false : true,
              height: !!patientSize.bodySize.height ? false : true,
            });
            patientSize.setBodyFocus(
              patientSize.bodySize.height ? "weight" : "height",
            );
            return;
          }

          setSubmitPatientData((prev) => {
            return {
              ...prev,
              weight: `${patientSize.bodySize.weight}`,
              height: `${patientSize.bodySize.height}`,
            };
          });
          setStep(5);
        }}
        skipFunc={() => {
          setSubmitPatientData((prev) => {
            return {
              ...prev,
              weight: "",
              height: "",
            };
          });
          patientSize.setBodySize({ height: "", weight: "" });
          setStep(5);
        }}
        inputType={InputType.bodySize}
      />
    );
  }

  if (step == 5) {
    return (
      <ScreenDetail
        setStep={setStep}
        backFunc={() => {
          if (patientData.medicalDepartmentId === 3) {
            setStep(2);
          } else {
            setStep((prev) => prev - 1);
          }
        }}
        departmentId={patientData.medicalDepartmentId}
        setStep={setStep}
        setDetailQuestion={(data: any) => {
          if (data.length < 1) {
            return;
          }
          setSubmitPatientData((prev) => {
            return { ...prev, screenAnswerList: data };
          });
          setStep((prev) => prev + 1);
        }}
      />
    );
  }

  if (step == 6) {
    return (
      <ScreenAIQuestionPage
        submitPatientData={submitPatientData}
        nextStep={() => {
          setStep((prev) => prev + 1);
        }}
      />
    );
  }

  if (step == 7) {
    return (
      <ScreenSurvey
        backFunc={""}
        nextStep={(data: string) => {
          let reqData = {
            asks: data,
            savedUrl: [],
            preClinicRecordId: submitPatientData.checkUpId,
          };
          NetworkManager.checkupService
            .saveAsk(reqData)
            .then((res) => {
              setStep((prev) => prev + 1);
            })
            .catch(() => {});
        }}
        departmentId={patientData.medicalDepartmentId}
      />
    );
  }

  if (step == 8) {
    return <ScreenCompleteWait />;
  }

  navigate("/checkup");
};

export default ScreenUsePage;
