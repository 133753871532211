import {
  getDateFormatByIdentityBirth,
  getGenderBoolean,
} from "@/common/utils/PatientUtil";
import { ReceiptSearchResponse } from "@/network-module/services/HospitalService";
import { t } from "i18next";
import {
  COLORS,
  IconBadge,
  typography_style_heading_lg_semibold,
} from "vd-component";

interface PatientCardProps {
  patient: ReceiptSearchResponse;
  nextStep: Function;
  selectedPatient: ReceiptSearchResponse | undefined;
  selectPatient: Function;
}
const PatientCard = ({
  patient,
  nextStep,
  selectedPatient,
  selectPatient,
}: PatientCardProps) => {
  const isSelected = selectedPatient?.patientId === patient.patientId;

  const maskingName = () => {
    if (patient.name.length === 1) return patient.name;
    if (patient.name.length === 2) return patient.name.substring(0, 1) + "*";

    // 양 끝을 제외한 가운데 문자열만 마스킹
    const name = patient.name;
    return (
      name.substring(0, 1) +
      "*".repeat(name.length - 2) +
      name.substring(name.length - 1)
    );
  };

  return (
    <div
      css={{
        padding: "20px",
        display: "flex",
        height: "124px",
        flexDirection: "column",
        gap: "12px",
        border: isSelected ? "2px solid" : "1px solid",
        borderColor: isSelected
          ? COLORS.colorInversePrimaryNomal
          : COLORS.colorLineLight,
        borderRadius: "12px",
        boxShadow: isSelected
          ? "0px 0px 8px 0px rgba(0, 0, 0, 0.08), 0px 8px 16px 0px rgba(0, 0, 0, 0.08), 0px 16px 20px 0px rgba(0, 0, 0, 0.12)"
          : "none",
      }}
      onClick={() => {
        selectPatient(patient);
      }}
    >
      <span css={[typography_style_heading_lg_semibold]}>{maskingName()}</span>
      <div css={{ display: "flex", gap: "8px" }}>
        <IconBadge badgeType="opacity-bg" size="x-large" variant="primary">
          {getGenderBoolean(patient.identityGender)
            ? t("common.female")
            : t("common.male")}
        </IconBadge>
        <IconBadge badgeType="opacity-bg" size="x-large" variant="primary">
          {getDateFormatByIdentityBirth(patient.birth, patient.identityGender)}
        </IconBadge>
      </div>
    </div>
  );
};

export default PatientCard;
