import {
  Button,
  COLORS,
  Popup,
  typography_style_body_lg_medium,
  typography_style_heading_lg_semibold,
} from "vd-component";

interface ReceptionBackHomePopupProps {
  isOpen: boolean;
  onClose: () => void;
  handleClick: () => void;
}

const ReceptionBackHomePopup = ({
  isOpen,
  onClose,
  handleClick,
}: ReceptionBackHomePopupProps) => {
  return (
    <Popup
      isOpen={isOpen}
      onClose={onClose}
      title="처음으로 돌아가시겠습니까?"
      css={{ padding: "20px 24px" }}
    >
      <div
        css={[
          typography_style_heading_lg_semibold,
          {
            color: COLORS.colorLabelNomal,
            width: "320px",
            display: "flex",
            justifyContent: "center",
          },
        ]}
      >
        <span>처음으로 돌아가시겠습니까?</span>
      </div>
      <div
        css={[
          typography_style_body_lg_medium,
          {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            color: COLORS.colorLabelLight,
            marginBottom: "32px",
          },
        ]}
      >
        <span>처음으로 돌아가는 경우</span>
        <span>작성한 내용이 모두 삭제됩니다</span>
      </div>
      <div css={{ display: "flex", gap: "8px" }}>
        <Button
          onClick={onClose}
          css={{ flex: 1 }}
          size="large"
          variant="secondary-line"
        >
          취소
        </Button>
        <Button
          // onClick={() => {
          //   navigate("/reception");
          // }}
          onClick={handleClick}
          css={{ flex: 1 }}
          size="large"
          variant="primary"
        >
          확인
        </Button>
      </div>
    </Popup>
  );
};

export default ReceptionBackHomePopup;
