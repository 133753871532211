import NewLayout from "@/components/common/NewLayout";
import { NetworkManager } from "@/network-module/NetworkManager";
import { CheckNormalIcon } from "@/theme/CustomIcons";
import { useState } from "react";
import {
  Button,
  COLORS,
  SelectChip,
  Title,
  typography_style_heading_md_medium,
} from "vd-component";

const ScreenSurvey = ({
  nextStep,
  departmentId,
  backFunc,
}: {
  nextStep: Function;
  departmentId: number;
  backFunc: Function | any;
}) => {
  const { data: surveyData } =
    NetworkManager.checkupService.getShortCut(departmentId);
  const [surveyAnswer, setSurveyAnswer] = useState(new Set());

  const handleToggle = (shortCut: string) => {
    const updatedSurveyAnswer = new Set(surveyAnswer);
    if (updatedSurveyAnswer.has(shortCut)) {
      updatedSurveyAnswer.delete(shortCut);
    } else {
      updatedSurveyAnswer.add(shortCut);
    }
    setSurveyAnswer(updatedSurveyAnswer);
  };

  const handleComplete = () => {
    if (surveyAnswer.size < 1) return false;
    nextStep(JSON.stringify(Array.from(surveyAnswer)));
  };

  return (
    <NewLayout
      backFunc={backFunc}
      isShowPatientInfo
      css={{
        flexDirection: "column",
        justifyContent: "normal",
        gap: "24px",
        padding: "36px 48px",
      }}
    >
      <Title
        size="2x-large"
        title="마지막으로 의료진에게 {0}이 있으신가요?"
        description="의료진이 알아야하는 특이사항이나 요구사항을 선택해주세요."
        highlightArr={["요청 사항"]}
        css={{ alignItems: "flex-start" }}
        descriptionStyleProps={{ css: COLORS.colorLabelLight }}
      />
      <div
        css={{
          display: "flex",
          flexWrap: "wrap",
          gap: "12px",
          minHeight: "116px",
        }}
      >
        {surveyData.map((survey) => {
          const isSelected = surveyAnswer.has(survey.shortCut);
          return (
            <SelectChip
              size="xlarge"
              variant="secondary"
              css={{
                padding: "10px 12px",
                borderRadius: "999px",
                gap: "4px",
                color: isSelected
                  ? COLORS.colorInverseLabelLight
                  : COLORS.colorLabelNomal,
                background: isSelected
                  ? COLORS.colorInverseBackgroundLineNomal
                  : COLORS.colorBackgroundLight,
                ":hover": {
                  background: isSelected
                    ? COLORS.colorInverseBackgroundLineNomal
                    : COLORS.colorBackgroundLight,
                },
                ":active": {
                  background: isSelected
                    ? COLORS.colorInverseBackgroundLineNomal
                    : COLORS.colorBackgroundLight,
                },
              }}
              onClick={() => {
                handleToggle(survey.shortCut);
              }}
            >
              <CheckNormalIcon />
              <span css={[typography_style_heading_md_medium]}>
                {survey.shortCut}
              </span>
            </SelectChip>
          );
        })}
      </div>

      <div
        css={{
          marginTop: "24px",
          display: "flex",
          justifyContent: "center",
          gap: "12px",
        }}
      >
        <Button
          size="x-large"
          variant="secondary-line"
          css={{ width: "194px" }}
          onClick={() => {
            nextStep('["해당없음"]');
          }}
        >
          해당없음
        </Button>
        <Button
          size="x-large"
          variant="primary"
          css={{ width: "194px" }}
          disabled={surveyAnswer.size === 0}
          onClick={handleComplete}
        >
          완료
        </Button>
      </div>
    </NewLayout>
  );
};

export default ScreenSurvey;
