/**
 * 인체 다이어그램 카테고리 탭에서 사용되는 enum
 * */

/**
 * 메인 카테고리(인체부위)
 * */
export const BodyMainCategoryEnum = {
  BODY: "body", // 전신
  HAND: "hand", // 손
  FOOT: "foot", // 발
  EHEAD: "ehead", // 이비인후과머리
  NHEAD: "nhead", // 신경과머리
} as const;

/**
 * 서브 카테고리(상세 인체부위)
 * */
// 전신 하위 카테고리
export const BodySubCategoryEnum = {
  FRONT: "front", // 전면
  BACK: "back", // 후면
  LEFT: "left", // 왼쪽
  RIGHT: "right", // 오른쪽
} as const;

// 손 하위 카테고리
export const HandSubCategoryEnum = {
  IN: "in", // 손바닥
  OUT: "out", // 손등
} as const;

// 발 하위 카테고리
export const FootSubCategoryEnum = {
  TOP: "top", // 발등
  BACK: "back", // 발뒤
  BOTTOM: "bottom", // 발바닥
  OUT_SIDE: "outSide", // 바깥쪽
  IN_SIDE: "inSide", // 안쪽
} as const;

// 전체 하위 카테고리
export const SubCategoryEnum = {
  ...BodySubCategoryEnum,
  ...HandSubCategoryEnum,
  ...FootSubCategoryEnum,
  "": "",
} as const;

/**
 * 인체도 방향
 * */
export const PartDirectionEnum = {
  RIGHT: "right", // 오른쪽
  LEFT: "left", // 왼쪽
} as const;
