import AxiosClient from "@network-module/AxiosClient";

export class BaseRepository {
  api: AxiosClient = new AxiosClient();

  protected post(
    endpoint: string,
    data: Record<string, any> | undefined,
    headers?: Record<string, any>
  ) {
    return this.api.post(endpoint, data, headers);
  }

  protected get(endpoint: string) {
    return this.api.get(endpoint);
  }

  protected delete(endpoint: string) {
    return this.api.delete(endpoint);
  }

  protected put(endpoint: string, data: Record<string, any> | undefined) {
    return this.api.put(endpoint, data);
  }
}
