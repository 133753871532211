import { InputType } from "@/common/utils/Type";
import NewLayout from "@/components/common/NewLayout";
import InputForm from "./InputForm";
import KeyPadContainer from "./KeyPadContainer";

interface PersonalDataInputProps {
  buttonAvailable: boolean;
  backFunc?: Function;
  skipFunc?: Function;
  inputFunc: Function;
  inputType: InputType;
  padContent: Function;
  inputContent: Function;
  isShowPatientInfo?: boolean;
}
const PersonalDataInput = ({
  buttonAvailable,
  backFunc,
  skipFunc,
  inputFunc,
  inputType,
  padContent,
  inputContent,
  isShowPatientInfo = false,
}: PersonalDataInputProps) => {
  return (
    <NewLayout
      backFunc={backFunc}
      isShowPatientInfo={isShowPatientInfo}
      css={{
        padding: "47px 58px",
        justifyContent: "normal",
      }}
    >
      <div
        style={{
          display: "flex",
          gap: "141px",
        }}
      >
        <InputForm
          inputType={inputType}
          inputContent={inputContent}
          buttonAvailable={buttonAvailable}
          inputFunc={inputFunc}
          skipFunc={skipFunc}
        />
        <KeyPadContainer
          padContent={padContent}
          skipFunc={skipFunc}
          buttonAvailable={buttonAvailable}
          inputFunc={inputFunc}
        />
      </div>
    </NewLayout>
  );
};

export default PersonalDataInput;
