import AppSetting from "../config/AppSetting";

export const enum StorageKey {}

export default class StorageManager {
  static setData(key: string, value: string) {
    // if (AppSetting.isElectronApp()) {
    AppSetting.getVdBridge().setStorageData(key, value);
    // } else {
    localStorage.setItem(key, value);
    // }
  }

  static getData(key: string): Promise<string> {
    // if (AppSetting.isElectronApp()) {
    //   return new Promise((resolve) => {
    //     AppSetting.getVdBridge()
    //       .getStorageData(key)
    //       .then((value: string) => resolve(value ?? ""));
    //   });
    // }
    return new Promise((resolve) => {
      resolve(localStorage.getItem(key) ?? "");
    });
  }

  static getDataNow(key: string): string {
    return localStorage.getItem(key) ?? "";
  }

  static setToken(accessToken: string, refreshToken: string) {
    localStorage.setItem("access_token", accessToken);
    localStorage.setItem("refresh_token", refreshToken);
    // if (AppSetting.isElectronApp()) {
    //   const tokenSave = AppSetting.getVdBridge().setStorageData(
    //     "access_token",
    //     accessToken
    //   );
    //   const refreshSave = AppSetting.getVdBridge().setStorageData(
    //     "refresh_token",
    //     refreshToken
    //   );
    //   return Promise.all([tokenSave, refreshSave]);
    // } else {
    //   localStorage.setItem("access_token", accessToken);
    //   AppSetting.getVdBridge().setStorageData("refresh_token", refreshToken);
    // }
  }

  static getToken() {
    if (AppSetting.isElectronApp()) {
      return { accessToken: localStorage.getItem("access_token") };
      // return AppSetting.getVdBridge()
      //   .getStorageData("access_token")
      //   .then((accessToken) => {
      //     return {
      //       accessToken,
      //     };
      //   });
    } else {
      // localStorage.setItem("access_token", accessToken);
      // AppSetting.getVdBridge().setStorageData("refresh_token", refreshToken);
      return { accessToken: localStorage.getItem("access_token") };
    }
  }
}
