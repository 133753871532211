import type { StateCreator } from "zustand";
import { DepartmentEnum } from "../entities/departmentMappings";

export interface HumanBodyPainArea {
  selectedPainAreas: Set<string>;
  department: DepartmentEnum | null;
  setDepartment: (department: DepartmentEnum) => void;
  resetPainArea: () => void;
  togglePainArea: (painArea: string) => void;
}

export const humanBodyPainArea: StateCreator<HumanBodyPainArea> = (set) => ({
  selectedPainAreas: new Set(),
  department: null,
  setDepartment: (department: DepartmentEnum) => {
    set(() => {
      return { department };
    });
  },
  resetPainArea: () => {
    set(() => {
      return { selectedPainAreas: new Set() };
    });
  },

  togglePainArea: (painArea: string) => {
    set((state) => {
      const updatedPainAreas = new Set(state.selectedPainAreas);
      if (updatedPainAreas.has(painArea)) {
        updatedPainAreas.delete(painArea);
      } else {
        updatedPainAreas.add(painArea);
      }
      return { selectedPainAreas: updatedPainAreas };
    });
  },
});
