import { Device } from "../entities/DeviceEntity";
import { BaseRepository } from "./BaseRepository";

export class AuthRepository extends BaseRepository {
  // signupPatient(data) {
  //   return this.post("/auth/patient/signup", data);
  // }

  kioskAdd(data: Device): Promise<{ otp: string }> {
    return this.api.post("/auth/kiosk/add", data);
  }

  kioskSignInFirst(data: Device) {
    return this.post("/auth/kiosk/signin/first", data);
  }

  searchPatients(birth: string, identityGender: string | number): Promise<any> {
    return this.api.get(
      `/patients/search/kiosk?birth=${birth}&&identityGender=${identityGender}`,
    );
  }
}
