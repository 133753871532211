export enum PadType {
  kor,
  num,
}

export enum InputType {
  name,
  identityNumber,
  phoneNumber,
  bodySize,
}
