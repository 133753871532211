import { PauseIcon, PlayIcon } from "@/theme/CustomIcons";
import { MouseEventHandler } from "react";
import { COLORS } from "vd-component";

export const RecordIconBtn = ({
  listening,
  onClick,
}: {
  listening: boolean;
  onClick?: MouseEventHandler<HTMLImageElement>;
}) => {
  return (
    <div
      css={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "84px",
        height: "84px",
        background: COLORS.colorBackgroundWhite,
        borderRadius: "999px",
      }}
      onClick={onClick}
    >
      {listening ? (
        <PauseIcon color={COLORS.colorLabelNomal} />
      ) : (
        <PlayIcon color={COLORS.colorLabelNomal} />
      )}
    </div>
  );
};
