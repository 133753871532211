import { css } from "@emotion/react";
import { DesignSystemSize, DesignSystemState } from "../DesignSystemType";
import { SegmentedControlChild } from "./SegmentedControlChild";

const defaultStyle = css`
  width: fit-content;
  height: fit-content;
  display: flex;
  background: var(--color-background-nomal, #dde0e2);
  width: 100%;
`;

const sizeStyle = {
  [DesignSystemSize.LARGE]: css`
    border-radius: var(--Border-Radius-8, 8px);
    padding: var(--spacing-4, 4px);
  `,
  [DesignSystemSize.MEDIUM]: css`
    border-radius: var(--Border-Radius-6, 6px);
    padding: var(--spacing-4, 4px);
  `,
  [DesignSystemSize.SMALL]: css`
    border-radius: var(--Border-Radius-4, 4px);
    padding: var(--spacing-2, 2px);
  `,
  [DesignSystemSize.XSMALL]: css`
    border-radius: var(--Border-Radius-4, 4px);
    padding: var(--spacing-2, 2px);
  `,
};

export const SegmentedControl = ({
  size,
  items,
}: {
  size: DesignSystemSize;
  items: {
    text: string;
    count?: number;
    onClick?: () => void;
    checked?: boolean;
  }[];
}) => {
  return (
    <div css={[defaultStyle, sizeStyle[size]]}>
      {items.map((item) => (
        <SegmentedControlChild
          key={item.text}
          size={size}
          state={
            item.checked
              ? DesignSystemState.SELECTED
              : DesignSystemState.DEFAULT
          }
          text={item.text}
          count={item.count}
          onClick={item.onClick}
        />
      ))}
    </div>
  );
};
