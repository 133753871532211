import { ScreenQuestion } from "@/network-module/entities/CheckupEntityNew";
import { NetworkManager } from "@/network-module/NetworkManager";
import { useEffect, useState } from "react";

import "regenerator-runtime/runtime";

interface QuestionData {
  id: number;
  title: string;
  question: string;
  answers: Set<number>;
}

//case1 : 두개가 정상적으로 나오는 케이스
//case2 : 한개는 가려진채로 나오는 케이스
//case3 : 하나만 나오는 케이스
export enum DisplayType {
  CASE1 = 1,
  CASE2 = 2,
  CASE3 = 3,
}

const ScreenDetailHook = (departmentId: number) => {
  const [isInputOpen, setIsInputOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [maxIndex, setMaxIndex] = useState(0);
  const { data: originQuestionList } =
    NetworkManager.checkupService.getCheckupScreen(departmentId);

  const [toggledCustomAnswer, setToggledCustomAnswer] = useState<number>(0);

  const [questionListById, setQuestionListById] = useState<ScreenQuestion[]>(
    [],
  );

  const [mainDisplayQuestion, setMainDisplayQuestion] = useState<
    ScreenQuestion[][]
  >([]);
  const [subDisplayQuestion, setSubDisplayQuestion] = useState<Set<number>[]>(
    [],
  );
  const [subQuestionOrigin, setSubQuestionOrigin] = useState<number[][]>([]);

  const [questionData, setQuestionData] = useState<QuestionData[]>([]);
  const [customAnswer, setCustomAnswer] = useState<string[]>([]);
  const [answerList, setAnswerList] = useState<string[]>([]);

  // 각 질문 페이지의 표현 방식을 관리
  const [displayInfo, setDisplayInfo] = useState([]);

  const pagePassable = () => {
    if (mainDisplayQuestion[currentIndex] == undefined) return false;

    const currentPageQuestions = [];
    mainDisplayQuestion[currentIndex].forEach((question) => {
      currentPageQuestions.push(question.id);
    });

    if (subDisplayQuestion[currentIndex] != undefined) {
      subDisplayQuestion[currentIndex].forEach((question) => {
        currentPageQuestions.push(question);
      });
    }

    return currentPageQuestions.every((question) => {
      return questionData[question].answers.size > 0;
    });
  };

  //값 돌려줄 때 사용
  const returnDetailQuestion = () => {
    const procData = questionData.map((question, idx) => {
      if (question == undefined) return;
      const answer = [];

      question.answers.forEach((id) => {
        if (answerList[id] == "직접입력") {
          answer.push({
            id,
            content: customAnswer[question.id],
          });
        } else {
          answer.push({
            id,
            content: answerList[id],
          });
        }
      });

      return {
        id: question.id,
        title: question.title,
        question: question.question,
        answers: answer,
      };
    });

    //이 아래에서는 undefined값을 제거한다
    return procData.filter((item) => item != undefined);
  };

  const setDisplayData = (questionList: ScreenQuestion[]) => {
    const displayTmp = [];
    const subDisplayTmp = [];
    const excludeIds = [];
    for (let i = 0; i < questionList.length; i += 2) {
      const q1 = questionList[i];
      const q2 = questionList[i + 1];

      let pass1 = true;
      let pass2 = true;

      if (q1.relatedQuestion) {
        const q1r = JSON.parse(q1.relatedQuestion);
        Object.keys(q1r).forEach((q1rK) => {
          subDisplayTmp[q1rK] = q1r[q1rK];
        });
        excludeIds.push(...Object.values(q1r).flat());
      }

      if (q2 != undefined) {
        if (q2.relatedQuestion) {
          const q2r = JSON.parse(q2.relatedQuestion);
          Object.keys(q2r).forEach((q2rK) => {
            subDisplayTmp[q2rK] = q2r[q2rK];
          });
          excludeIds.push(...Object.values(q2r).flat());
        }
      }

      if (excludeIds.includes(q1.id)) {
        pass1 = false;
      }

      if (q2 != undefined) {
        if (excludeIds.includes(q2.id)) {
          pass2 = false;
        }
      }

      if (q2 != undefined) {
        if (pass1 && pass2) {
          if (q2.relatedQuestion) {
            displayTmp.push([q1]);
            displayTmp.push([q2]);
          } else {
            displayTmp.push([q1, q2]);
          }
          continue;
        }
      }

      if (pass1) {
        displayTmp.push([q1]);
      }

      if (q2 != undefined) {
        if (pass2) {
          displayTmp.push([q2]);
        }
      }
    }

    const displayInfoTmp = [];
    displayTmp.forEach((questions, index) => {
      let displayType: DisplayType;
      // 질문의 개수가 두개인 경우 두개 보여줌
      if (questions.length === 2) {
        displayType = DisplayType.CASE1;
      } else if (questions.length === 1) {
        // 질문의 개수가 한개인 경우 그 질문이 관련질문이 있는지 확인
        if (questions[0].relatedQuestion) {
          displayType = DisplayType.CASE2;
        } else {
          // 관련질문이 없는 경우 그냥 보여줌
          displayType = DisplayType.CASE3;
        }
      }
      displayInfoTmp[index] = displayType;
    });

    setSubQuestionOrigin(subDisplayTmp);
    setDisplayInfo(displayInfoTmp);
    setMaxIndex(displayTmp.length);
    setMainDisplayQuestion(displayTmp);
  };

  const setQuestionDataFormat = (originQuestionList: ScreenQuestion[]) => {
    const result = [];
    const answers = [];
    const questionListByIdTmp = [];

    originQuestionList.forEach((question, index) => {
      question.screeningAnswer.forEach((answer) => {
        answers[answer.id] = answer.content;
      });

      result[question.id] = {
        id: question.id,
        title: question.title,
        question: question.question,
        answers: new Set(),
      };
      questionListByIdTmp[question.id] = question;
    });

    setQuestionData(result);
    setQuestionListById(questionListByIdTmp);
    setAnswerList(answers);
  };

  const checkAnswer = (questionId: number, answerId: number) => {
    const question = questionData.find((question) => {
      if (question === undefined) return false;
      return question.id === questionId;
    });
    return question?.answers.has(answerId);
  };

  const toggleOffAnswer = (questionId: number, answerId: number) => {
    const question = questionData.find((question) => {
      if (question === undefined) return false;
      return question.id === questionId;
    });
    question.answers.delete(answerId);

    setQuestionData((prev) => {
      const newData = [...prev];
      newData[questionId] = question;
      return newData;
    });
  };

  const toggleOnAnswer = (questionId: number, answerId: number) => {
    const question = questionData.find((question) => {
      if (question === undefined) return false;
      return question.id === questionId;
    });
    const currentQuestion = questionListById[questionId];
    const type2Answer = currentQuestion.screeningAnswer.find(
      (answer) => answer.type == 2,
    );
    if (type2Answer) {
      question.answers.delete(type2Answer.id);
    }
    question.answers.add(answerId);

    setQuestionData((prev) => {
      const newData = [...prev];
      newData[questionId] = question;
      return newData;
    });
  };

  const toggleAnswer = (
    questionId: number,
    answerId: number,
    multiAnswer: boolean,
  ) => {
    const question = questionData.find((question) => {
      if (question === undefined) return false;
      return question.id === questionId;
    });

    if (!question) return;

    const currentQuestion = questionListById[questionId];

    const type2Answer = currentQuestion.screeningAnswer.find(
      (answer) => answer.type == 2,
    );
    const prevAnswer = question.answers;

    if (multiAnswer) {
      if (question.answers.has(answerId)) {
        question.answers.delete(answerId);
      } else {
        question.answers.add(answerId);
      }
      if (type2Answer) {
        question.answers.delete(type2Answer.id);
      }
    } else {
      question.answers = new Set([answerId]);
      unSetCustomAnswer(questionId);
    }

    const offAnswers = new Set(
      [...prevAnswer].filter((item) => !question.answers.has(item)),
    );

    subQuestionControl(question, answerId, offAnswers);

    //이 아래에서는 위에서 바뀐 값을 questionData에 반영
    setQuestionData((prev) => {
      const newData = [...prev];
      newData[questionId] = question;
      return newData;
    });
  };

  const subQuestionControl = (
    question: QuestionData,
    answerId: number,
    offAnswers: Set<number>,
  ) => {
    if (offAnswers.size > 0) {
      //질문 제거된 케이스
      offAnswers.forEach((oaid) => {
        if (question.answers.has(oaid)) {
          return;
        }

        if (
          subQuestionOrigin[oaid] != undefined &&
          subQuestionOrigin[oaid].length > 0
        ) {
          const subDisplayTmp = subDisplayQuestion;
          subQuestionOrigin[oaid].forEach((id) => {
            subDisplayTmp[currentIndex].delete(id);
            if (questionListById[id].relatedQuestion) {
              const qr = JSON.parse(questionListById[id].relatedQuestion);
              Object.keys(qr).forEach((qrK) => {
                qr[qrK].forEach((qrid) => {
                  subDisplayTmp[currentIndex].delete(qrid);
                  questionData[qrid].answers = new Set();
                });
              });
            }
          });
          setSubDisplayQuestion(subDisplayTmp);
          //응답 데이터 제거
          subQuestionOrigin[oaid].forEach((id) => {
            questionData[id].answers = new Set();
          });
        }
      });
    }

    //질문 선택된 케이스
    if (
      subQuestionOrigin[answerId] != undefined &&
      subQuestionOrigin[answerId].length > 0
    ) {
      const subDisplayTmp = subDisplayQuestion;
      if (subDisplayTmp[currentIndex] == undefined) {
        subDisplayTmp[currentIndex] = new Set();
      }
      //추가 질문이 있는지 없는지 체크 및 관련 로직 처리
      if (question.answers.has(answerId)) {
        subQuestionOrigin[answerId].forEach((id) => {
          subDisplayTmp[currentIndex].add(id);
        });
        setSubDisplayQuestion(subDisplayTmp);
      }
    }
  };

  const toggleCustomAnswer = (questionId: number, content: string) => {
    setCustomAnswer((prev) => {
      const newData = [...prev];
      newData[questionId] = content;
      return newData;
    });
  };

  const unSetCustomAnswer = (questionId: number) => {
    setCustomAnswer((prev) => {
      const newData = [...prev];
      if (newData[questionId]) {
        newData[questionId] = "";
      }
      return newData;
    });
  };

  const prevQuestion = (setStep?: Function) => {
    if (currentIndex === 0 && setStep) return setStep(4);
    if (currentIndex - 1 >= 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const nextQuestion = () => {
    if (currentIndex + 1 <= maxIndex - 1) {
      setCurrentIndex(currentIndex + 1);
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (originQuestionList?.length > 0) {
      setQuestionDataFormat(originQuestionList);
      setDisplayData(originQuestionList);
    }
  }, [originQuestionList]);

  useEffect(() => {
    if (toggledCustomAnswer > 0) {
      setIsInputOpen(true);
    } else {
      setIsInputOpen(false);
    }
  }, [toggledCustomAnswer]);

  return {
    maxIndex,
    currentIndex,
    prevQuestion,
    nextQuestion,
    mainDisplayQuestion,
    subDisplayQuestion,
    toggleAnswer,
    checkAnswer,
    displayInfo,
    questionListById,
    isInputOpen,
    setIsInputOpen,
    customAnswer,
    toggledCustomAnswer,
    setToggledCustomAnswer,
    toggleCustomAnswer,
    unSetCustomAnswer,
    pagePassable,
    returnDetailQuestion,
    toggleOnAnswer,
    toggleOffAnswer,
  };
};

export default ScreenDetailHook;
