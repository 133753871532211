import ReceptionQRPage from "@/pages/reception/ReceptionQRPage";
import Test from "@/pages/test/test";
import { lazy, Suspense } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import "regenerator-runtime";
import "regenerator-runtime/runtime";
import KioskProvider from "./provider";

const KioskIndex = lazy(() => import("@/components/kiosk/KioskIndex"));
const ReceptionPage = lazy(() => import("@/pages/reception/ReceptionPage"));
const ScreenPage = lazy(() => import("@/pages/screening/ScreenPage"));

const router = createBrowserRouter([
  {
    path: "/",
    index: true,
    element: (
      <Suspense fallback={<div></div>}>
        <KioskProvider>
          <KioskIndex />
        </KioskProvider>
      </Suspense>
    ),
  },
  {
    path: "/qr",
    index: true,
    element: (
      <Suspense fallback={<div></div>}>
        <KioskProvider>
          <ReceptionQRPage />
        </KioskProvider>
      </Suspense>
    ),
  },
  {
    path: "/reception",
    element: (
      <Suspense fallback={<div></div>}>
        <KioskProvider>
          <ReceptionPage />
        </KioskProvider>
      </Suspense>
    ),
  },
  {
    path: "/checkup",
    element: (
      <Suspense fallback={<div></div>}>
        <KioskProvider>
          <ScreenPage />
        </KioskProvider>
      </Suspense>
    ),
  },
  {
    path: "/test/test",
    element: (
      <Suspense fallback={<div></div>}>
        <Test />
      </Suspense>
    ),
  },
]);

export const Router = () => <RouterProvider router={router} />;
