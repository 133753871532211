import NewList from "@/components/common/List/NewList";
import { useHumanBodyStore } from "@/features/humanBodyDiagram/context/useHumanBodyStore";
import {
  BodyMainCategoryEnum,
  BodySubCategoryEnum,
  FootSubCategoryEnum,
  HandSubCategoryEnum,
} from "@/features/humanBodyDiagram/entities/categories";
import type {
  MainCategory,
  SubCategory,
} from "@/features/humanBodyDiagram/stores/humanBodyPartsSlice";

import styled from "@emotion/styled";
import { COLORS, typography_style_heading_sm_semibold } from "vd-component";

export const HumanBodySubCategoryTabs = () => {
  const { mainCategory, subCategory, setSubCategory } = useHumanBodyStore(
    (store) => ({
      mainCategory: store.mainCategory,
      subCategory: store.subCategory,
      setSubCategory: store.setSubCategory,
    }),
  );

  const subCategoryItems = getSubCategoryItems(mainCategory);

  return (
    <Container>
      <ToggleTabs
        key={subCategory}
        items={subCategoryItems}
        itemTemplateProps={{
          onItemClick: (item) => setSubCategory(item.category),
          itemTemplate: (item) => {
            return <span>{item.label}</span>;
          },
          itemContainerStyle: (item) => {
            if (subCategory === item.category) {
              return {
                background: `${COLORS.colorInverseBackgroundLineNomal}`,
                span: {
                  color: "white",
                },
              };
            }

            return {
              background: "var(--color-background-nomal)",
              span: {
                color: "var(--color-label-light)",
              },
            };
          },
        }}
      />
    </Container>
  );
};

interface SubCategoryItemType {
  label: string;
  category: SubCategory;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ToggleTabs = styled(NewList<SubCategoryItemType>)`
  gap: 8px;
  display: flex;
  overflow-x: auto;
  align-items: center;

  li {
    ${typography_style_heading_sm_semibold}
    border-radius: 999px;
    display: flex;
    align-items: center;
    padding: 4px 16px;
  }
`;

const getSubCategoryItems = (
  mainCategory: MainCategory,
): SubCategoryItemType[] => {
  switch (mainCategory) {
    case BodyMainCategoryEnum.EHEAD:
      return [
        {
          label: "왼쪽",
          category: BodySubCategoryEnum.LEFT,
        },
        {
          label: "오른쪽",
          category: BodySubCategoryEnum.RIGHT,
        },
      ];
    case BodyMainCategoryEnum.NHEAD:
      return [
        {
          label: "뒷면",
          category: BodySubCategoryEnum.BACK,
        },
        {
          label: "왼쪽",
          category: BodySubCategoryEnum.LEFT,
        },
        {
          label: "오른쪽",
          category: BodySubCategoryEnum.RIGHT,
        },
      ];
    case BodyMainCategoryEnum.BODY:
      return [
        {
          label: "앞면",
          category: BodySubCategoryEnum.FRONT,
        },
        {
          label: "뒷면",
          category: BodySubCategoryEnum.BACK,
        },
      ];
    case BodyMainCategoryEnum.HAND:
      return [
        {
          label: "손바닥",
          category: HandSubCategoryEnum.IN,
        },
        {
          label: "손등",
          category: HandSubCategoryEnum.OUT,
        },
      ];
    case BodyMainCategoryEnum.FOOT:
      return [
        {
          label: "발등",
          category: FootSubCategoryEnum.TOP,
        },
        {
          label: "발뒤",
          category: FootSubCategoryEnum.BACK,
        },
        {
          label: "발바닥",
          category: FootSubCategoryEnum.BOTTOM,
        },
        {
          label: "바깥쪽",
          category: FootSubCategoryEnum.OUT_SIDE,
        },
        {
          label: "안쪽",
          category: FootSubCategoryEnum.IN_SIDE,
        },
      ];
  }
};
