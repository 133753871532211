import { TermDetailEntity, TermEntity } from "../entities/TermEntity";
import { BaseRepository } from "./BaseRepository";

export class TermRepository extends BaseRepository {
  getTermSummaryList(
    hospitalId: number | string,
    signal?: AbortSignal
  ): Promise<TermEntity[]> {
    return this.api.get(`/terms/summary/web?hospital=${hospitalId}`, {
      signal,
    });
  }

  getTermSummaryDetail(
    termId: number,
    signal: AbortSignal
  ): Promise<TermDetailEntity> {
    return this.api.get(`/terms/one/web/${termId}`, { signal });
  }
}
