import type {
  Direction,
  MainCategory,
  SubCategory,
} from "@/features/humanBodyDiagram/stores/humanBodyPartsSlice";

type CategoryGroupKey =
  | `${MainCategory}_${SubCategory}`
  | `${MainCategory}_${SubCategory}_${Direction}`;

export const getCategoryGroupKey = ({
  mainCategory,
  subCategory,
  direction,
}: {
  mainCategory: MainCategory;
  subCategory: SubCategory;
  direction?: Direction | null;
}): CategoryGroupKey => {
  if (direction) {
    return `${mainCategory}_${subCategory}_${direction}`;
  }
  return `${mainCategory}_${subCategory}`;
};
