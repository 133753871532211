import { DesignSystemSize } from "@/components/DesignSystem/DesignSystemType";
import { SegmentedControl } from "@/components/DesignSystem/Tab/SegmentedControl";
import { useHumanBodyStore } from "@/features/humanBodyDiagram/context/useHumanBodyStore";
import { BodyMainCategoryEnum } from "@/features/humanBodyDiagram/entities/categories";
import {
  foot_L,
  foot_R,
} from "@/features/humanBodyDiagram/entities/painAreaIds/footIds";
import {
  hand_L,
  hand_R,
} from "@/features/humanBodyDiagram/entities/painAreaIds/handIds";
import { useTranslation } from "react-i18next";

interface Props {
  className?: string;
}

const BodyParts = [
  "L-t-05",
  "R-t-31",
  "R-t-05",
  "L-t-11",
  "R-t-11",
  "R-t-16",
  "L-t-16",
  "L-b-17",
  "L-b-18",
  "R-b-18",
  "R-b-17",
  "R-b-19",
  "R-b-20",
  "L-b-20",
  "L-b-19",
  "L-b-21",
  "L-b-22",
  "L-b-25",
  "L-b-24",
  "L-b-23",
  "L-b-27",
  "L-b-26",
  "R-b-27",
  "R-b-26",
  "R-b-25",
  "R-b-24",
  "R-b-23",
  "R-b-21",
  "R-b-22",
  "L-t-12",
  "L-t-13",
  "L-t-08",
  "L-t-09",
  "L-t-07",
  "L-t-10",
  "L-t-06",
  "L-t-04",
  "L-t-03",
  "L-t-02",
  "L-t-01",
  "L-t-00",
  "L-t-55",
  "R-t-55",
  "R-t-00",
  "R-t-01",
  "R-t-02",
  "R-t-04",
  "R-t-03",
  "R-t-07",
  "R-t-06",
  "R-t-08",
  "R-t-10",
  "R-t-12",
  "R-t-13",
  "R-t-09",
  "bL-t-10",
  "bL-t-09",
  "bL-t-08",
  "bL-t-12",
  "bL-t-13",
  "bL-t-16",
  "bL-t-15",
  "bL-t-14",
  "bL-t-06",
  "bL-t-05",
  "bL-t-04",
  "bL-t-03",
  "bR-t-01",
  "bR-t-00",
  "bL-t-02",
  "bR-t-02",
  "bR-t-03",
  "bL-t-07",
  "bR-t-07",
  "bR-t-04",
  "bR-t-06",
  "bR-t-05",
  "bR-t-09",
  "bR-t-08",
  "bR-t-13",
  "bR-t-14",
  "bR-t-16",
  "bR-t-15",
  "bR-t-12",
  "bR-t-10",
  "bL-t-11",
  "bR-t-11",
  "bL-t-19",
  "bL-t-20",
  "bR-t-20",
  "bR-t-19",
  "bL-b-22",
  "bL-b-21",
  "bL-b-23",
  "bR-b-22",
  "bR-b-23",
  "bL-b-33",
  "bR-b-21",
  "bL-b-24",
  "bL-b-25",
  "bR-b-25",
  "bR-b-27",
  "bR-b-28",
  "bR-b-24",
  "bR-b-26",
  "bL-b-27",
  "bL-b-28",
  "bL-b-26",
  "bL-b-29",
  "bL-b-30",
  "bR-b-30",
  "bR-b-29",
];

const HandParts = [...hand_L, ...hand_R];
const FootParts = [...foot_L, ...foot_R];

export const HumanBodyMainCategoryTabs = ({ className }: Props) => {
  const { t } = useTranslation("doctor");
  const { selectedPainAreas, mainCategory, handleChangeMainCategory } =
    useHumanBodyStore((store) => ({
      mainCategory: store.mainCategory,
      handleChangeMainCategory: store.handleChangeMainCategory,
      selectedPainAreas: store.selectedPainAreas,
    }));

  const SegmentItem = [
    {
      text: "전신",
      checked: mainCategory == BodyMainCategoryEnum.BODY,
      count: Array.from(selectedPainAreas).filter((area) =>
        BodyParts.includes(area),
      ).length,
      onClick: () => {
        handleChangeMainCategory(BodyMainCategoryEnum.BODY);
      },
    },
    {
      text: "손",
      checked: mainCategory == BodyMainCategoryEnum.HAND,
      count: Array.from(selectedPainAreas).filter((area) =>
        HandParts.includes(area),
      ).length,
      onClick: () => {
        handleChangeMainCategory(BodyMainCategoryEnum.HAND);
      },
    },
    {
      text: "발",
      checked: mainCategory == BodyMainCategoryEnum.FOOT,
      count: Array.from(selectedPainAreas).filter((area) =>
        FootParts.includes(area),
      ).length,
      onClick: () => {
        handleChangeMainCategory(BodyMainCategoryEnum.FOOT);
      },
    },
  ];

  return (
    <>
      <SegmentedControl size={DesignSystemSize.LARGE} items={SegmentItem} />
    </>
  );
};
