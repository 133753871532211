import type { DepartmentEnum } from "@/features/humanBodyDiagram/entities/departmentMappings";
import type {
  Direction,
  MainCategory,
  SubCategory,
} from "@/features/humanBodyDiagram/stores/humanBodyPartsSlice";
import type { Predicate } from "@/features/humanBodyDiagram/utils/conditionUtils";

/**
 * 카테고리 조건 생성
 * Mappings에서 사용됨
 * */
export const createMainCategoryCondition =
  (category: MainCategory): Predicate =>
  (params) =>
    params.mainCategory === category;

export const createSubCategoryCondition =
  (subCategory: SubCategory): Predicate =>
  (params) =>
    params.subCategory === subCategory;

export const createDirectionCondition =
  (direction: Direction): Predicate =>
  (params) =>
    params.direction === direction;

export const createDepartmentCondition =
  (department: DepartmentEnum): Predicate =>
  (params) =>
    params.department === department;
