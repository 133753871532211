import dayjs from "dayjs";
import { t } from "i18next";

export const calculateAge = (dateString: string) => {
  const today = dayjs();
  const birthDate = dayjs(dateString);

  let age = today.year() - birthDate.year();

  const todayMonth = today.month();
  const birthDateMonth = birthDate.month();

  if (
    todayMonth < birthDateMonth ||
    (todayMonth === birthDateMonth && today.date() < birthDate.date())
  ) {
    age--;
  }

  return Math.abs(age);
};

export function getGenderBoolean(idGender: number) {
  // 1, 3 남자-false / 2, 4 여자-true
  return !(idGender % 2);
}

export const makeBirthString = (birth, gender) => {
  let prefix = "19";
  if (gender > 2) {
    prefix = "20";
  }

  return `${prefix}${birth}`;
};

export const makePatientData = (birth, gender) => {
  const birthString = makeBirthString(birth, gender);
  const age = calculateAge(birthString);

  const genderTxt = getGenderBoolean(gender ?? 1)
    ? t("common.female")
    : t("common.male");

  return `${dayjs(birthString).format(
    "YYYY.MM.DD"
  )} · 만 ${age}세 · ${genderTxt}`;
};
